// src/components/MessageBubble.tsx
import React from "react";
import {
  RiInformationFill,
  RiLinksFill,
  RiQuestionLine,
  RiSearchLine,
} from "react-icons/ri";
import { UserQuery } from "@/utils/types";
import Logo from "./styled/Logo";
import { UserQuerySocialBar } from "./UserQuerySocialBar";
import ReactMarkdown from "react-markdown";
import { SourceItemAnalysisCard } from "./SourceItemAnalysisCard";
import Card from "./styled/Card";
import SubchapterTitle from "./SubchapterTitle";

interface UserQueryMessageCardProps {
  userquery: UserQuery;
  onReply?: (new_query: string, userquery_uuid: string) => void;
}

export const UserQueryMessageCard: React.FC<UserQueryMessageCardProps> = ({
  userquery,
  onReply,
}) => {
  const fromUserQueryToMessage = (userquery: UserQuery) => {
    if (userquery.result_metadata && userquery.result_metadata["answer"]) {
      return userquery.result_metadata["answer"];
    }
    switch (userquery.status) {
      case "pending":
        return "Thinking...";
      case "queued":
        return "Thinking...";
      case "classifing_type": {
        return "Understanding your query...";
      }
      case "researching_result": {
        if (userquery.type == "question" || userquery.type == "follow_up") {
          return "Answering your question...";
        }
        if (userquery.type == "search") {
          return "Searching news...";
        }
        if (userquery.type == "url") {
          return "Reading the link you shared...";
        }
        return "Thinking...";
      }
      case "completed": {
        if (userquery.type == "question" || userquery.type == "follow_up") {
          return "I don't know the answer to that question yet. I'm still learning!";
        }
        if (userquery.type == "search") {
          return "Ok, that's a search query. Here you go:";
        }
        if (userquery.type == "url") {
          return "I summarized the link you shared";
        }
        return `Found ${userquery.sections.length} results`;
      }
      case "failed":
        return "Something went wrong. Please try again";
      default:
        return "Something went wrong";
    }
  };

  return (
    <>
      <Card className="transition-all align-middle">
        <div
          className={`transition-all align-middle ${
            userquery.status == "completed" || userquery.status == "failed"
              ? "bg-white text-black dark:bg-black dark:text-white"
              : "bg-white dark:bg-black text-black dark:text-white flex animate-loading"
          }`}
        >
          <div className="flex flex-row items-center py-2">
            <div className="flex-shrink-0 mb-auto mt-1">
              {userquery.status == "completed" && (
                <>
                  {userquery.type == "question" && <RiQuestionLine />}
                  {userquery.type == "search" && <RiSearchLine />}
                  {userquery.type == "url" && <RiLinksFill />}
                  {userquery.type == "follow_up" && <RiQuestionLine />}
                </>
              )}
              {userquery.status == "researching_result" && (
                <>
                  {userquery.type == "question" && <RiQuestionLine />}
                  {userquery.type == "search" && <RiSearchLine />}
                  {userquery.type == "url" && <RiLinksFill />}
                  {userquery.type == "follow_up" && <RiQuestionLine />}
                </>
              )}
              {userquery.status == "failed" && <RiInformationFill />}
              {userquery.status != "completed" &&
                userquery.status != "researching_result" &&
                userquery.status != "failed" && (
                  <Logo
                    variant="svg"
                    className="w-4 h-4 dark:bg-gray-300 dark:rounded-lg"
                  />
                )}
            </div>
            <ReactMarkdown
              className={`my-auto text-sm ml-2 ${
                userquery.status == "completed" ? "" : ""
              }`}
            >
              {fromUserQueryToMessage(userquery) as string}
            </ReactMarkdown>
          </div>
          {userquery.sourceitemstorymetas.length > 0 && (
            <div className="mt-8">
              {userquery.type != "url" && (
                <SubchapterTitle title="Sources analyzed" />
              )}
              {userquery.sourceitemstorymetas?.map((meta, index) => (
                <SourceItemAnalysisCard key={`section-${index}`} meta={meta} />
              ))}
            </div>
          )}
        </div>
        {(userquery.status == "completed" || userquery.status == "failed") && (
          <div className="mt-1 mb-4">
            <UserQuerySocialBar
              canLike={true}
              userquery_uuid={userquery.uuid}
              onReply={onReply}
            />
          </div>
        )}
      </Card>
    </>
  );
};
