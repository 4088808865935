import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { RiArrowDownSLine, RiCheckLine } from "react-icons/ri";
import classnames from "classnames";

export type SelectOption = {
  label: string;
  value: string;
  icon?: React.ReactNode;
};

export default function SelectMenu({
  value,
  options,
  onSelect,
  label,
}: {
  value: SelectOption;
  options: SelectOption[];
  onSelect: (value: SelectOption) => void;
  label?: string;
}) {
  const [selection, setSelection] = useState<SelectOption>(value);

  return (
    <div className="flex flex-col">
      <Listbox
        value={selection}
        onChange={(value) => {
          setSelection(value);
          onSelect(value);
        }}
      >
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className="block text-sm font-medium leading-6 text-black dark:text-white">
                {label}
              </Listbox.Label>
            )}
            <div className="relative mt-2">
              <Listbox.Button className="relative flex items-center w-full cursor-default rounded-md bg-white dark:bg-black py-1.5 pl-3 pr-20 text-left text-black dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6">
                {selection && selection.icon && (
                  <span className="flex items-center pr-3">
                    {selection.icon}
                  </span>
                )}
                <span className="block truncate">
                  {selection ? selection.label : "Select an option"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <RiArrowDownSLine
                    className="h-5 w-5 text-black dark:text-white"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-black py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classnames(
                          {
                            "text-white dark:text-black bg-primary": active,
                            "text-black dark:text-white": !active,
                          },
                          "relative cursor-default select-none py-2 px-3 text-sm"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <div className="flex">
                          {option.icon && (
                            <span className="flex items-center pr-3">
                              {option.icon}
                            </span>
                          )}
                          <span
                            className={classnames(
                              {
                                "font-semibold": selected,
                                "font-normal": !selected,
                              },
                              "block truncate"
                            )}
                          >
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classnames(
                                active
                                  ? "text-white dark:text-black"
                                  : "text-primary",
                                "flex items-center pl-1.5"
                              )}
                            >
                              <RiCheckLine
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
