import { usePostHog } from "posthog-js/react";
import Button from "./styled/Button";
import { RiThumbUpLine } from "react-icons/ri";
import { Story } from "@/utils/types";
import { TextFrontend } from "@/utils/Text";

type StoryLikeButtonProps = {
  story: Story;
  canLike: boolean;
  liked: boolean;
  likesCount: number;
  onLike: () => void;
};

export const StoryLikeButton = (props: StoryLikeButtonProps) => {
  const posthog = usePostHog();

  const trackLike = async () => {
    posthog?.capture(props.liked ? "remove like" : "like", {
      trigger: "story_like",
    });
    props.onLike();
  };

  return (
    <>
      {props.canLike && (
        <>
          <Button
            variant={props.liked ? "primary" : "secondary"}
            onClick={trackLike}
            icon={<RiThumbUpLine />}
            title={props.liked ? "Remove like" : "Like"}
            aria-label={props.liked ? "Remove like" : "Like"}
            role="button"
          >
            {(props.likesCount > 0 && !props.liked) ||
              (props.likesCount > 1 &&
                TextFrontend.formatNumber(props.likesCount))}
          </Button>
        </>
      )}
    </>
  );
};
