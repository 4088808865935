import { useState, useEffect } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import Button from "@/components/styled/Button";
import { RiLoader2Fill } from "react-icons/ri";

type SignInWithGoogleProps = {
  redirectUrl?: string;
};

const SignInWithGoogle = (props: SignInWithGoogleProps = {}) => {
  const [googleURL, setGoogleURL] = useState<string | null>(null);

  useEffect(() => {
    if (props.redirectUrl) {
      localStorage.setItem("redirectUrl", props.redirectUrl || "");
    }
    loadGoogleLoginURL();
  }, []);

  const loadGoogleLoginURL = async () => {
    try {
      const url = (await MarconipyApi.getSocialProviderLoginURL(
        "google"
      )) as any;
      setGoogleURL(url["url"]);
    } catch (error: any) {
      if (error.code == "ERR_NETWORK") {
        setRedirectAfterError(true);
      } else {
        throw error;
      }
    }
  };
  const [redirectAfterError, setRedirectAfterError] = useState(false);

  return (
    <>
      {redirectAfterError && (
        <p className="text-black dark:text-white text-center">
          No connection! Try again later
        </p>
      )}
      {!redirectAfterError && (
        <Button
          disabled={googleURL == null}
          href={googleURL || ""}
          variant="secondary"
          className={
            googleURL == null
              ? "rounded-lg"
              : "rounded-lg border-0 hover:bg-gray-100"
          }
        >
          {googleURL != null ? (
            // <RiGalleryLine />
            <img
              src="/signinwithgoogle.svg"
              alt="Sign in with Google"
              className="hover:opacity-75"
            />
          ) : (
            <RiLoader2Fill />
          )}
        </Button>
      )}
    </>
  );
};

export default SignInWithGoogle;
