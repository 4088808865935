import React from "react";
import { Helmet } from "react-helmet";
import { useLoaderData, Await } from "react-router-dom";
import { defer } from "react-router-dom";
import MarconipyApi from "@/utils/marconipyApi";
import { Loading } from "@/components/Loading";
import classnames from "classnames";
import { Footer } from "@/components/Footer";
import {
  ArchiveAPIResponse,
  ArchiveYearResponse,
  ArchiveMonthResponse,
} from "@/utils/types";
import { TextFrontend } from "@/utils/Text";
import Divider from "@/components/styled/Divider";

export default function ArchiveView() {
  const data = useLoaderData() as any;

  return (
    <>
      <Helmet>
        <title>Tailor News - Archive</title>
        <meta
          name="description"
          content="Tailor Archive - Tailor is your personal AI news assistant. Is the best way to navigate the news and to stay informed without feeling overwhelmed."
        />
        <meta name="keywords" content="Tailor, News, Archive" />
        <meta property="og:title" content="Tailor News - Archive" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://tailor.news/featured/archive"
        />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta
          property="og:description"
          content="Tailor Archive - Tailor is your personal AI news assistant. Is the best way to navigate the news and to stay informed without feeling overwhelmed."
        />
        <meta property="og:site_name" content="Tailor" />
        <meta property="og:locale" content="en_US" />
      </Helmet>
      <React.Suspense fallback={<Loading />}>
        <Await resolve={data.archive} errorElement={<p>Error</p>}>
          {(archive: ArchiveAPIResponse) => (
            <>
              <div className="col-span-12 px-8 mt-4">
                <h1 className="font-bold mb-4 font-agipo uppercase">
                  Tailor News - Archive
                </h1>
              </div>
              <div
                // ref={containerRef}
                className={classnames("overflow-auto px-8 col-span-12")}
              >
                {archive.years.map((year: ArchiveYearResponse) => (
                  <>
                    <h2 className="font-bold mb-4">{year.year}</h2>
                    {year.months.map((month: ArchiveMonthResponse) => (
                      <>
                        <a
                          href={`/featured/archive/${year.year}/${month.month}/`}
                          className="block hover:bg-gray-50 dark:text-white dark:hover:bg-gray-800 p-4 rounded-md"
                        >
                          <h3>
                            {TextFrontend.fromMonthNumberToMonthName(
                              month.month
                            )}{" "}
                            {year.year}
                          </h3>
                        </a>
                      </>
                    ))}
                    <Divider />
                  </>
                ))}
              </div>
            </>
          )}
        </Await>
      </React.Suspense>
      <Footer />
    </>
  );
}

export async function loader() {
  try {
    const archiveResponse = MarconipyApi.getFeaturedArchive() as Promise<any>;
    return defer({
      archive: archiveResponse,
    });
  } catch (e: any) {
    console.error(e);
    return e;
  }
}
