// import { useEffect, useState } from "react";

type HostHeaderProps = {
  title: string;
  subtitle?: string;
  className?: string;
};
// import SkeletonElement from "./SkeletonElement";
import classnames from "classnames";
// import { useContentSetContext } from "@/contexts/ContentSetContext";

const HostHeader = (props: HostHeaderProps) => {
  // const [imageurl, setImageurl] = useState<string | undefined>(undefined);
  // const { contentset } = useContentSetContext();

  // useEffect(() => {
  //   if (contentset) {
  //     setImageurl(contentset.host.portrait_image_url);
  //   }
  // }, [contentset]);

  return (
    <div className={classnames(props.className ?? "", "flex my-4 space-x-4")}>
      {/* {imageurl ? ( */}
      <div className="w-16 h-16 flex-shrink-0">
        <img
          src="/logo-512x512.png"
          alt={`${props.title} image`}
          className="rounded-full"
        />
      </div>
      {/* ) : (
        <SkeletonElement width={60} height={60} />
      )} */}
      <div className="text-left p-4 bg-white dark:bg-black border border-gray rounded-lg rounded-tl-none">
        <div className="font-serif text-xl font-semibold">{props.title}</div>
        {props.subtitle && (
          <div className="font-sans text-sm">{props.subtitle}</div>
        )}
      </div>
    </div>
  );
};

export default HostHeader;
