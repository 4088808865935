import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import { redirect, useNavigate } from "react-router-dom";
import Container from "@/components/styled/Container";
import LoginForm from "@/components/auth/LoginForm";
import Card from "@/components/styled/Card";

export default function Login() {
  const navigate = useNavigate();
  const additionalMessage = localStorage.getItem("additionalMessage") || false;

  return (
    <>
      <Helmet>
        <title>Login - Tailor</title>
        <meta
          name="description"
          content="Login to access your Tailor account. Get an A.I. to help you understand the news."
        />
        <meta property="og:title" content="Login - Tailor" />
        <meta
          property="og:description"
          content="Login to access your Tailor account. Get an A.I. to help you understand the news."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
        <link rel="canonical" href="https://tailor.news/login" />
      </Helmet>
      <div className="col-span-12 sm:col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 grid grid-cols-12">
        <Container>
          {additionalMessage && (
            <p className="bg-primary-light rounded-lg text-black text-left text-sm w-full p-2">
              {additionalMessage}
            </p>
          )}
          <Card className="flex flex-col gap-2">
            <h1>Login</h1>
            <LoginForm
              onSignupRedirect={() => navigate("/signup")}
              onForgottenPasswordRedirect={() => navigate("/forgot-password")}
            />
          </Card>
        </Container>
      </div>
    </>
  );
}

export async function loader({}: { params: any }) {
  try {
    //check if the user is already logged in
    const loggedIn = MarconipyApi.isAuth();
    if (loggedIn) {
      console.log("User is already logged in");
      return redirect("/");
    } else {
      return false;
    }
  } catch (e) {
    throw e;
  }
}
