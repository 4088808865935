import { useState, useEffect } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import { Topic } from "utils/types";
import SuggestionTag from "@/components/SuggestionTag";
import SearchResult from "@/components/SearchResult";
import { RiBookmarkLine, RiCloseFill, RiSearch2Line } from "react-icons/ri";
import { DebounceInput } from "react-debounce-input";
import Button from "@/components/styled/Button";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import { useOnboardingContext } from "@/screens/OnboardingView";
import classnames from "classnames";

type OnboardingTopicsStepProps = {
  nextStep?: () => void;
  buttonLabel?: string;
  showConfirmation?: boolean;
  buttonVariant?: "primary" | "secondary";
};

const OnboardingTopicsStep = ({
  nextStep,
  buttonLabel,
  showConfirmation,
  buttonVariant,
}: OnboardingTopicsStepProps) => {
  const { contentset, updateContentSetOptimistically } = useContentSetContext();
  const { setHostTitle, setHostSubtitle, showHost } = useOnboardingContext();
  const [featuredTopics, setFeaturedTopics] = useState<Topic[]>([]);
  const [shownSelectedTopics, setShownSelectedTopics] = useState<Topic[]>([]);
  const [shownFeaturedTopics, setShownFeaturedTopics] = useState<Topic[]>([]);
  const [query, setQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Topic[]>([]);
  const [clickedButton, setClickedButton] = useState<boolean>(false);
  const [searchInputIsFocused, setSearchInputIsFocused] =
    useState<boolean>(false);
  const [queryIsInResults, setQueryIsInResults] = useState<boolean>(false);
  const [topicsHaveChanged, setTopicsHaveChanged] = useState(false);
  const [topicFormHasErrors, setTopicFormHasErrors] = useState(false);
  const [topicFormError, setTopicFormError] = useState("");
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  useEffect(() => {
    const downloadFromCloud = async () => {
      let allFeaturedtopics =
        (await MarconipyApi.getTopicsFeatured()) as unknown as Topic[];
      allFeaturedtopics = allFeaturedtopics.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setFeaturedTopics(allFeaturedtopics);
      setShownFeaturedTopics(
        filterAddedTopics(allFeaturedtopics, contentset?.topics ?? [])
      );
      setShownSelectedTopics(contentset?.topics ?? []);
    };
    if (contentset && featuredTopics.length == 0) {
      downloadFromCloud();
    }
  }, [contentset, featuredTopics.length]);

  useEffect(() => {
    setHostTitle("What are you interested in?");
    setHostSubtitle("Select at least 4 topics ");
  });

  useEffect(() => {
    showHost(!searchInputIsFocused);
  }, [searchInputIsFocused, showHost]);

  const confirmTopics = async function () {
    if (contentset) {
      if (showConfirmation) {
        setShowConfirmationMessage(true);
        setTopicsHaveChanged(false);
      }
      let savedTopics: Topic[] = [];
      for (const topic of shownSelectedTopics) {
        if (topic.uuid !== "temp") {
          savedTopics.push(topic);
        } else {
          const newTopic = (await MarconipyApi.searchTopic(
            topic.name
          )) as any as Topic;
          if (newTopic) {
            savedTopics.push(newTopic);
          }
        }
      }
      updateContentSetOptimistically(
        {
          ...contentset,
          topics: savedTopics,
        },
        async () => {
          await MarconipyApi.updateTopics(contentset.uuid, {
            uuids: savedTopics.map((topic) => topic.uuid),
          });
          nextStep?.();
        }
      );
    }
  };
  const addFeaturedTag = async function (topic: Topic) {
    setQuery("");
    setSuggestions([]);
    setSearchInputIsFocused(false);
    const newTopics = [
      ...shownSelectedTopics,
      { ...topic, name: topic.name[0].toUpperCase() + topic.name.slice(1) },
    ].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    setShownFeaturedTopics(filterAddedTopics(featuredTopics, newTopics));
    setShownSelectedTopics(newTopics);
    setTopicsHaveChanged(true);
  };
  const removeTag = async function (topic: Topic) {
    const newTopics =
      shownSelectedTopics
        .filter((_topic) => _topic.uuid !== topic.uuid)
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ) ?? [];
    setShownFeaturedTopics(filterAddedTopics(featuredTopics, newTopics));
    setShownSelectedTopics(newTopics);
    setTopicsHaveChanged(true);
  };

  const searchAutocomplete = async function (squery: string) {
    setQuery(squery);
    setTopicFormError("");
    setTopicFormHasErrors(false);
    if (squery.length < 3) {
      setSuggestions([]);
      return;
    }
    let results = (await MarconipyApi.searchTopicAutocomplete(
      squery
    )) as any as Topic[];
    const filteredResults = results.filter(
      (result) =>
        !shownSelectedTopics.some(
          (topic) =>
            topic.uuid === result.uuid ||
            result.name.toLowerCase() === topic.name.toLowerCase()
        )
    );
    setQueryIsInResults(
      results.some(
        (result) => result.name.toLowerCase() === squery.toLowerCase()
      )
    );
    setSuggestions(filteredResults);
  };

  const searchWasFocused = async function () {
    setSearchInputIsFocused(true);
  };
  const unfocusSearch = async function () {
    setQuery("");
    setTopicFormError("");
    setTopicFormHasErrors(false);
    setSearchInputIsFocused(false);
    setSuggestions([]);
  };

  const searchAndAddTopic = async function (topic: Topic) {
    if (topic.name.length > 90) {
      //client side validation for a faster UX
      setTopicFormHasErrors(true);
      setTopicFormError("Interest name is too long");
      return;
    }
    setQuery("");
    setSuggestions([]);
    setSearchInputIsFocused(false);
    const topicAlreadyAdded = shownSelectedTopics.some(
      (t) => t.name.toLowerCase() === topic.name.toLowerCase()
    );
    if (topicAlreadyAdded) {
      return;
    }
    const newTopics = [...(shownSelectedTopics ?? []), topic].sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    setShownFeaturedTopics(filterAddedTopics(featuredTopics, newTopics));
    setShownSelectedTopics(newTopics);
    setTopicsHaveChanged(true);
  };

  const handleTopicSubmit = async function (e: any) {
    e.preventDefault();
    await searchAndAddTopic({
      name: query,
      uuid: "temp",
      last_sourceset_search: null,
    });
    setQuery("");
  };

  const filterAddedTopics = (
    featured_topics: Topic[],
    addedTopics: Topic[]
  ) => {
    const filteredTopics = featured_topics.filter(
      (ftopic) => !addedTopics.some((atopic) => atopic.uuid === ftopic.uuid)
    );
    return filteredTopics;
  };

  useEffect(() => {
    if (showConfirmationMessage) {
      setTimeout(() => {
        setShowConfirmationMessage(false);
      }, 1000);
    }
  }, [showConfirmationMessage]);

  useEffect(() => {
    const checkTempTopics = async () => {
      let shouldReplaceTopics = false;
      let newTopics = [];
      for (const topic of shownSelectedTopics) {
        if (topic.uuid == "temp") {
          const newTopic = (await MarconipyApi.searchTopic(
            topic.name
          )) as any as Topic;
          if (newTopic) {
            newTopics.push(newTopic);
            shouldReplaceTopics = true;
          }
        } else {
          newTopics.push(topic);
        }
      }
      if (shouldReplaceTopics) {
        setShownFeaturedTopics(filterAddedTopics(featuredTopics, newTopics));
        setShownSelectedTopics(newTopics);
      }
    };
    checkTempTopics();
  }, [featuredTopics, shownSelectedTopics]);

  return (
    <>
      <form
        onSubmit={handleTopicSubmit}
        className={`${topicFormHasErrors && "has-errors"}`}
      >
        <div className="flex relative">
          <DebounceInput
            type="text"
            value={query}
            placeholder="🔎  Search..."
            onChange={(e) => searchAutocomplete(e.target.value)}
            onFocus={searchWasFocused}
            className={`text-black dark:text-white bg-white dark:bg-black border rounded-md py-1 px-2 w-full ${
              topicFormHasErrors ? "border-primary" : "border-gray"
            }`}
          />
          {searchInputIsFocused && (
            <span className="absolute flex p-2 items-center inset-y-0 right-0 cursor-pointer hover:text-primary">
              <RiCloseFill onClick={unfocusSearch} className="" />
            </span>
          )}
        </div>
      </form>
      {(suggestions.length > 0 || query?.length > 0) && (
        <div className="flex flex-col my-2">
          {query?.length > 0 && !queryIsInResults && (
            <SearchResult
              key={query}
              value={{ name: query, uuid: "temp", last_sourceset_search: null }}
              icon={<RiSearch2Line />}
              callback={searchAndAddTopic}
            />
          )}
          {suggestions?.map((topic) => (
            <SearchResult
              key={topic.uuid}
              value={topic}
              icon={<RiBookmarkLine />}
              callback={addFeaturedTag}
            />
          ))}
        </div>
      )}
      {!searchInputIsFocused && (
        <>
          <div className="w-full flex gap-2 flex-wrap my-4">
            {shownSelectedTopics.map((topic) => (
              <SuggestionTag
                selected={true}
                key={topic.uuid}
                value={topic}
                icon={<RiCloseFill />}
                clickOnIcon={true}
                callback={removeTag}
              />
            ))}
            {shownFeaturedTopics.map((topic) => (
              <SuggestionTag
                icon={null}
                selected={false}
                key={topic.uuid}
                value={topic}
                callback={addFeaturedTag}
              />
            ))}
          </div>

          {showConfirmation && topicsHaveChanged && (
            <span className="text-sm text-primary mx-auto">
              {shownSelectedTopics.length < 4
                ? "Select at least 4 topics"
                : "You have unsaved changes!"}
            </span>
          )}

          <Button
            onClick={() => {
              if (shownSelectedTopics.length < 4) {
                setClickedButton(true);
                return;
              }
              setClickedButton(false);
              confirmTopics();
            }}
            $fullwidth
            variant={buttonVariant ?? "primary"}
            className={classnames({
              "opacity-50": shownSelectedTopics.length < 4,
            })}
            disabled={
              showConfirmation &&
              (!topicsHaveChanged || shownSelectedTopics.length < 4)
            }
          >
            {showConfirmationMessage
              ? "Saved!"
              : buttonLabel
              ? buttonLabel
              : nextStep
              ? "Continue"
              : "Save"}
          </Button>
          {clickedButton && (
            <small className="text-primary">Select at least 4 topics</small>
          )}
        </>
      )}
      {searchInputIsFocused && topicFormHasErrors && (
        <span className="text-sm text-primary ml-4">{topicFormError}</span>
      )}
      {searchInputIsFocused && suggestions.length == 0 && query == "" && (
        <>
          <div className="flex flex-col align-center text-center mt-8 gap-2">
            <h2>Search for an interest</h2>
            <p className="font-sans text-black dark:text-white text-base">
              Try to be as specific as possible
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default OnboardingTopicsStep;
