import { useState, useEffect } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import { useAuth } from "@/contexts/AuthenticationContext";
import { Helmet } from "react-helmet";
import { useNavigate, useLoaderData } from "react-router-dom";
import { Loading } from "@/components/Loading";
import { usePostHog } from "posthog-js/react";

type userDataResponse = {
  provider: string;
  code: string;
};

export default function SocialLogin() {
  const navigate = useNavigate();
  const data = useLoaderData() as userDataResponse;
  const { updateAuth } = useAuth();
  const posthog = usePostHog();
  const [errors, setErrors] = useState<string | null>(null);

  const socialsignupcall = async (provider: string, code: string) => {
    try {
      await MarconipyApi.signupSocial(provider, code);
      await MarconipyApi.putProfile();
      await updateAuth();
      // this capture event needs to happen after we have identified the user in updateAuth
      posthog?.capture("login", { method: provider });
      const redirectUrl = localStorage.getItem("redirectUrl");
      if (redirectUrl) {
        localStorage.removeItem("redirectUrl");
        navigate(redirectUrl);
        return;
      } else {
        navigate("/");
        return;
      }
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.non_field_errors &&
        error.response.data.non_field_errors[0] ==
          "User is already registered with this e-mail address."
      ) {
        //we need to redirect to login page and tell the user to login with the existing email address
        let additionalMessage = localStorage.setItem(
          "additionalMessage",
          "It looks like you already have an account with this email address. Please login with your email and password."
        );
        navigate("/login");
        return;
      }
      if (
        error.response &&
        error.response.data &&
        error.response.data.non_field_errors &&
        error.response.data.non_field_errors[0] !=
          "User is already registered with this e-mail address."
      ) {
        setErrors(error.response.data.non_field_errors[0]);
        return;
      }
    }
  };

  useEffect(() => {
    socialsignupcall(data.provider, data.code);
  }, []);

  return (
    <>
      <Helmet>
        <title>Signing up</title>
        <meta
          name="description"
          content="Login to access your Tailor account. Tailor uses AI to help you understand and navigate the news."
        />
        <meta property="og:title" content="Login - Tailor" />
        <meta
          property="og:description"
          content="Login to access your Tailor account. Tailor uses AI to help you understand and navigate the news."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
      </Helmet>
      <div className="col-span-12 grid grid-cols-12">
        <Loading />
        {errors && (
          <p className="text-white dark:text-black text-lg">Error: {errors}</p>
        )}
      </div>
    </>
  );
}

export async function loader({
  params,
  request,
}: {
  params: any;
  request: Request;
}) {
  try {
    const provider = params.provider;
    if (provider == "google") {
      // const { search } = useLocation();
      const queryparams = new URLSearchParams(request.url.split("?")[1]);
      const code = queryparams.get("code") as string;
      return { provider, code };
    }
    throw new Error("Provider not supported");
  } catch (e) {
    console.log(e);
    throw e;
  }
}
