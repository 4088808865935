import React, { useEffect, useState } from "react";
import { UserQuery } from "@/utils/types";
import TimeAgo from "react-timeago";
import { UserQueryMessageCard } from "./UserQueryMessageCard";

interface UserQueryQuestionAnswerProps {
  userquery: UserQuery;
  onReply: (new_query: string, userquery_uuid: string) => void;
  other_userqueries?: UserQuery[];
  renderOnlyTopLevel?: boolean;
}

export const UserQueryQuestionAnswer: React.FC<
  UserQueryQuestionAnswerProps
> = ({ userquery, onReply, other_userqueries, renderOnlyTopLevel = false }) => {
  const [show, setShow] = useState(false);
  const [repliedToUserQuery, setRepliedToUserQuery] =
    useState<UserQuery | null>(null);
  useEffect(() => {
    //search among user queries. if one of them has a related_userquery_uuids that matches this userquery.uuid, then this is a reply to that userquery
    if (other_userqueries) {
      for (const other_userquery of other_userqueries) {
        if (
          other_userquery.result_metadata &&
          other_userquery.result_metadata["related_userquery_uuids"] &&
          other_userquery.result_metadata["related_userquery_uuids"].includes(
            userquery.uuid
          )
        ) {
          setRepliedToUserQuery(other_userquery);
        }
      }
    }
    if (
      !userquery.result_metadata ||
      !userquery.result_metadata["related_userquery_uuids"] ||
      userquery.result_metadata["related_userquery_uuids"].length === 0
    ) {
      //this is a top level comment
      setShow(true);
      return;
    }
    if (!renderOnlyTopLevel) {
      setShow(true);
    }
  }, [
    other_userqueries,
    renderOnlyTopLevel,
    userquery.result_metadata,
    userquery.uuid,
  ]);

  return (
    <>
      {show && (
        <div className="mb-8 flex flex-col gap-4" key={userquery.uuid}>
          <div className="flex flex-col gap-2 border rounded-lg p-2 bg-tertiary dark:bg-black-deep">
            <div className="flex flex-row gap-1 items-center">
              <img
                src={
                  userquery.author_profile_picture?.res_100 ??
                  "https://d3c0pq8hs2rjcc.cloudfront.net/default_user_picture.png"
                }
                className="w-6 h-6 w-max-12 h-max-12 my-auto rounded-full"
              />
              <div className="text-sm">
                <span className="font-bold mr-2">{userquery.author_name}</span>
                {` `}
                <span className="opacity-70">
                  <TimeAgo date={userquery.created_at} />
                </span>
              </div>
            </div>
            {userquery.type == "url" && (
              <p className="truncate">
                <span>Analyze this URL: </span>
                <span>
                  <a
                    href={userquery.query}
                    target="_blank"
                    rel="noreferrer"
                    className="underline hover:text-blue-500"
                  >
                    {userquery.query}
                  </a>
                </span>
              </p>
            )}
            {userquery.type != "url" && <p>{userquery.query}</p>}
          </div>
          <div className="pl-4 md:pl-8">
            <UserQueryMessageCard
              key={userquery.uuid}
              userquery={userquery}
              onReply={onReply}
            />
          </div>
        </div>
      )}
      {/* render the replied to userquery */}
      {repliedToUserQuery && (
        <div className="mb-8 flex flex-col gap-4 ml-8">
          <UserQueryQuestionAnswer
            userquery={repliedToUserQuery}
            onReply={onReply}
            other_userqueries={other_userqueries}
          />
        </div>
      )}
    </>
  );
};
