import { SourceItemStoryMeta } from "utils/types";
import SubchapterTitle from "@/components/SubchapterTitle";
import ScoreCircle from "@/components/ScoreCircle";
import Tooltip from "./styled/Tooltip";

type SourceItemScoreCardProps = {
  sourceitemstorymeta: SourceItemStoryMeta;
};

const SourceItemScoreCard = ({
  sourceitemstorymeta,
}: SourceItemScoreCardProps) => {
  return (
    <div className="flex flex-col gap-4">
      <SubchapterTitle title="Score card" />
      <div className="sm:min-w-48 flex flex-col md:flex-row gap-4 mb-4">
        {!sourceitemstorymeta.metadata && (
          <>
            <ScoreCircle
              score="?"
              colorVariant="loading"
              label="Verifying claims..."
            />
          </>
        )}
        {sourceitemstorymeta.metadata &&
          sourceitemstorymeta.metadata["answer"] &&
          !sourceitemstorymeta.metadata["stats"] && (
            <>
              <ScoreCircle
                score="?"
                colorVariant="loading"
                label={sourceitemstorymeta.metadata["answer"]}
              />
            </>
          )}
        {sourceitemstorymeta.metadata &&
          sourceitemstorymeta.metadata["stats"] && (
            <>
              <Tooltip
                content={`${
                  (1 -
                    sourceitemstorymeta.metadata["stats"][
                      "unverified_facts_to_total_facts_ratio"
                    ]) *
                  100
                }% of the statements in the article are backed up by other sources`}
              >
                <ScoreCircle
                  score={
                    (
                      (1 -
                        sourceitemstorymeta.metadata["stats"][
                          "unverified_facts_to_total_facts_ratio"
                        ]) *
                      100
                    ).toString() + "%"
                  }
                  colorVariant={
                    sourceitemstorymeta.metadata["stats"][
                      "unverified_facts_to_total_facts_ratio"
                    ] > 0.1
                      ? "warning"
                      : "good"
                  }
                  label="Verified Score"
                  variant="primary"
                />
              </Tooltip>
              <Tooltip
                content={`${sourceitemstorymeta.metadata["stats"]["unverified_facts"]} statements in the article can't be backed up by other sources`}
              >
                <ScoreCircle
                  score={
                    sourceitemstorymeta.metadata["stats"]["unverified_facts"]
                  }
                  colorVariant={
                    sourceitemstorymeta.metadata["stats"][
                      "unverified_facts_to_total_facts_ratio"
                    ] > 0.1
                      ? "warning"
                      : "good"
                  }
                  label="unsupported statements"
                />
              </Tooltip>
              <Tooltip
                content={`${sourceitemstorymeta.metadata["stats"]["total_facts"]} total statements in article`}
              >
                <ScoreCircle
                  score={sourceitemstorymeta.metadata["stats"]["total_facts"]}
                  colorVariant={"default"}
                  label="total statements"
                />
              </Tooltip>
            </>
          )}
      </div>
      <div className="sm:min-w-48 flex flex-col md:flex-row gap-4 mb-4">
        {sourceitemstorymeta.metadata &&
          sourceitemstorymeta.metadata["stats"] && (
            <>
              <Tooltip
                content={`Our AI considered ${
                  sourceitemstorymeta.metadata["stats"][
                    "opinion_to_total_ratio"
                  ] * 100
                }% of the statements in the article to be opinions rather than facts`}
              >
                <ScoreCircle
                  score={
                    (
                      sourceitemstorymeta.metadata["stats"][
                        "opinion_to_total_ratio"
                      ] * 100
                    ).toString() + "%"
                  }
                  colorVariant={
                    sourceitemstorymeta.metadata["stats"][
                      "opinion_to_total_ratio"
                    ] > 0.2
                      ? "warning"
                      : "good"
                  }
                  label="Opinion Score"
                  variant="primary"
                />
              </Tooltip>
              <Tooltip
                content={`Our AI found ${
                  sourceitemstorymeta.metadata["stats"]["opinions"] * 100
                } claims made in the article to be opinions rather than facts`}
              >
                <ScoreCircle
                  score={sourceitemstorymeta.metadata["stats"]["opinions"]}
                  colorVariant={"default"}
                  label="opinions"
                />
              </Tooltip>
            </>
          )}
      </div>
    </div>
  );
};

export default SourceItemScoreCard;
