import { Loading } from "@/components/Loading";
import { redirect } from "react-router-dom";

export default function Redirector() {
  return <Loading />;
}
declare global {
  interface Window {
    MSStream: string;
  }
}

const grabUTMparamsAndReturnUrlParams = function (url: string): string {
  const utmParams = new URL(url).searchParams;
  const paramsArray: string[] = [];
  const utmKeys = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
  ];

  utmKeys.forEach((key) => {
    const value = utmParams.get(key);
    if (value) {
      paramsArray.push(`${key}=${value}`);
    }
  });

  return paramsArray.length > 0 ? `?${paramsArray.join("&")}` : "";
};

export async function loader({
  params,
  request,
}: {
  params: any;
  request: Request;
}) {
  const userAgent = navigator.userAgent || navigator.vendor;
  const type = params.redirect_type;
  let iOSdeepLink = "";
  let weburl = "https://tailor.news/";
  switch (type) {
    case "libraryItem": {
      iOSdeepLink = "library?id=" + params.uuid;
      weburl += "library/" + params.uuid;
      break;
    }
    case "feedItemDetail": {
      iOSdeepLink = "feedItemDetail/?id=" + params.uuid;
      weburl += "s/" + params.uuid;
      break;
    }
    case "storyDetail": {
      iOSdeepLink = "storyDetail?id=" + params.uuid;
      weburl += "s/story/" + params.uuid;
      break;
    }
    default: {
      console.warn("Invalid redirect type");
      throw new Error("Invalid redirect type");
    }
  }
  if (iOSdeepLink == "") {
    throw new Error("Invalid redirect type");
  }
  weburl += "/";
  weburl += grabUTMparamsAndReturnUrlParams(request.url);
  //sometimes hash is a query parameter
  const hash = new URL(request.url).searchParams.get("hash");
  if (hash) {
    weburl += "#" + hash;
  }
  // Logic to detect mobile and platform
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    // iOS
    return redirect("tailormediatailorapp://" + iOSdeepLink);
  } else if (/android/i.test(userAgent)) {
    // Android
    console.warn("Android deep link not implemented yet, redirecting to web");
    return redirect(weburl);
  } else {
    return redirect(weburl);
  }
}
