import debounce from "lodash/debounce";
import { useCallback, useEffect } from "react";

const useDebouncedEffect = (effect: any, deps: any[], delay: number) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = debounce(() => callback(), delay);

    handler();

    return () => handler.cancel();
  }, [callback, delay]);
};

export default useDebouncedEffect;
