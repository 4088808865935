import { Story } from "@/utils/types";
import Button from "./styled/Button";
import { useAudioPlayerContext } from "@/contexts/AudioPlayerContext";
import { useCallback } from "react";
import { storyToPlayable } from "./AudioPlayerManager";
import { RiPauseLine, RiPlayLine } from "react-icons/ri";

const PlayButton = ({ story }: { story: Story }) => {
  const { skipToPlayable, isPlaying, currentPlayable, pauseAudio } =
    useAudioPlayerContext();
  const onPlay = useCallback(
    (uuid: string) => {
      skipToPlayable(uuid, storyToPlayable(story));
    },
    [story, skipToPlayable]
  );
  const isPlayingCurrentStory =
    isPlaying && currentPlayable?.uuid == story.uuid;
  const playLabel = isPlayingCurrentStory ? "Pause" : "Listen";
  const playIcon = isPlayingCurrentStory ? <RiPauseLine /> : <RiPlayLine />;
  const playAction = isPlayingCurrentStory
    ? pauseAudio
    : () => onPlay(story.uuid);
  return (
    <Button
      onClick={playAction}
      icon={playIcon}
      variant="secondary"
      role="button"
      aria-label={playLabel}
      title={playLabel}
    >
      {playLabel}
    </Button>
  );
};

export default PlayButton;
