// src/components/MessageBubble.tsx
import React, { useCallback, useEffect } from "react";

import { UserQueryMeta } from "@/utils/types";
import { LikeButton } from "./LikeButton";
import MarconipyApi from "@/utils/marconipyApi";
import Button from "./styled/Button";
import { UserQueryInput } from "./UserQueryInput";
import { usePostHog } from "posthog-js/react";
import classNames from "classnames";

interface UserQuerySocialBarProps {
  userquery_uuid: string;
  canLike: boolean;
  className?: string;
  onReply?: (new_query: string, userquery_uuid: string) => void;
}

export const UserQuerySocialBar: React.FC<UserQuerySocialBarProps> = ({
  userquery_uuid,
  canLike,
  className,
  onReply,
}) => {
  const [meta, setMeta] = React.useState<UserQueryMeta | null>(null);
  const [openInputAfterReply, setOpenInputAfterReply] = React.useState(false);
  const posthog = usePostHog();

  const refresh = useCallback(async () => {
    const meta = (await MarconipyApi.getQueryMeta(
      userquery_uuid
    )) as any as UserQueryMeta;
    setMeta(meta);
  }, [userquery_uuid]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const onLike = async () => {
    if (!canLike) {
      return;
    }
    if (meta?.liked) {
      await MarconipyApi.updateQueryMeta(userquery_uuid, { liked: false });
    } else {
      await MarconipyApi.updateQueryMeta(userquery_uuid, { liked: true });
      if (meta?.disliked) {
        //remove dislike
        await MarconipyApi.updateQueryMeta(userquery_uuid, { disliked: false });
      }
    }
    refresh();
  };
  const onDisLike = async () => {
    if (!canLike) {
      return;
    }
    if (meta?.disliked) {
      await MarconipyApi.updateQueryMeta(userquery_uuid, { disliked: false });
    } else {
      await MarconipyApi.updateQueryMeta(userquery_uuid, { disliked: true });
      if (meta?.liked) {
        //remove like
        await MarconipyApi.updateQueryMeta(userquery_uuid, { liked: false });
      }
    }
    refresh();
  };

  const localOnReply = (new_query: string) => {
    if (onReply) {
      onReply(new_query, userquery_uuid);
      posthog?.capture("search", {
        query: new_query,
        reply: true,
      });
    }
    setOpenInputAfterReply(false);
  };

  return (
    <div className={classNames("w-full flex justify-end gap-1", className)}>
      <LikeButton
        canLike={canLike}
        liked={meta?.liked ?? false}
        likesCount={meta?.likes_count ?? 0}
        onClick={onLike}
      />
      <LikeButton
        canLike={canLike}
        liked={meta?.disliked ?? false}
        likesCount={meta?.dislikes_count ?? 0}
        isDisliked={true}
        onClick={onDisLike}
      />
      {onReply && !openInputAfterReply && (
        <Button
          onClick={() => setOpenInputAfterReply(true)}
          variant="secondary"
          className="flex items-center"
        >
          Ask follow-up
        </Button>
      )}
      {onReply && openInputAfterReply && (
        <div className="flex-grow">
          <UserQueryInput onSubmit={localOnReply} autofocus={true} />
        </div>
      )}
    </div>
  );
};
