import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  RiWhatsappLine,
  RiFacebookFill,
  RiLinkedinBoxFill,
  RiRedditLine,
  RiFileCopyLine,
  RiTwitterXLine,
} from "react-icons/ri";
import Button from "@/components/styled/Button";
import classNames from "classnames";

type ShareButtonProps = {
  url: string;
  title: string;
  size: "sm" | "lg";
  onShare?: () => void;
};

export const ShareButton = (props: ShareButtonProps) => {
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(props.url);
      props.onShare?.();
      alert("Link copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const sizeClassName = classNames(
    "hover:text-primary transition-colors duration-200",
    {
      "w-4 h-4": props.size == "sm",
      "w-6 h-6": props.size == "lg",
    }
  );

  return (
    <div className="flex w-full gap-4 justify-between text-black dark:text-white">
      <FacebookShareButton
        url={props.url}
        quote={props.title}
        beforeOnClick={props.onShare}
      >
        <RiFacebookFill className={sizeClassName} />
      </FacebookShareButton>
      <TwitterShareButton
        url={props.url}
        title={props.title}
        via={props.title}
        beforeOnClick={props.onShare}
      >
        <RiTwitterXLine className={sizeClassName} />
      </TwitterShareButton>
      <WhatsappShareButton
        url={props.url ?? ""}
        title={props.title}
        beforeOnClick={props.onShare}
      >
        <RiWhatsappLine className={sizeClassName} />
      </WhatsappShareButton>
      <LinkedinShareButton
        url={props.url}
        title={props.title}
        beforeOnClick={props.onShare}
      >
        <RiLinkedinBoxFill className={sizeClassName} />
      </LinkedinShareButton>
      <RedditShareButton
        url={props.url}
        title={props.title}
        beforeOnClick={props.onShare}
      >
        <RiRedditLine className={sizeClassName} />
      </RedditShareButton>
      <Button className="p-0" variant="plain" onClick={handleCopy} size="sm">
        <RiFileCopyLine className={sizeClassName} />
      </Button>
    </div>
  );
};
