import { Suggestion } from "@/utils/types";
import { RiArrowRightSLine } from "react-icons/ri";

type SearchResultUserQuerySuggestionProps<T extends Suggestion> = {
  query: string;
  icon: any | null;
  callback?: (value: string) => void;
};

const SearchResultUserQuerySuggestion = <T extends Suggestion>(
  props: SearchResultUserQuerySuggestionProps<T>
) => {
  const callback = () => {
    if (props.callback) {
      props.callback(props.query);
    }
  };

  return (
    <div
      onClick={callback}
      className="flex bg-white dark:bg-black w-full justify-between items-center cursor-pointer hover:bg-primary-light hover:dark:bg-black-light px-2 py-2 rounded-sm"
    >
      <div className="flex gap-2 items-center overflow-hidden">
        <div className="w-4 h-4">{props.icon && props.icon}</div>
        <span className="truncate">{props.query}</span>
      </div>
      <div className="w-4 h-4">
        <RiArrowRightSLine />
      </div>
    </div>
  );
};

export default SearchResultUserQuerySuggestion;
