import React from "react";
import { Helmet } from "react-helmet";
import { useLoaderData, Await } from "react-router-dom";
import { defer } from "react-router-dom";
import MarconipyApi from "@/utils/marconipyApi";
import { Loading } from "@/components/Loading";
import classnames from "classnames";
import { StoryCard } from "@/components/StoryCard";
import { Footer } from "@/components/Footer";
import { FeedSectionType, FeedAPIResponse } from "@/utils/types";
import { TextFrontend } from "@/utils/Text";
import { RiFolderOpenLine, RiNewspaperLine } from "react-icons/ri";
import { PaginationNavigator } from "@/components/PaginationNavigator";
import { LoadingError } from "@/components/LoadingError";

export default function FeaturedView() {
  const data = useLoaderData() as any;

  const widthClassBasedOnIndex = (index: number): string => {
    switch (index) {
      // case 0:
      //   return "col-span-12";
      // case 1:
      //   return "col-span-6 px-1";
      // case 2:
      //   return "col-span-6 px-1";
      // case 5:
      //   return "col-span-4 px-1";
      // case 6:
      //   return "col-span-4 px-1";
      // case 7:
      //   return "col-span-4 px-1";
      // case 8:
      //   return "col-span-12";
      // case 13:
      //   return "col-span-12";
      default:
        return "col-span-12 lg:col-span-6 px-1";
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Tailor is your personal AI news assistant. Is the best way to navigate the news and to stay informed without feeling overwhelmed."
        />
        <meta name="keywords" content="Tailor, News" />
        <meta property="og:title" content="Tailor News - Featured stories" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta
          property="og:description"
          content="Tailor is your personal AI news assistant. Is the best way to navigate the news and to stay informed without feeling overwhelmed."
        />
        <meta property="og:site_name" content="Tailor" />
        <meta property="og:locale" content="en_US" />
      </Helmet>
      <React.Suspense fallback={<Loading />}>
        <Await resolve={data.feed} errorElement={<LoadingError />}>
          {(feed: FeedAPIResponse) => (
            <>
              {(!feed.category || feed.category == "") && (
                <Helmet>
                  <title>
                    Latest Featured news from Tailor{data.archive_string}
                  </title>
                  <link
                    rel="alternate"
                    type="application/rss+xml"
                    href="rss.xml"
                  />
                  {/* <link
                    rel="canonical"
                    href={`https://tailor.news/featured`}
                  />
                  <meta
                    property="og:url"
                    content="https://tailor.news/featured"
                  /> */}
                </Helmet>
              )}
              {feed.category && feed.category != "" && (
                <Helmet>
                  <title>
                    Latest {feed.category} news from Tailor{data.archive_string}
                  </title>
                  {/* <link
                    rel="canonical"
                    href={`https://tailor.news/featured/${TextFrontend.fromCategoryNameToUrl(
                      feed.category
                    )}`}
                  />
                  <meta
                    property="og:url"
                    content={`https://tailor.news/featured/${TextFrontend.fromCategoryNameToUrl(
                      feed.category
                    )}`}
                  /> */}
                  <link
                    rel="alternate"
                    type="application/rss+xml"
                    href={`${TextFrontend.fromCategoryNameToUrl(
                      feed.category
                    )}/rss.xml`}
                  />
                </Helmet>
              )}
              <div className="col-span-2 px-8 mt-4">
                <h1 className="text-sm font-bold mb-4 font-agipo uppercase">
                  <RiNewspaperLine className="inline-block text-xl mr-2" />
                  Latest{" "}
                  {`${TextFrontend.fromCategoryNameToUrl(
                    feed.category ?? null
                  )}`}{" "}
                  news
                  {data.archive_string}
                </h1>
              </div>
              {feed.categories && (
                <div className="col-span-10 px-8 pt-2 hidden md:block">
                  {/* <Tooltip message="Filter by category"> */}
                  <RiFolderOpenLine className="inline-block text-xl mr-2" />
                  {/* </Tooltip> */}
                  {feed.categories.map((category: string, index: number) => {
                    return (
                      <>
                        <ul className="inline-flex md:list-inside">
                          {` `}
                          {category == feed.category && (
                            <li
                              className="text-xs mx-1 font-sans secondary capitalize underline"
                              key={index}
                            >
                              {category}
                            </li>
                          )}
                          {category != feed.category && (
                            <a
                              href={`/featured/${TextFrontend.fromCategoryNameToUrl(
                                category
                              )}`}
                            >
                              <li
                                className="text-xs mx-2 font-sans secondary capitalize hover:underline"
                                key={index}
                              >
                                {category}
                              </li>
                            </a>
                          )}
                        </ul>
                      </>
                    );
                  })}
                </div>
              )}
              <div
                // ref={containerRef}
                className={classnames("overflow-auto px-8 col-span-12 pb-20")}
              >
                <div className="grid grid-cols-12">
                  {feed.sections.map(
                    (section: FeedSectionType, index: number) => {
                      return (
                        <div
                          className={`mt-2 mb-2 lg:px-1 ${widthClassBasedOnIndex(
                            index
                          )}`}
                          key={index}
                        >
                          <StoryCard section={section} />
                        </div>
                      );
                    }
                  )}
                  <div className="col-span-12">
                    <PaginationNavigator
                      currentPage={feed.current_page ?? 1}
                      totalPages={feed.total_pages ?? 1}
                      baseUrl={data.clean_url}
                    />
                    <Footer />
                  </div>
                </div>
              </div>
            </>
          )}
        </Await>
      </React.Suspense>
    </>
  );
}

export async function loader({
  request,
  params,
}: {
  params: any;
  request: any;
}) {
  try {
    const categoryId = params.categoryId || null;
    const yearId = params.year || null;
    const monthId = params.month || null;
    // page is a query request param
    console.log(request);
    const url = new URL(request.url);
    const page_string = url.searchParams.get("page") || undefined;
    const page: number = page_string ? parseInt(page_string) : 1;
    //get the url without search params
    const clean_url = request.url.split("?")[0];
    const isArchive = yearId && monthId;
    let from_string = null;
    let to_string = null;
    let archive_string = "";
    if (isArchive) {
      // Use native JavaScript Date to find the last day of the month
      const date = new Date(yearId, monthId, 0); // Month is 0-indexed in JavaScript Date, so monthId should be 1 more than the actual month number if monthId is 1-indexed
      const last_day = date.getDate().toString();
      from_string = yearId && monthId ? `${yearId}-${monthId}-01` : null;
      to_string = yearId && monthId ? `${yearId}-${monthId}-${last_day}` : null;

      // Making a human readable string like October 2024
      // Note: getMonth() returns 0-11, so we add 1 for correct month representation
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = monthNames[new Date(yearId, monthId - 1).getMonth()]; // Adjust monthId if it's 1-indexed
      archive_string = ` - ${monthName} ${yearId}`;
    }
    const feedResponse = MarconipyApi.getFeaturedFeed(
      categoryId,
      from_string,
      to_string,
      page
    ) as Promise<any>;
    return defer({
      feed: feedResponse,
      year: yearId,
      month: monthId,
      archive_string: archive_string,
      clean_url: clean_url,
    });
  } catch (e: any) {
    console.error(e);
    return e;
  }
}
