import { useLoaderData } from "react-router-dom";
import MarconipyApi from "@/utils/marconipyApi";
import Container from "@/components/styled/Container";
import Button from "@/components/styled/Button";
import { RiLoader2Line } from "react-icons/ri";

//import { deleteNote, getNote } from "../notes";

export default function ConfirmEmailView() {
  const confirmation = useLoaderData() || "loading";

  return (
    <Container>
      <div className="flex items-center gap-2 flex-col">
        {confirmation === "loading" && (
          <div className="flex gap-2 items-center">
            <RiLoader2Line className="animate-spin" />
            <p>Loading...</p>
          </div>
        )}
        {confirmation === "error" && (
          <>
            <p>Oops! There was an error in your email verification.</p>
            <Button variant="primary" href="/">
              Go back to the homepage
            </Button>
          </>
        )}
        {confirmation === "confirmed" && (
          <>
            <p>Email verified! Thank you.</p>
            <Button variant="primary" href="/">
              Go back to the homepage
            </Button>
          </>
        )}
      </div>
    </Container>
  );
}

export async function loader({ params }: { params: any }) {
  try {
    await MarconipyApi.confirmEmail(params.key);
    return "confirmed";
  } catch (e) {
    console.log(e);
    return "error";
  }
}

export async function action({}: { params: any }) {
  //   await deleteNote(params.noteId);
  //   return redirect("/new");
}
