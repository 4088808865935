import { useEffect, useMemo } from "react";
import { storyToPlayable } from "@/components/AudioPlayerManager";
import { SmallPlayer } from "@/components/AudioPlayer";
import Container from "@/components/styled/Container";
import { useAudioPlayerContext } from "@/contexts/AudioPlayerContext";
import { FeedAPIResponse } from "@/utils/types";
import useFeed from "@/hooks/useFeed";
import classNames from "classnames";
import Button from "@/components/styled/Button";
import { RiPauseFill, RiPlayLine } from "react-icons/ri";
import { TextFrontend } from "@/utils/Text";
import TimeAgo from "react-timeago";
import { StorySocialBar } from "@/components/StorySocialBar";

type PodcastPartialViewProps = {
  feed: FeedAPIResponse;
};

const PodcastPartialView = ({ feed }: PodcastPartialViewProps) => {
  const { sections } = useFeed(feed, {
    audioOnly: true,
    canLoadNewContent: true,
    canRequestNewContent: false,
  });
  const {
    playlist,
    updatePlaylist,
    playAudio,
    pauseAudio,
    skipToPlayable,
    isPlaying,
    currentPlayableIndex,
  } = useAudioPlayerContext();

  const currentSection = useMemo(() => {
    return sections.find(
      (section) => section.uuid == playlist[currentPlayableIndex]?.uuid
    );
  }, [sections, playlist, currentPlayableIndex]);

  useEffect(() => {
    updatePlaylist(
      sections
        .filter(
          (section) =>
            section.story != undefined && section.story.audio_url != null
        )
        .map((section) => storyToPlayable(section.story!))
    );
  }, [playAudio, sections, updatePlaylist]);

  return (
    <div
      className={classNames(
        "col-span-12 grid grid-cols-12 lg:px-0 overflow-auto"
      )}
    >
      <Container className="mt-12 pb-80 flex-col gap-8">
        <SmallPlayer showEmptyPlayer showImage showTitle />
        {currentSection && currentSection.story && (
          <StorySocialBar story={currentSection.story} showViews={false} />
        )}
        <div className="flex flex-col gap-2">
          {playlist.length > 0 && <p className="uppercase">Playlist</p>}
          {playlist.map((playable, index) => (
            <Button
              variant="plain"
              key={index}
              onClick={() => {
                if (index == currentPlayableIndex) {
                  if (isPlaying) {
                    pauseAudio();
                  } else {
                    playAudio();
                  }
                } else {
                  skipToPlayable(playable.uuid);
                }
              }}
              className={classNames(
                {
                  "border border-primary": index == currentPlayableIndex,
                },
                "justify-between gap-2"
              )}
            >
              <div className="flex flex-row gap-2 items-center flex-shrink truncate">
                <div
                  style={{ backgroundImage: `url(${playable.coverImage})` }}
                  className="w-10 h-10 rounded bg-cover bg-center"
                />
                <div className="flex flex-col">
                  <p>{playable.title}</p>
                  <div className="flex gap-1">
                    <small>
                      {TextFrontend.formatDuration(playable.duration)}
                    </small>
                    {"•"}
                    <small>{<TimeAgo date={playable.publishedAt} />}</small>
                  </div>
                </div>
              </div>
              <div className="flex-shrink-0">
                {index == currentPlayableIndex && isPlaying ? (
                  <RiPauseFill />
                ) : (
                  <RiPlayLine />
                )}
              </div>
            </Button>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default PodcastPartialView;
