import { Suggestion } from "@/utils/types";
import classnames from "classnames";

type SuggestionTagProps<T extends Suggestion> = {
  selected: boolean;
  value: T;
  icon: any | null;
  clickOnIcon?: boolean;
  callback?: (value: T) => void;
};

const SuggestionTag = <T extends Suggestion>(props: SuggestionTagProps<T>) => {
  return (
    <div
      className={classnames(
        "font-sans p-2 rounded-lg flex items-center w-auto whitespace-nowrap truncate text-ellipsis",
        {
          "bg-black text-white dark:bg-black-light dark:text-white":
            props.selected,
          "bg-white dark:bg-black text-black dark:text-white border":
            !props.selected,
          "cursor-pointer hover:bg-primary-light dark:hover:bg-gray-900":
            props.callback && !props.clickOnIcon,
        }
      )}
      onClick={() =>
        props.callback && !props.clickOnIcon && props.callback(props.value)
      }
    >
      {props.value.name}
      {props.icon && (
        <span
          className="cursor-pointer ml-2"
          onClick={() =>
            props.callback && props.clickOnIcon && props.callback(props.value)
          }
        >
          {props.icon}
        </span>
      )}
    </div>
  );
};

export default SuggestionTag;
