import { Link } from "react-router-dom";

type PaginationNavigatorProps = {
  currentPage: number;
  totalPages: number;
  baseUrl: string;
};

export const PaginationNavigator = (props: PaginationNavigatorProps) => {
  return (
    <>
      {props.totalPages > 1 && (
        <div className="col-span-12 w-full flex text-black dark:text-white mb-4 overflow-x-scroll">
          <p className="flex text-sm px-4 py-2">Pages: </p>
          {Array.from(Array(props.totalPages).keys()).map((page) => {
            return (
              <Link
                key={page}
                to={`${props.baseUrl}?page=${page + 1}`}
                className={`text-sm hover:opacity-100 ${
                  props.currentPage == page + 1
                    ? "no-underline font-bold opacity-100"
                    : "underline hover:no-underline opacity-75"
                }`}
              >
                <div className="justify-between items-center px-4 py-2 hover:bg-white dark:hover:bg-black">
                  {page + 1}
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};
