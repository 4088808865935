import { usePostHog } from "posthog-js/react";
import Button from "./styled/Button";
import { RiThumbDownLine } from "react-icons/ri";
import { Story } from "@/utils/types";

type StoryDislikeButtonProps = {
  story: Story;
  canDislike: boolean;
  disliked: boolean;
  onDislike: () => void;
};

export const StoryDislikeButton = (props: StoryDislikeButtonProps) => {
  const posthog = usePostHog();

  const trackLike = async () => {
    posthog?.capture(props.disliked ? "remove dislike" : "dislike", {
      trigger: "story_dislike",
    });
    props.onDislike();
  };

  return (
    <>
      {props.canDislike && (
        <>
          <Button
            variant={props.disliked ? "primary" : "secondary"}
            onClick={trackLike}
            icon={<RiThumbDownLine />}
            title={props.disliked ? "Remove dislike" : "Dislike"}
            aria-label={props.disliked ? "Remove dislike" : "Dislike"}
            role="button"
          />
        </>
      )}
    </>
  );
};
