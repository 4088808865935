import { useState } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import { redirect, useLoaderData } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

import {
  RiWhatsappLine,
  RiMessengerLine,
  RiFacebookFill,
  RiTwitterLine,
  RiLinkedinBoxFill,
  RiRedditLine,
} from "react-icons/ri";
import { User } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import Container from "@/components/styled/Container";
import Input from "@/components/styled/Input";
import Button from "@/components/styled/Button";

type userDataResponse = {
  user?: any;
  sharelink?: string;
};

export default function WaitlistView() {
  const usr = useLoaderData() as userDataResponse;
  const [copied, setCopied] = useState(false);
  const [skipcode, setSkipcode] = useState<string>("");
  const [hasInviteCode, setHasInviteCode] = useState<boolean>(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const posthog = usePostHog();

  const handleCopy = () => {
    navigator.clipboard.writeText(usr.sharelink ?? "");
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSubmit = async () => {
    try {
      setError("");
      await MarconipyApi.skipWaitlist(skipcode);
      posthog?.capture("waitlist skipped with invite code");
      navigate("/");
    } catch {
      setError("Invite code is invalid, check again");
    }
  };

  return (
    <>
      <Helmet>
        <title>{"Welcome to Tailor's Waitlist! 🎉"}</title>
      </Helmet>
      <Container>
        <div className="flex flex-col gap-4">
          <h1>Waitlist</h1>
          <h2>
            🎉 Hi {usr.user.first_name}
            {", welcome to Tailor's waitlist!"}
          </h2>
          <h2>
            Only{" "}
            <span className="position">{usr.user.waitlist_position + 148}</span>
            {" users ahead of you - you're so close! 🚀"}
          </h2>
          <br />
          {hasInviteCode && (
            <>
              <p>Enter your Invite Code to skip the waitlist!</p>
              <div className="flex flex-col gap-2">
                <Input
                  type="text"
                  placeholder="your invite code"
                  value={skipcode}
                  onChange={(e) => setSkipcode(e.target.value)}
                />
                <Button variant="tertiary" onClick={handleSubmit}>
                  Send
                </Button>
              </div>
              {error && <small className="text-primary">{error}</small>}
              <Button
                variant="secondary"
                onClick={() => setHasInviteCode(false)}
              >
                {"I don't have an Invite Code"}
              </Button>
            </>
          )}
          {hasInviteCode == false && (
            <>
              <p>Boost your spot by inviting friends!</p>
              <p>
                {
                  "For each friend who joins, you'll jump up 5 spots. Share your unique invite link:"
                }
              </p>
              <div className="flex flex-col gap-2">
                <Input
                  type="text"
                  value={usr.sharelink}
                  readOnly
                  onClick={handleCopy}
                />
                <Button variant="tertiary" onClick={handleCopy}>
                  {copied ? "Copied 🎉" : "Copy"}
                </Button>
              </div>
              <br />
              <div className="flex justify-center gap-2">
                <WhatsappShareButton
                  url={usr.sharelink ?? ""}
                  title={`I'd like to invite you to Tailor, sign up here: ${
                    usr.sharelink ?? ""
                  }`}
                >
                  <Button className="sharebutton-waitlist" variant="plain">
                    <RiWhatsappLine />
                  </Button>
                </WhatsappShareButton>
                <FacebookMessengerShareButton
                  url={usr.sharelink ?? ""}
                  appId="123456789"
                >
                  <Button className="sharebutton-waitlist" variant="plain">
                    <RiMessengerLine />
                  </Button>
                </FacebookMessengerShareButton>
                <FacebookShareButton
                  url={usr.sharelink ?? ""}
                  quote={`I'd like to invite you to Tailor, sign up here: ${
                    usr.sharelink ?? ""
                  }`}
                >
                  <Button className="sharebutton-waitlist" variant="plain">
                    <RiFacebookFill />
                  </Button>
                </FacebookShareButton>
                <TwitterShareButton
                  url={usr.sharelink ?? ""}
                  title="Join Tailor"
                  via={`I'd like to invite you to Tailor, sign up here: ${
                    usr.sharelink ?? ""
                  }`}
                >
                  <Button className="sharebutton-waitlist" variant="plain">
                    <RiTwitterLine />
                  </Button>
                </TwitterShareButton>
                <LinkedinShareButton
                  url={usr.sharelink ?? ""}
                  title={`I'd like to invite you to Tailor, sign up here: ${
                    usr.sharelink ?? ""
                  }`}
                >
                  <Button className="sharebutton-waitlist" variant="plain">
                    <RiLinkedinBoxFill />
                  </Button>
                </LinkedinShareButton>
                <RedditShareButton
                  url={usr.sharelink ?? ""}
                  title={`I'd like to invite you to Tailor, sign up here: ${
                    usr.sharelink ?? ""
                  }`}
                >
                  <Button className="sharebutton-waitlist" variant="plain">
                    <RiRedditLine />
                  </Button>
                </RedditShareButton>
              </div>
              <Button
                variant="secondary"
                className="mt-4"
                onClick={() => setHasInviteCode(true)}
              >
                I have an Invite Code
              </Button>
            </>
          )}
        </div>
      </Container>
    </>
  );
}

export async function loader({}: { params: any }) {
  try {
    //check if the user is already logged in
    const loggedIn = MarconipyApi.isAuth();
    if (!loggedIn) {
      console.log("User is not logged in");
      return redirect("/");
    } else {
      let user: User | null = MarconipyApi.user;
      if (!user) {
        user = await MarconipyApi.getUser();
      }
      if (!user?.waitlist) {
        return redirect("/");
      }
      let sharelinks = (await MarconipyApi.getInviteLink()) as any;
      return { user: user, sharelink: sharelinks["invite_link"] };
    }
  } catch (e) {
    throw e;
  }
}
