import ReactMarkdown from "react-markdown";
import CitationTooltip from "./CitationTooltip";

const LongformBlock = ({
  content,
  content_json,
  sources,
}: {
  content: string;
  sources: Record<string, any>;
  content_json?: any;
}) => {
  const shouldShowCitations = content_json && content_json.value;
  return (
    <>
      {!shouldShowCitations && (
        <div className="flex flex-col gap-2 py-2 mb-4">
          <ReactMarkdown>{content as string}</ReactMarkdown>
        </div>
      )}
      {shouldShowCitations &&
        content_json.value.map((item: any, index: number) => (
          <div key={index}>
            {item.type == "heading" && <h2>{item.text}</h2>}
            {item.type == "paragraph" && (
              <p>
                <span className="items-baseline">{item.text}</span>
                {item.source_uuids?.map((source: any, index: number) => (
                  <CitationTooltip
                    key={"source-" + index}
                    index={sources[source].index}
                    sourceitem={sources[source].sourceitem}
                  />
                ))}
              </p>
            )}
          </div>
        ))}
    </>
  );
};

export default LongformBlock;
