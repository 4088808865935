import { Story } from "@/utils/types";
import { useCallback, useMemo } from "react";

const useSourceMap = (story: Story) => {
  const mapUuidsToIndex = useCallback(
    (data: any[]) => {
      let sourceitems = story.sourceitems ?? [];
      const uuidMap: Record<string, Record<string, any>> = {};
      let currentIndex = 1;

      data.forEach((item) => {
        if (!item.source_uuids) {
          return;
        }
        item.source_uuids.forEach((uuid: string) => {
          if (!uuidMap[uuid]) {
            uuidMap[uuid] = {
              index: currentIndex++,
              sourceitem: sourceitems.find((si) => si.uuid === uuid),
            };
          }
        });
      });

      for (const sourceitem of sourceitems) {
        if (!uuidMap[sourceitem.uuid]) {
          uuidMap[sourceitem.uuid] = {
            index: currentIndex++,
            sourceitem: sourceitem,
          };
        }
      }

      return uuidMap;
    },
    [story.sourceitems]
  );

  const sourceIdsMap = useMemo(() => {
    return mapUuidsToIndex([
      ...(story.bulletpoints_content_json &&
      story.bulletpoints_content_json.value
        ? story.bulletpoints_content_json.value
        : []),
      ...(story.longform_content_json && story.longform_content_json.value
        ? story.longform_content_json.value
        : []),
    ]);
  }, [
    mapUuidsToIndex,
    story.bulletpoints_content_json,
    story.longform_content_json,
  ]);

  return sourceIdsMap;
};

export default useSourceMap;
