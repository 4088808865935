import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

type ToastProps = {
  open: boolean;
  onClose: () => void;
  time?: number;
  children: React.ReactNode;
};
function Toast({ children, open, onClose, time }: ToastProps) {
  const [openInternal, setOpenInternal] = useState(open);

  useEffect(() => {
    if (open) {
      setOpenInternal(open);
      setTimeout(() => {
        setOpenInternal(false);
        onClose();
      }, time ?? 3000);
    }
  }, [open, onClose, time]);

  return (
    <Transition.Root show={openInternal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed mx-4 bottom-0 right-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed mx-4 bottom-0 right-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-black px-4 pb-4 pt-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 border border-gray">
                <>{children}</>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Toast.Body = function ToastBody({ children }: { children: React.ReactNode }) {
  return <div className="text-center">{children}</div>;
};

export default Toast;
