import { createContext, useContext, useEffect, useState } from "react";
import BackButton from "@/components/BackButton";
import { redirect } from "react-router-dom";
import MarconipyApi from "@/utils/marconipyApi";
import { usePostHog } from "posthog-js/react";
import OnboardingWelcomeStep from "./Onboarding/OnboardingWelcomeStep";
import OnboardingTopicsStep from "./Onboarding/OnboardingTopicsStep";
import OnboardingFrequencyStep from "./Onboarding/OnboardingFrequencyStep";
import OnboardingAppDownloadStep from "./Onboarding/OnboardingAppDownloadStep";
import classnames from "classnames";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import OnboardingHostContainer from "./Onboarding/OnboardingHostContainer";
import ProgressBar from "@/components/styled/ProgressBar";
import Container from "@/components/styled/Container";
import { ContentSet } from "@/utils/types";
import { useNavigate } from "react-router-dom";
import Card from "@/components/styled/Card";
import { Loading } from "@/components/Loading";

const ghostSteps = [4, 6, 7];

const OnboardingContext = createContext<{
  showHost: (value: boolean) => void;
  setHostTitle: (value: string) => void;
  setHostSubtitle: (value: string) => void;
  isLoading: boolean;
  showLoading: (value: boolean) => void;
}>({
  showHost: () => {},
  setHostTitle: () => {},
  setHostSubtitle: () => {},
  isLoading: false,
  showLoading: () => {},
});

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error(
      "useOnboardingContext must be used within a OnboardingContext"
    );
  }
  return context;
};

export default function OnboardingView() {
  const [step, setStep] = useState(1);
  const [stepForProgress, setStepForProgress] = useState(1);
  const { contentset, reloadContentSet } = useContentSetContext();
  const posthog = usePostHog();
  const [showHost, setShowHost] = useState<boolean>(true);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [hostTitle, setHostTitle] = useState<string>("");
  const [hostSubtitle, setHostSubtitle] = useState<string>("");
  const navigate = useNavigate();

  const nextStep = async (reload: boolean) => {
    if (reload) {
      reloadContentSet();
    }
    const newStep = step + 1;
    setStep(newStep);
    if (!ghostSteps.includes(newStep)) {
      setStepForProgress(stepForProgress + 1);
    }
    if (!showHost) {
      setShowHost(true);
    }
  };

  const previousStep = () => {
    if (step <= 1) {
      setStep(1);
      return;
    }
    const newStep = step - 1;
    setStep(newStep);
    if (!ghostSteps.includes(newStep)) {
      setStepForProgress(stepForProgress - 1);
    }
    if (showLoading) {
      setShowLoading(false);
    }
    if (!showHost) {
      setShowHost(true);
    }
  };

  const confirmTopics = async () => {
    if (!contentset) {
      return;
    }
    posthog?.capture("start generating contentset", {
      trigger: "OnboardingView",
      contentset_uuid: contentset.uuid,
    });
    MarconipyApi.generateContentSet(contentset.uuid).then(() => {
      reloadContentSet();
    });
    nextStep(false);
  };

  const complete = async () => {
    if (!contentset) {
      return;
    }
    posthog?.capture("complete onboarding", {
      trigger: "OnboardingView",
      contentset_uuid: contentset.uuid,
    });
    navigate(`/discover`);
  };

  useEffect(() => {
    reloadContentSet();
  }, [reloadContentSet]);

  if (!contentset) {
    return <Loading />;
  }

  return (
    <OnboardingContext.Provider
      value={{
        showHost: (value: boolean) => setShowHost(value),
        setHostTitle,
        setHostSubtitle,
        isLoading: showLoading,
        showLoading: (value: boolean) => setShowLoading(value),
      }}
    >
      <div className="overflow-y-auto col-span-12 grid grid-cols-12">
        <Container className="w-full h-full pb-40">
          <div className="relative flex justify-center align-center">
            <div
              className={classnames("absolute left-0", {
                invisible: step <= 1,
              })}
            >
              <BackButton onClick={previousStep} />
            </div>
            <ProgressBar
              steps={8 - ghostSteps.length}
              currentStep={stepForProgress - 1}
            />
          </div>
          <Card className="flex flex-col justify-center align-center mt-4">
            {step == 1 && (
              <OnboardingWelcomeStep nextStep={() => nextStep(true)} />
            )}
            {step != 1 && (
              <OnboardingHostContainer
                title={hostTitle}
                subtitle={hostSubtitle}
                isLoading={showLoading}
                show={showHost}
              >
                {step == 2 && <OnboardingTopicsStep nextStep={confirmTopics} />}
                {step == 3 && (
                  <OnboardingFrequencyStep nextStep={() => nextStep(true)} />
                )}
                {step == 4 && (
                  // start of the generation! Ask to download the iOS app
                  <OnboardingAppDownloadStep nextStep={complete} />
                )}
              </OnboardingHostContainer>
            )}
          </Card>
        </Container>
      </div>
    </OnboardingContext.Provider>
  );
}

export async function loader({ params }: { params: any }) {
  try {
    //check if the user is already logged in
    const loggedIn = MarconipyApi.isAuth();
    if (!loggedIn) {
      console.log("User is not logged in");
      return redirect("/login");
    }
    if (
      !params.contentsetId ||
      params.contentsetId == "undefined" ||
      params.contentsetId == "default"
    ) {
      let c = (await MarconipyApi.getContentSets()) as any as ContentSet[];
      if (c.length > 0) {
        return redirect(`/new/${c[0].uuid}`);
      }
      await MarconipyApi.loadToken();
      await MarconipyApi.addContentSet();
      c = (await MarconipyApi.getContentSets()) as any as ContentSet[];
      if (c.length > 0) {
        return redirect(`/new/${c[0].uuid}`);
      } else {
        //return redirect("/discover");
      }
    }
    return params.contentsetId;
  } catch (e: any) {
    console.log("error, check if waitlist");
    console.log(e.response.data);
    if (e.json && e.json.detail && e.json.detail.includes("waitlist")) {
      return redirect("/waitlist");
    }
    if (e.response && e.response.data && e.response.data.includes("Banned")) {
      return redirect("/banned");
    }
    throw e;
  }
}
