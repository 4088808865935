import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { RiCloseLine } from "react-icons/ri";
import classNames from "classnames";

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  absoluteChildren?: React.ReactNode;
  size?: "md" | "lg" | "xl";
  className?: string;
};
function Modal({
  children,
  absoluteChildren,
  open,
  onClose,
  size = "md",
  className = "",
}: ModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 dark:bg-gray-950 bg-opacity-75 dark:bg-opacity-80 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto dark:[color-scheme:dark]">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  {
                    "sm:max-w-lg": size === "md",
                    "sm:max-w-3xl": size === "lg",
                    "sm:max-w-4xl": size === "xl",
                  },
                  "relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all w-full mt-4 mb-20 bg-gray-50 dark:bg-black",
                  className
                )}
              >
                <>
                  <div className="absolute right-0 top-0 pr-2 pt-2 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white dark:bg-black text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <RiCloseLine className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {children}
                </>
                <div className="absolute bottom-0 inset-x-0">
                  {absoluteChildren}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.MainContainer = function ModalMainContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className="p-4">{children}</div>;
};

Modal.Title = function ModalTitle({ children }: { children: React.ReactNode }) {
  return (
    <Dialog.Title
      as="h3"
      className="text-black dark:text-white font-semibold p-4"
    >
      {children}
    </Dialog.Title>
  );
};

Modal.Body = function ModalBody({ children }: { children: React.ReactNode }) {
  return <div>{children}</div>;
};

Modal.Footer = function ModalFooter({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className="flex justify-end p-4 sm:gap-3">{children}</div>;
};

export default Modal;
