import classNames from "classnames";

type SubchapterTitleProps = {
  title: string;
  subtitle?: string;
  className?: string;
};

export default function SubchapterTitle(props: SubchapterTitleProps) {
  return (
    <p
      className={classNames(
        "font-bold text-sm uppercase opacity-75",
        props.className
      )}
    >
      {props.title}
    </p>
  );
}
