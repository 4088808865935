import Modal from "../styled/Modal";
import SignupForm from "./SignupForm";
import { useState } from "react";
import LoginForm from "./LoginForm";
import PasswordForgottenForm from "./PasswordForgottenForm";

type AuthModalProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  redirectToAfterLogin?: string;
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
};

export const AuthModal = ({
  isOpen,
  setOpen,
  redirectToAfterLogin,
  title,
  subtitle,
}: AuthModalProps) => {
  const [view, setView] = useState("signup");

  return (
    <>
      <Modal open={isOpen} onClose={() => setOpen(false)}>
        <Modal.MainContainer>
          <Modal.Body>
            <div className="flex flex-col gap-2">
              {view == "signup" && (
                <>
                  <div className="dark:text-white">
                    {title && (
                      <h1 className="mb-2 text-center font-serif font-bold">
                        {title}
                      </h1>
                    )}
                    {subtitle && <p className="text-center my-4">{subtitle}</p>}
                  </div>
                  <SignupForm
                    onLoginRedirect={() => setView("login")}
                    redirectToAfterLogin={redirectToAfterLogin}
                  />
                </>
              )}
              {view == "login" && (
                <LoginForm
                  onSignupRedirect={() => setView("signup")}
                  onForgottenPasswordRedirect={() => setView("password-reset")}
                  redirectToAfterLogin={redirectToAfterLogin}
                />
              )}
              {view == "password-reset" && (
                <PasswordForgottenForm
                  onLoginRedirect={() => setView("login")}
                />
              )}
            </div>
          </Modal.Body>
        </Modal.MainContainer>
      </Modal>
    </>
  );
};
