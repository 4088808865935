import React from "react";
import { FeedSectionType } from "@/utils/types";
import { StoryRenderer } from "@/components/StoryRenderer";
import TopicSuggestionSection from "@/components/TopicSuggestionSection";
import SourceSetsSuggestionSection from "@/components/SourceSetsSuggestionSection";
import { SectionTitle } from "@/components/SectionTitle";
import FeedSection from "./styled/FeedSection";
import StoryDetailView from "@/screens/partials/StoryDetailView";
import Card from "./styled/Card";

type SectionRendererProps = {
  section: FeedSectionType;
  index: number;
  onReadMore: (uuid: string) => void;
  className?: string | Record<string, boolean>;
  footer?: React.ReactNode;
  onAuthRedirect?: () => void;
};

export const SectionRenderer = React.forwardRef<
  HTMLDivElement,
  SectionRendererProps
>(function SectionRenderer(
  {
    section,
    index,
    onReadMore,
    className,
    footer,
    onAuthRedirect,
  }: SectionRendererProps,
  ref
) {
  return (
    <>
      {/* {index == 0 && section.type != "title" && (
        <FeedSection key={`label`} className="pt-4">
          <FeedHostBubble text={"is curating your feed"} />
        </FeedSection>
      )} */}
      {section.type == "title" && (
        <SectionTitle ref={ref} key={`title-${index}`} section={section} />
      )}
      {section.story && section.type == "story" && (
        <StoryRenderer
          ref={ref}
          key={`section-${index}`}
          story={section.story!}
          debug={section.debug}
          onReadMore={onReadMore}
          className={className}
          onAuthRedirect={onAuthRedirect}
        />
      )}
      {section.story && section.type == "detail" && (
        <FeedSection ref={ref}>
          <Card className="bg-gray-50 p-0">
            <StoryDetailView
              key={`section-${index}`}
              story={section.story}
              onAuthRedirect={onAuthRedirect}
              debug={section.debug}
            />
          </Card>
        </FeedSection>
      )}
      {section.type == "add_topic" && (
        <FeedSection ref={ref} key={`section_add_topic`}>
          <TopicSuggestionSection />
        </FeedSection>
      )}
      {section.type == "add_sourceset" && (
        <FeedSection ref={ref} key={`section_add_sourceset`}>
          <SourceSetsSuggestionSection />
        </FeedSection>
      )}
      <>{footer}</>
    </>
  );
});
