import { createRoot } from "react-dom/client";
import "@/index.scss";
import App from "@/App";

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/service-worker.js")
//       .then((registration) => {
//         console.log("SW registered:", registration);
//       })
//       .catch((registrationError) => {
//         console.log("SW registration failed:", registrationError);
//       });
//   });
// }

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
