import { useState } from "react";
import { SourceSet } from "utils/types";
import { TextFrontend } from "@/utils/Text";
import { SourceSetInformationPartialView } from "@/screens/partials/SourceSetInformationPartialView";
import Modal from "@/components/styled/Modal";
import SourceSetImage from "./SourceSetImage";

type SourceSetRowItemProps = {
  sourceset: SourceSet;
  functionToAddButton: (sourceset: SourceSet) => void;
  functionToRemoveButton: (sourceset: SourceSet) => void;
};

const SourceSetRowItem = (props: SourceSetRowItemProps) => {
  const [showMoreInfoSheet, setShowMoreInfoSheet] = useState(false);

  const removeAndClose = (sourceset: SourceSet) => {
    setShowMoreInfoSheet(false);
    props.functionToRemoveButton(sourceset);
  };

  const addAndClose = (sourceset: SourceSet) => {
    setShowMoreInfoSheet(false);
    props.functionToAddButton(sourceset);
  };

  const handleTapGesture = () => {
    if (showMoreInfoSheet == false) {
      setShowMoreInfoSheet(true);
    }
  };

  const close = () => {
    setShowMoreInfoSheet(false);
  };

  return (
    <div
      className="cursor-pointer flex items-center py-1 justify-between"
      onClick={handleTapGesture}
    >
      <div className="flex space-x-4 items-center overflow-hidden p-2 w-full border border-transparent hover:border-primary rounded-lg ">
        <SourceSetImage
          image={props.sourceset.image}
          type={props.sourceset.type}
          alt={props.sourceset.name}
        />
        <div className="flex flex-col text-left gap-0">
          <p className="font-sans text-sm p-0">{props.sourceset.name}</p>
          <div className="truncate text-sm font-sans text-slate-500 dark:text-gray-400">
            {props.sourceset.rss_url &&
              TextFrontend.humanUrl(props.sourceset.rss_url)}
          </div>
        </div>
      </div>

      <Modal open={showMoreInfoSheet} onClose={close}>
        <Modal.Title>{props.sourceset.name}</Modal.Title>
        <Modal.MainContainer>
          <Modal.Body>
            <SourceSetInformationPartialView
              sourceset={props.sourceset}
              showAddRemoveButton={true}
              added={true}
              functionToAddButton={() => addAndClose(props.sourceset)}
              functionToRemoveButton={() => removeAndClose(props.sourceset)}
            />
          </Modal.Body>
        </Modal.MainContainer>
      </Modal>
    </div>
  );
};

export default SourceSetRowItem;
