import { useEffect, useState } from "react";
import Lottie, { LottieProps } from "react-lottie";
import icon8File from "../lottie/icons8-file.json";
import icons8PhotoGallery from "../lottie/icons8-photo-gallery.json";
import icons8Audio from "../lottie/icons8-audio.json";
import icons8Edit from "../lottie/icons8-edit.json";
import { StatusDetail } from "utils/types";
import MarconipyApi from "@/utils/marconipyApi";
import ProgressBar from "@/components/styled/ProgressBar";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import { redirect, useNavigate } from "react-router-dom";
import Card from "../components/styled/Card";
import FeedSection from "@/components/styled/FeedSection";

const GeneratingView = () => {
  const { contentset } = useContentSetContext();
  const [currentStatus, setCurrentStatus] = useState<StatusDetail | undefined>(
    contentset?.statuses.find((s: any) => s.status === contentset.status)
  );
  const [timeleft, setTimeleft] = useState("Loading...");
  const [statusDescription] = useState("Loading...");
  const [animatedProgress, setAnimatedProgress] = useState(0);
  const navigate = useNavigate();

  const defaultOptions: LottieProps["options"] = {
    loop: true,
    autoplay: true,
    animationData: icon8File,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [lottieOptions, setLottieOptions] = useState(defaultOptions);

  useEffect(() => {
    if (!contentset) return;

    let currentStatus = contentset.statuses.find(
      (s: any) => s.status === contentset.status
    );
    setCurrentStatus(currentStatus);
    updateAnimationName(contentset.status);

    calculateTimeLeft(contentset.statuses, contentset.status);
    if (contentset.status == "draft") {
      navigate("/new/" + contentset.uuid);
    }
    if (contentset.status == "failed") {
      navigate("/failed");
    }
  }, [contentset, navigate]);

  function statusRelativeLength(statusDetail: StatusDetail): number {
    if (!contentset) {
      return 0;
    }
    const totalEstimatedTime = contentset.statuses.reduce(
      (total, status) => total + status.estimated_time,
      0
    );
    const statusEstimatedTime =
      statusDetail.estimated_time > 0 ? statusDetail.estimated_time : 0;

    // Prevent division by zero
    return totalEstimatedTime !== 0
      ? statusEstimatedTime / totalEstimatedTime
      : 0;
  }

  function statusCompletion(statusDetail: StatusDetail): number {
    if (!contentset) {
      return 0;
    }
    const currentStatusIndex = contentset.statuses.findIndex(
      (status) => status.status === currentStatus?.status
    );
    const statusDetailIndex = contentset.statuses.findIndex(
      (status) => status.status === statusDetail.status
    );
    const statusEstimatedTime = statusDetail.estimated_time;

    if (currentStatusIndex === -1) {
      return 0;
    }
    if (statusDetailIndex === -1) {
      console.log("Given status not found in statuses");
      return 0;
    }
    if (statusDetailIndex < currentStatusIndex) {
      return 100;
    }
    if (statusDetailIndex > currentStatusIndex) {
      return 0;
    }
    if (statusDetailIndex === currentStatusIndex) {
      if (statusEstimatedTime > 0) {
        return 50;
      }
      return 100;
    }
    return 0;
  }

  function updateAnimationName(current_status_name: string): void {
    let soptions: LottieProps["options"] = {
      loop: true,
      autoplay: true,
      animationData: icon8File,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    switch (current_status_name) {
      case "pending_generation":
      case "searching_for_sources":
        soptions.animationData = icon8File;
        setLottieOptions(soptions);
        break;
      case "episode_writing":
        soptions.animationData = icons8Edit;
        setLottieOptions(soptions);
        break;
      case "artwork":
        soptions.animationData = icons8PhotoGallery;
        setLottieOptions(soptions);
        break;
      case "episode_recording":
        soptions.animationData = icons8Audio;
        setLottieOptions(soptions);
        break;
      default:
        soptions.animationData = icons8Edit;
        setLottieOptions(soptions);
        break;
    }
  }

  function startAnimatingProgress(status: StatusDetail): void {
    setAnimatedProgress(statusCompletion(status));

    setTimeout(() => {
      setAnimatedProgress(statusCompletion(status));
    }, 500);
  }

  function calculateTimeLeft(
    currentStatuses: StatusDetail[],
    current_status_name: string
  ): number {
    const currentIndex = currentStatuses.findIndex(
      (status) => status.status == current_status_name
    );
    if (currentIndex === -1) {
      console.log(
        `Current status not found in statuses: ${current_status_name}`
      );
      return -1;
    }
    let remainingStatuses = currentStatuses.slice(currentIndex);
    let timeLeft = remainingStatuses.reduce(
      (total, status) => total + status.estimated_time,
      0
    );
    timeLeft = timeLeft / 60;
    let timeLeftDisplay = Math.floor(timeLeft).toString();
    if (timeLeftDisplay != "0") {
      setTimeleft(`${timeLeftDisplay} minutes`);
    }
    return timeLeft;
  }

  useEffect(() => {
    if (contentset) {
      startAnimatingProgress(
        contentset.statuses.find((s: any) => s.status == contentset.status) ??
          contentset.statuses[0]
      );
    }
  });

  const statusWidths = contentset
    ? contentset.statuses.map((st) => statusRelativeLength(st) * 100)
    : [];

  return (
    <FeedSection className="py-4 flex items-center justify-center">
      <Card className="w-full">
        <div className="flex items-end justify-between py-4">
          <div className="flex flex-col gap-2">
            <div className="text-sm text-slate-500 dark:text-gray-100">
              Estimated time
            </div>
            <div className="font-bold text-3xl font-serif">{timeleft}</div>
          </div>
          <div className="w-12 h-12">
            <Lottie options={lottieOptions} />
          </div>
        </div>
        {statusWidths.length > 0 && (
          <ProgressBar
            widths={statusWidths}
            steps={contentset ? contentset.statuses.length : 0}
            currentStep={
              contentset
                ? contentset.statuses.findIndex(
                    (status) => status.status === currentStatus?.status
                  )
                : 0
            }
            stepProgress={animatedProgress}
          />
        )}
        <div className="py-4">
          {contentset && currentStatus ? (
            <p>{currentStatus.description}</p>
          ) : (
            <p>{statusDescription}</p>
          )}
        </div>
      </Card>
    </FeedSection>
  );
};

export default GeneratingView;

export async function loader({}: { params: any }) {
  try {
    if (!MarconipyApi.isAuth()) {
      return redirect("/");
    }
    return {};
  } catch (e: any) {
    if (e.json && e.json.detail && e.json.detail.includes("Banned")) {
      return redirect("/banned");
    } else if (
      e["json"] &&
      e["json"]["detail"] &&
      e["json"]["detail"] == "waitlisted"
    ) {
      console.log("Waitlist!");
      return redirect("/waitlist");
    } else {
      return redirect("/", 302);
    }
  }
}
