import { useState, useEffect, useRef, useCallback, useReducer } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import { FeedSectionType, FeedAPIResponse } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import debounce from "lodash/debounce";
import { useTabVisibility } from "@/hooks/useTabVisibility";
import {
  FeatureFlags,
  useFeatureFlagsContext,
} from "@/contexts/FeatureFlagsContext";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import useDebouncedEffect from "@/hooks/useDebouncedEffect";
// import useCustomInstructions from "./useCustomInstructions";

const useFeed = (
  initFeedAPIResponse: FeedAPIResponse,
  {
    canLoadNewContent,
    canRequestNewContent,
    refreshOnScroll,
    engagementTrackingType,
    audioOnly,
    feedType,
    topicFilter,
  }: // autoupdateCustomizations,
  {
    audioOnly?: boolean;
    canLoadNewContent?: boolean;
    canRequestNewContent?: boolean;
    refreshOnScroll?: boolean;
    engagementTrackingType?: "feed" | "detail";
    feedType?: string;
    topicFilter?: string;
    // autoupdateCustomizations?: boolean;
  }
) => {
  const posthog = usePostHog();
  const { contentset, updateContentSetLocally } = useContentSetContext();
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [storyIndex, setStoryIndex] = useState<number | null>(null);
  const trackIntervalRef = useRef<number | null>(null);
  const [newStoriesNotFound, setNewStoriesNotFound] = useState(false);
  const isTabActive = useTabVisibility();
  const paginationSize = 10;
  const refreshIntervalRef = useRef<number | null>(null);
  const [firstRefresh, setFirstRefresh] = useState(false);
  const { isFeatureEnabled } = useFeatureFlagsContext();
  const [sections, updateSections] = useReducer(
    (
      state: FeedSectionType[],
      update: { action: string; sections: FeedSectionType[] }
    ): FeedSectionType[] => {
      //this is temporary until we fix the backend: TAI-1197
      const ensureUniqueSections = (
        sections: FeedSectionType[]
      ): FeedSectionType[] => {
        const unique = new Map<string, FeedSectionType>();

        sections.forEach((section) => {
          if (section.story?.uuid) {
            unique.set(section.story.uuid, section);
          } else {
            // If no story or uuid, just add the section without uniqueness check
            unique.set(Math.random().toString(), section); // Using a random string as key for sections without uuid
          }
        });

        return Array.from(unique.values());
      };
      switch (update.action) {
        case "add":
          return ensureUniqueSections([...state, ...update.sections]);
        case "replace":
          return ensureUniqueSections(update.sections);
        default:
          return state;
      }
    },
    initFeedAPIResponse.sections ?? []
  );
  const [paginationTimestamp, setPaginationTimestamp] = useState<number | null>(
    initFeedAPIResponse.pagination_timestamp ?? null
  );
  const [isGenerating, setIsGenerating] = useState<boolean>(
    initFeedAPIResponse.status != "published" ?? false
  );
  // useCustomInstructions({
  //   stories: sections
  //     .filter((section) => section.type == "story")
  //     .map((section) => section.story!),
  //   autoUpdateCustomizations: autoupdateCustomizations ?? false,
  //   onUpdateStories: (stories) => {
  //     updateSections({
  //       action: "replace",
  //       sections: sections.map((section) => {
  //         const story = stories.find(
  //           (story) => story.uuid == section.story?.uuid
  //         );
  //         if (story) {
  //           return {
  //             ...section,
  //             story,
  //             debug: { ...section.debug, ...story.customization?.debug },
  //           };
  //         }
  //         return section;
  //       }),
  //     });
  //   },
  // });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const requestNewContent = useCallback(
    debounce(async () => {
      if (!canRequestNewContent) {
        return;
      }
      await MarconipyApi.requestNewContent();
      setIsGenerating(true);
    }, 2000),
    [canRequestNewContent]
  );

  const refreshFeed = useCallback(
    async (
      reset: boolean = false,
      newFeedType: string | null = null,
      newTopicFilter: string | null = null
    ) => {
      const getFeed = async (
        currentSections: FeedSectionType[],
        timestamp: number | null
      ) => {
        const _feedType = newFeedType ?? feedType;
        const _topicFilter = newTopicFilter ?? topicFilter;
        const newfeed: FeedAPIResponse = (await MarconipyApi.getFeed({
          audioOnly,
          feedType: _feedType,
          topicFilter: _topicFilter,
          paginationTimestamp: timestamp,
          debugMode: isFeatureEnabled(FeatureFlags.FEED_DEBUG_MODE),
        })) as any as FeedAPIResponse;
        const newSections = newfeed.sections.filter((section) => {
          if (section.type == "story") {
            return !currentSections.find(
              (currentSection) => currentSection.uuid == section.uuid
            );
          } else {
            // for now, we don't have more than one of each type of section that is not a story
            return !currentSections.find(
              (currentSection) => currentSection.type == section.type
            );
          }
        });
        return {
          newFeed,
          newSections,
          newPaginationTimestamp: newfeed.pagination_timestamp ?? null,
        };
      };

      let newPaginationTimestamp = paginationTimestamp;
      if (reset) {
        newPaginationTimestamp = null;
      }
      let newFeed: FeedAPIResponse | null = null;
      let newSections: FeedSectionType[] = [];
      const currentSections = reset ? [] : sections;
      const {
        newFeed: _newFeed,
        newSections: _newSections,
        newPaginationTimestamp: _timestamp,
      } = await getFeed(currentSections, newPaginationTimestamp);
      newFeed = _newFeed;
      newPaginationTimestamp = _timestamp;
      newSections = newSections.concat(_newSections);
      let _isGenerating = newFeed ? newFeed.status != "published" : false;
      if (
        newFeed &&
        newFeed.status &&
        contentset &&
        newFeed.status != contentset.status
      ) {
        updateContentSetLocally({
          ...contentset,
          status: newFeed.status,
        });
        setIsGenerating(_isGenerating);
      }
      if (newPaginationTimestamp) {
        setPaginationTimestamp(newPaginationTimestamp);
      }
      if (newSections.length == 0) {
        if (reset) {
          updateSections({ action: "replace", sections: [] });
        }
        setNewStoriesNotFound(true);
        if (!_isGenerating) {
          requestNewContent();
        }
      } else {
        if (reset) {
          updateSections({ action: "replace", sections: newSections });
        } else {
          updateSections({ action: "add", sections: newSections });
        }
        setNewStoriesNotFound(false);
      }
      setIsLoading(false);
    },
    [
      contentset,
      audioOnly,
      feedType,
      isFeatureEnabled,
      paginationTimestamp,
      requestNewContent,
      sections,
      setIsGenerating,
      setPaginationTimestamp,
      topicFilter,
      updateContentSetLocally,
      updateSections,
    ]
  );

  useDebouncedEffect(
    () => {
      if (!canLoadNewContent) {
        return;
      }
      if (sections.length == 0 && refreshIntervalRef.current == null) {
        refreshFeed();
        refreshIntervalRef.current = setInterval(() => {
          refreshFeed();
        }, 5000);
      } else if (sections.length > 0 && refreshIntervalRef.current) {
        clearInterval(refreshIntervalRef.current);
      }
    },
    [canLoadNewContent, sections, refreshFeed],
    1000
  );

  useDebouncedEffect(
    () => {
      if (
        canLoadNewContent &&
        !firstRefresh &&
        sections.length < paginationSize
      ) {
        setTimeout(() => {
          refreshFeed();
        }, 1000);
        setFirstRefresh(true);
      }
    },
    [canLoadNewContent, sections, firstRefresh, refreshFeed],
    1000
  );

  useEffect(() => {
    const trackReadTime = async () => {
      if (sections.length == 0 || !contentset) {
        return;
      }
      posthog?.capture("read to 5 seconds " + engagementTrackingType, {
        contentset_uuid: contentset.uuid,
      });
      if (
        storyIndex != null &&
        sections[storyIndex] &&
        (sections[storyIndex].type == "story" ||
          sections[storyIndex].type == "sourceitemstorymeta")
      ) {
        //check if the browser window is in focus
        if (isTabActive) {
          await MarconipyApi.updateStoryMeta(
            sections[storyIndex!].story!.uuid,
            0
          );
        }
      }
    };

    async function handleVisibilityChange() {
      if (document.visibilityState === "visible" && engagementTrackingType) {
        // The tab is active, start the interval if not already started
        if (trackIntervalRef.current != null) {
          clearInterval(trackIntervalRef.current);
        }
        trackIntervalRef.current = setInterval(() => {
          trackReadTime();
        }, 5000);
      } else {
        // The tab is inactive, clear the interval
        if (trackIntervalRef.current != null) {
          clearInterval(trackIntervalRef.current);
          trackIntervalRef.current = null;
        }
      }
    }
    // Listen for visibility change events
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Call the handler once to start the interval if the page is visible on load
    handleVisibilityChange();

    // Cleanup
    return () => {
      if (trackIntervalRef.current != null) {
        clearInterval(trackIntervalRef.current);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [
    posthog,
    contentset,
    storyIndex,
    sections,
    isTabActive,
    engagementTrackingType,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce(() => {
      if (!containerRef.current) return;
      let containerTop = containerRef.current.offsetTop;
      let containerHeight = containerRef.current.clientHeight;

      let visibleTops: number[] = [];
      let visibleBottoms: number[] = [];
      let divToTriggerRefresh = null;
      for (let i = 0; i < cardRefs.current.length; i++) {
        const cardRef = cardRefs.current[i];
        if (cardRef != null) {
          if (divToTriggerRefresh == null && i >= cardRefs.current.length - 4) {
            divToTriggerRefresh = cardRef;
          }
          const cardTop = cardRef.getBoundingClientRect().top;
          const cardBottom = cardRef.getBoundingClientRect().bottom;
          const cardMiddle =
            cardTop + cardRef.getBoundingClientRect().height / 2;

          if (cardTop >= containerTop) {
            visibleTops.push(i);
          } else if (
            cardBottom > containerTop + containerHeight / 2 ||
            cardMiddle > containerTop
          ) {
            visibleBottoms.push(i);
          }
        }
      }
      let _storyIndex: number | null = null;
      if (
        visibleBottoms.length > 0 &&
        visibleTops.length > 0 &&
        visibleBottoms[0] <= visibleTops[0]
      ) {
        _storyIndex = visibleBottoms[0];
      } else if (visibleTops.length > 0) {
        _storyIndex = visibleTops[0];
      }
      if (_storyIndex != null && _storyIndex != storyIndex) {
        setStoryIndex(_storyIndex);
      }

      if (divToTriggerRefresh) {
        if (
          divToTriggerRefresh.getBoundingClientRect().top < window.innerHeight
        ) {
          refreshFeed();
        }
      }
    }, 1000),
    [refreshFeed, storyIndex]
  );

  useEffect(() => {
    if (!canLoadNewContent || !refreshOnScroll) {
      return;
    }
    const container = containerRef.current;
    container?.addEventListener("scroll", handleScroll);

    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [canLoadNewContent, handleScroll, refreshOnScroll]);

  useDebouncedEffect(
    () => {
      // request new content once on load
      if (!canRequestNewContent) {
        return;
      }
      requestNewContent();
    },
    [canRequestNewContent, requestNewContent],
    1000
  );

  useEffect(() => {
    updateSections({
      action: "replace",
      sections: initFeedAPIResponse.sections ?? [],
    });
  }, [initFeedAPIResponse.sections]);

  return {
    cardRefs,
    containerRef,
    sections,
    isGenerating,
    isLoading,
    newStoriesNotFound,
    refreshFeed,
  };
};

export default useFeed;
