import { useCallback, useState } from "react";
import { StoryLikeButton } from "./StoryLikeButton";
import { StoryShareButton } from "./StoryShareButton";
import MarconipyApi from "@/utils/marconipyApi";
import { RiEyeFill } from "react-icons/ri";
import { useAuth } from "@/contexts/AuthenticationContext";
import { Story } from "@/utils/types";
import { TextFrontend } from "@/utils/Text";
import { StoryDislikeButton } from "./StoryDislikeButton";
import throttle from "lodash/throttle";

type StorySocialBarProps = {
  story: Story;
  onRefreshNeeded?: () => void;
  onAuthRedirect?: () => void;
  showViews?: boolean;
};

export const StorySocialBar = ({
  story,
  onRefreshNeeded = () => {},
  onAuthRedirect = () => {},
  showViews = true,
}: StorySocialBarProps) => {
  const [liked, setLiked] = useState(story.liked);
  const [disliked, setDisliked] = useState(story.disliked);
  const [likesCount, setLikesCount] = useState(story.likes_count);
  const { isAuth } = useAuth();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLike = useCallback(
    throttle(() => {
      if (isAuth) {
        MarconipyApi.trackStoryEvent(story.uuid, "like");
        setLiked(true);
        setLikesCount((prevCount) => prevCount + 1);
        setDisliked(false);
        onRefreshNeeded();
      } else {
        onAuthRedirect();
      }
    }, 500),
    [isAuth, onAuthRedirect, onRefreshNeeded, story.uuid]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRemoveLike = useCallback(
    throttle(() => {
      if (isAuth) {
        MarconipyApi.trackStoryEvent(story.uuid, "remove_like");
        setLiked(false);
        setLikesCount((prevCount) => prevCount - 1);
        setDisliked(false);
        onRefreshNeeded();
      } else {
        onAuthRedirect();
      }
    }, 500),
    [isAuth, onAuthRedirect, onRefreshNeeded, story.uuid]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDislike = useCallback(
    throttle(() => {
      if (isAuth) {
        MarconipyApi.trackStoryEvent(story.uuid, "dislike");
        setDisliked(true);
        if (liked) {
          setLikesCount((prevCount) => prevCount - 1);
        }
        setLiked(false);
        onRefreshNeeded();
      } else {
        onAuthRedirect();
      }
    }, 500),
    [isAuth, liked, onAuthRedirect, onRefreshNeeded, story.uuid]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRemoveDislike = useCallback(
    throttle(() => {
      if (isAuth) {
        MarconipyApi.trackStoryEvent(story.uuid, "remove_dislike");
        setDisliked(false);
        setLiked(false);
        onRefreshNeeded();
      } else {
        onAuthRedirect();
      }
    }, 500),
    [isAuth, onAuthRedirect, onRefreshNeeded, story.uuid]
  );

  const onShare = useCallback(async () => {
    await MarconipyApi.trackStoryEvent(story.uuid, "share");
    onRefreshNeeded();
  }, [onRefreshNeeded, story]);

  return (
    <div className="flex flex-row gap-2 sm:gap-4 justify-center">
      {showViews && story.views_count > 1 && (
        <div className="flex flex-row gap-1 items-center px-2">
          <RiEyeFill />
          <small className="">
            {TextFrontend.formatNumber(story.views_count)}
          </small>
        </div>
      )}
      <StoryLikeButton
        story={story}
        onLike={liked ? onRemoveLike : onLike}
        liked={liked}
        likesCount={likesCount}
        canLike={true}
      />
      <StoryDislikeButton
        story={story}
        onDislike={disliked ? onRemoveDislike : onDislike}
        disliked={disliked}
        canDislike={true}
      />
      <StoryShareButton
        story={story}
        onShare={onShare}
        canShare={true}
        url={story.permalink}
        text={story.title}
      />
    </div>
  );
};
