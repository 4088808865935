import { Helmet } from "react-helmet";
import Container from "@/components/styled/Container";
import { Footer } from "@/components/Footer";
import Divider from "@/components/styled/Divider";

export default function ContactUs() {
  return (
    <>
      <Helmet>
        <title>Contact us - Tailor</title>
        <meta name="description" content="Get in touch with the Tailor team." />
        <meta property="og:title" content="Contact us - Tailor" />
        <meta
          property="og:description"
          content="Get in touch with the Tailor team."
        />
        <meta property="og:url" content="https://tailor.news/contact-us" />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
        <link rel="canonical" href="https://tailor.news/contact-us" />
      </Helmet>
      <div className="col-span-10 col-start-2">
        <Container className="my-20 pb-20 bg-white dark:bg-black rounded-lg">
          <h1 className="static">Contact us</h1>
          <br />
          <p>You can email us at info [at] tailor.news</p>
          <br />
          <br />
          <p>
            <strong>The company</strong>
          </p>
          <p>Tailor Media, Inc.</p>
          <p>9450 SW Gemini, Dr PMB 88021</p>
          <p>Beaverton, OR 97008</p>
          <br />
          <p>Website: tailor.news</p>
          <p>DUNS Number: 118972121</p>
        </Container>
      </div>
      <div className="col-span-12">
        <Divider />
        <Footer />
      </div>
    </>
  );
}
