import { useState, useEffect } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import { Topic } from "utils/types";
import SuggestionTag from "@/components/SuggestionTag";
import SearchResult from "@/components/SearchResult";
import { RiBookmarkLine, RiCloseFill, RiSearch2Line } from "react-icons/ri";
import { DebounceInput } from "react-debounce-input";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import HostHeader from "./HostHeader";
import Button from "./styled/Button";
import classnames from "classnames";
import { throttle } from "lodash";
import Card from "./styled/Card";

const TopicSuggestionSection = () => {
  const { contentset, updateContentSetOptimistically } = useContentSetContext();
  const [featuredTopics, setFeaturedTopics] = useState<Topic[]>([]);
  const [shownSelectedTopics, setShownSelectedTopics] = useState<Topic[]>([]);
  const [shownFeaturedTopics, setShownFeaturedTopics] = useState<Topic[]>([]);
  const [query, setQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Topic[]>([]);
  const [searchInputIsFocused, setSearchInputIsFocused] =
    useState<boolean>(false);
  const [topicsHaveChanged, setTopicsHaveChanged] = useState<boolean>(false);
  const [clickedButton, setClickedButton] = useState<boolean>(false);
  const [queryIsInResults, setQueryIsInResults] = useState<boolean>(false);

  useEffect(() => {
    const refreshRecommendedTopics = throttle(async () => {
      if (!contentset) {
        return;
      }
      let allFeaturedtopics = (await MarconipyApi.getRecommendedTopics(
        contentset.uuid
      )) as unknown as Topic[];
      allFeaturedtopics = allFeaturedtopics.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setShownSelectedTopics(contentset.topics);
      setFeaturedTopics(allFeaturedtopics);
      setShownFeaturedTopics(
        filterAddedTopics(allFeaturedtopics, contentset?.topics ?? [])
      );
    }, 5000);
    if (contentset && shownSelectedTopics.length == 0) {
      refreshRecommendedTopics();
    }
  }, [contentset, shownSelectedTopics.length]);

  const confirmTopics = async function () {
    if (contentset) {
      updateContentSetOptimistically(
        {
          ...contentset,
          topics: shownSelectedTopics,
        },
        async () => {
          await MarconipyApi.updateTopics(contentset.uuid, {
            uuids: shownSelectedTopics.map((topic) => topic.uuid),
          });
        }
      );
      setTopicsHaveChanged(false);
    }
  };

  const addFeaturedTag = async function (topic: Topic) {
    setQuery("");
    setSuggestions([]);
    setSearchInputIsFocused(false);
    const newTopics = [
      ...(shownSelectedTopics ?? []),
      { ...topic, name: topic.name[0].toUpperCase() + topic.name.slice(1) },
    ].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    setShownFeaturedTopics(filterAddedTopics(featuredTopics, newTopics));
    setShownSelectedTopics(newTopics);
    setTopicsHaveChanged(true);
  };
  const removeTag = async function (topic: Topic) {
    const newTopics =
      shownSelectedTopics
        .filter((_topic) => _topic.uuid !== topic.uuid)
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ) ?? [];
    setShownFeaturedTopics(filterAddedTopics(featuredTopics, newTopics));
    setShownSelectedTopics(newTopics);
    setTopicsHaveChanged(true);
  };

  const searchAutocomplete = async function (squery: string) {
    setQuery(squery);
    if (squery.length < 3) {
      setSuggestions([]);
      return;
    }
    let results = (await MarconipyApi.searchTopicAutocomplete(
      squery
    )) as any as Topic[];
    const filteredResults = results.filter(
      (result) =>
        !shownSelectedTopics.some(
          (topic) =>
            topic.uuid === result.uuid ||
            result.name.toLowerCase() === topic.name.toLowerCase()
        )
    );
    setQueryIsInResults(
      results.some(
        (result) => result.name.toLowerCase() === squery.toLowerCase()
      )
    );
    setSuggestions(filteredResults);
  };

  const searchWasFocused = async function () {
    setSearchInputIsFocused(true);
  };
  const searchWasoutOfFocus = async function () {
    setQuery("");
    setSearchInputIsFocused(false);
    setSuggestions([]);
  };

  const searchAndAddTopic = async function (topic: Topic) {
    setQuery("");
    setSuggestions([]);
    setSearchInputIsFocused(false);
    const topicAlreadyAdded = shownSelectedTopics.some(
      (t) => t.name.toLowerCase() === topic.name.toLowerCase()
    );
    if (topicAlreadyAdded) {
      return;
    }
    const newTopic = (await MarconipyApi.searchTopic(
      topic.name
    )) as any as Topic;
    if (newTopic) {
      const newTopics = [...(shownSelectedTopics ?? []), newTopic].sort(
        (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      setShownFeaturedTopics(filterAddedTopics(featuredTopics, newTopics));
      setShownSelectedTopics(newTopics);
      setTopicsHaveChanged(true);
    }
  };
  const handleTopicSubmit = async function (e: any) {
    e.preventDefault();
    await searchAndAddTopic({
      name: query,
      uuid: "temp",
      last_sourceset_search: null,
    });
    setQuery("");
  };

  const filterAddedTopics = (
    featured_topics: Topic[],
    addedTopics: Topic[]
  ) => {
    const filteredTopics = featured_topics.filter(
      (ftopic) => !addedTopics.some((atopic) => atopic.uuid === ftopic.uuid)
    );
    return filteredTopics;
  };

  useEffect(() => {
    setClickedButton(false);
  }, [topicsHaveChanged]);

  return (
    <Card>
      <HostHeader
        title="What else are you interested in?"
        subtitle="The more, the better!"
      />
      <form onSubmit={handleTopicSubmit} className="">
        <div className="flex relative">
          <DebounceInput
            type="text"
            value={query}
            placeholder="🔎 Search..."
            onChange={(e) => searchAutocomplete(e.target.value)}
            onFocus={searchWasFocused}
            className="text-black dark:text-white bg-white dark:bg-black border border-gray rounded-md py-1 px-2 w-full"
          />
          {searchInputIsFocused && (
            <span className="absolute flex p-2 items-center inset-y-0 right-0 cursor-pointer hover:text-primary">
              <RiCloseFill onClick={searchWasoutOfFocus} className="" />
            </span>
          )}
        </div>
      </form>
      {(suggestions.length > 0 || query?.length > 0) && (
        <div className="flex flex-col my-2">
          {query?.length > 0 && !queryIsInResults && (
            <SearchResult
              key={query}
              value={{ name: query, uuid: "temp", last_sourceset_search: null }}
              icon={<RiSearch2Line />}
              callback={searchAndAddTopic}
            />
          )}
          {suggestions?.map((topic) => (
            <SearchResult
              key={topic.uuid}
              value={topic}
              icon={<RiBookmarkLine />}
              callback={addFeaturedTag}
            />
          ))}
        </div>
      )}
      {!searchInputIsFocused && contentset && (
        <>
          <div className="w-full flex gap-2 flex-wrap my-4">
            {shownSelectedTopics.map((topic) => (
              <SuggestionTag
                selected={true}
                key={topic.uuid}
                value={topic}
                icon={<RiCloseFill />}
                clickOnIcon={true}
                callback={removeTag}
              />
            ))}
            {shownFeaturedTopics.map((topic) => (
              <SuggestionTag
                icon={null}
                selected={false}
                key={topic.uuid}
                value={topic}
                callback={addFeaturedTag}
              />
            ))}
          </div>
        </>
      )}
      {topicsHaveChanged && !searchInputIsFocused && (
        <>
          <Button
            onClick={() => {
              if (shownSelectedTopics.length < 4) {
                setClickedButton(true);
                return;
              }
              confirmTopics();
            }}
            $fullwidth
            variant="primary"
            className={classnames({
              "opacity-50": shownSelectedTopics.length < 4,
            })}
          >
            Confirm new topics
          </Button>
          {clickedButton && (
            <small className="text-primary">Select at least 4 topics</small>
          )}
        </>
      )}
      {searchInputIsFocused && suggestions.length == 0 && query == "" && (
        <>
          <div className="flex flex-col align-center text-center mt-8 gap-2">
            <h2>Search for an interest</h2>
            <p className="font-sans text-black dark:text-white text-base">
              Try to be as specific as possible
            </p>
          </div>
        </>
      )}
    </Card>
  );
};

export default TopicSuggestionSection;
