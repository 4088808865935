import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLoaderData } from "react-router-dom";
import { Loading } from "@/components/Loading";

import { useContentSetContext } from "@/contexts/ContentSetContext";
import { FeedPartialView } from "./partials/FeedPartialView";
import MarconipyApi from "@/utils/marconipyApi";
import { redirect } from "react-router-dom";
import { FeedAPIResponse } from "@/utils/types";
import { overriddenFeatureFlagsObject } from "@/App";
import { FeatureFlags } from "@/contexts/FeatureFlagsContext";
import { TailorErrorBoundary } from "@/contexts/TailorErrorBoundary";

export default function HistoryView() {
  const data = useLoaderData() as any;
  const { contentset } = useContentSetContext();
  const [feed, setFeed] = React.useState<FeedAPIResponse | null>(null);
  const [genericError, setGenericError] = React.useState<string | null>(null);

  useEffect(() => {
    const loadFeed = async () => {
      try {
        const newfeed = await data.feed;
        setFeed(newfeed);
      } catch (e) {
        setGenericError(e as any);
      }
    };
    loadFeed();
  }, [data]);

  return (
    <>
      <Helmet>
        <title>History - Tailor </title>
      </Helmet>
      {feed && contentset ? (
        <FeedPartialView
          feed={feed}
          canLoadNewContent={true}
          canShowGeneratingView={false}
          canRequestNewContent={false}
          advancedFeedOptions={true}
          disableScroll={false}
          defaultFeedType="history"
        />
      ) : (
        <>{!genericError && <Loading />}</>
      )}
      {genericError && <TailorErrorBoundary error={genericError} />}
    </>
  );
}

export async function loader() {
  if (!MarconipyApi.isAuth()) {
    return redirect("/signup");
  }
  let debugMode = false;
  if (overriddenFeatureFlagsObject[FeatureFlags.FEED_DEBUG_MODE]) {
    debugMode = true;
  }
  try {
    const feedResponse = MarconipyApi.getFeed({
      feedType: "history",
      debugMode,
    }) as Promise<any>;
    return {
      feed: feedResponse,
    };
  } catch (e: any) {
    if (e.json && e.json.detail && e.json.detail.includes("Banned")) {
      return redirect("/banned");
    } else if (
      e["json"] &&
      e["json"]["detail"] &&
      e["json"]["detail"] == "waitlisted"
    ) {
      console.log("Waitlist!");
      return redirect("/waitlist");
    } else {
      throw e;
    }
  }
}
