import { useEffect, useState } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import { redirect } from "react-router-dom";
import Container from "@/components/styled/Container";
import Button from "@/components/styled/Button";
import Card from "@/components/styled/Card";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import { useNavigate } from "react-router-dom";

export default function FailedContentSetView() {
  const { contentset } = useContentSetContext();
  const [resetting, setResetting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!contentset) {
      return;
    } else if (contentset.status == "draft") {
      navigate(`/new/${contentset.uuid}`);
    } else if (contentset.status != "failed") {
      navigate(`/discover`);
    }
  }, [contentset, navigate]);

  const nextStep = async () => {
    setResetting(true);
    return navigate(`/edit`);
  };

  if (!contentset) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{"There was an error"}</title>
      </Helmet>
      <Container>
        <Card>
          <h2>Woops, there was an error!</h2>
          <br />
          <br />
          <p className="mb-10">
            Tailor could not find any relevant publications given your
            interests. Try phrasing your interests differently or adding sources
            one by one in your settings.
          </p>
          <Button
            disabled={resetting}
            onClick={() => nextStep()}
            variant="primary"
            $fullwidth
          >
            {`${resetting ? "Loading" : "Adjust interests"}`}
          </Button>
        </Card>
      </Container>
    </>
  );
}

export async function loader() {
  try {
    //check if the user is already logged in
    const loggedIn = MarconipyApi.isAuth();
    if (!loggedIn) {
      console.log("User is not logged in");
      return redirect("/login");
    }
    return {};
  } catch (e: any) {
    console.log("error, check if waitlist");
    console.log(e.response.data);
    if (e.json && e.json.detail && e.json.detail.includes("waitlist")) {
      return redirect("/waitlist");
    }
    if (e.response && e.response.data && e.response.data.includes("Banned")) {
      return redirect("/banned");
    }
    throw e;
  }
}
