import { useState } from "react";
import { usePostHog } from "posthog-js/react";
import Button from "./styled/Button";
import { RiFileCopyLine, RiShareBoxLine } from "react-icons/ri";
import Modal from "./styled/Modal";
import { Digest } from "@/utils/types";
import { TextFrontend } from "@/utils/Text";
import { ShareButton } from "./ShareButton";

type DigestShareButtonProps = {
  digest: Digest;
  canShare: boolean;
  url: string;
  text: string;
  open?: boolean;
};

export const DigestShareButton = (props: DigestShareButtonProps) => {
  const posthog = usePostHog();
  const [openShareOverlay, setOpenShareOverlay] = useState(props.open || false);
  const handleCloseShareOverlay = () => {
    setOpenShareOverlay(false);
  };
  const handleShare = async () => {
    posthog?.capture("share", {
      trigger: "digest_share",
    });
  };
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(props.text);
      handleShare();
      alert("Text copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <>
      {props.canShare && (
        <>
          <Button
            variant="secondary"
            onClick={() => setOpenShareOverlay(!openShareOverlay)}
            icon={<RiShareBoxLine />}
            title="Share"
            aria-label="Share"
            role="button"
            hideTextOnMobile
          >
            Share
          </Button>
        </>
      )}
      <Modal open={openShareOverlay} onClose={handleCloseShareOverlay}>
        <Modal.Title>Share</Modal.Title>
        <Modal.Body>
          <div className="flex flex-col gap-2 items-center">
            <small className="opacity-50 uppercase text-black dark:text-white">
              Share link:
            </small>
            <div className="py-8 px-4 w-full">
              <ShareButton
                size="lg"
                url={props.url}
                title={props.digest.title}
                onShare={handleShare}
              />
            </div>
            <small className="opacity-50 uppercase text-black dark:text-white">
              {`Share your digest's content`}:
            </small>
            <div className="relative m-4">
              <div className="absolute right-8 top-5">
                <Button
                  className="p-2"
                  variant="secondary"
                  onClick={handleCopy}
                  size="sm"
                >
                  <RiFileCopyLine size={20} />
                </Button>
              </div>
              <div className="border rounded-lg p-4 max-h-80 overflow-y-scroll text-black dark:text-white">
                <p
                  className="py-2"
                  dangerouslySetInnerHTML={{
                    __html: TextFrontend.stripMarkdownLines(props.text, true),
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
