import { Helmet } from "react-helmet";
import Container from "@/components/styled/Container";
import { Footer } from "@/components/Footer";
import Divider from "@/components/styled/Divider";

export default function Careers() {
  return (
    <>
      <Helmet>
        <title>Careers at Tailor </title>
        <meta
          name="description"
          content="Join the Tailor team. Help the world make sense of the noise."
        />
        <meta property="og:title" content="Careers at Tailor" />
        <meta
          property="og:description"
          content="Join the Tailor team. Help the world make sense of the noise.."
        />
        <meta property="og:url" content="https://tailor.news/careers" />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
        <link rel="canonical" href="https://tailor.news/careers" />
      </Helmet>
      <div className="col-span-10 col-start-2">
        <Container className="my-20 pb-20 bg-white dark:bg-black rounded-lg">
          <h1 className="">Careers</h1>
          <p>
            Send your application at info [at] usetailor.com
            <br />
            with a link to your LinkedIn or Github profile.
          </p>
        </Container>
      </div>
      <div className="col-span-12">
        <Divider />
        <Footer />
      </div>
    </>
  );
}
