import { Helmet } from "react-helmet";
import { redirect, useNavigate } from "react-router-dom";
import Container from "@/components/styled/Container";
import Card from "@/components/styled/Card";
import { Footer } from "@/components/Footer";
import Divider from "@/components/styled/Divider";
import StaticFooter from "./StaticFooter";

export default function OurPrinciples() {
  return (
    <>
      <Helmet>
        <title>Our Principles - Tailor AI</title>
        <meta
          name="description"
          content="Explore Tailor's approach to addressing important problems with technology and AI, focusing on principles such as authenticity, truthfulness, trust, diversity, safety, openness, privacy, ethical utilization, collaboration, and AI safety exploration"
        />
        <meta property="og:title" content="Our Principles" />
        <meta
          property="og:description"
          content="Explore Tailor's approach to addressing important problems with technology and AI, focusing on principles such as authenticity, truthfulness, trust, diversity, safety, openness, privacy, ethical utilization, collaboration, and AI safety exploration"
        />
        <meta property="og:url" content="https://tailor.news/principles" />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
        <link rel="canonical" href="https://tailor.news/principles" />
      </Helmet>
      <div className="col-span-10 col-start-2 bg-white dark:bg-black mt-10 mb-10">
        <Container>
          <h1 className="static">Our Principles</h1>
          <h2>Get your news every day with Tailor</h2>
          <p>
            This document outlines the principles that guide our efforts to
            utilize technology and AI in solving important problems. Recognizing
            both the potential benefits and challenges associated with AI
            progress, we commit to following these principles in order to
            effectively execute Tailor's mission in a responsible and
            conscientious manner.
          </p>
          <ul role="list" className="list">
            <li className="list-item">
              <strong>Safeguard authenticity</strong>: We will avoid
              impersonating real individuals, ensuring the integrity and
              originality of the personalized media experiences we create.
            </li>
            <li className="list-item-2">
              <strong>Champion truthfulness</strong>: We are dedicated to
              providing content based on accurate information and real-world
              events, combating the spread of misinformation and false
              narratives.
            </li>
            <li className="list-item-3">
              <strong>Build lasting trust</strong>: We acknowledge the
              importance of trust in the media we generate and will consistently
              work towards maintaining the confidence of our users and partners.
            </li>
            <li className="list-item-4">
              <strong>Celebrate diversity and fairness</strong>: By minimizing
              biases and embracing inclusivity, we ensure that our AI systems
              respect and represent the variety of our global audience.
            </li>
            <li className="list-item-5">
              <strong>Ensure safety and dependability</strong>: Our commitment
              to thorough testing and ongoing refinement of our AI tools
              guarantees a secure and reliable media generation experience.
              <br />
            </li>
            <li className="list-item-6">
              <strong>Foster openness and responsibility</strong>: We pledge to
              be transparent about our AI development process and responsive to
              the needs and concerns of our users and stakeholders.
            </li>
            <li className="list-item-7">
              <strong>Protect privacy and user data</strong>: We prioritize
              privacy by incorporating data protection into the design of our AI
              systems, diligently handling personal information and user data.
            </li>
            <li className="list-item-8">
              <strong>Encourage ethical utilization</strong>: We actively
              promote responsible applications of our AI technology that align
              with our core values and contribute positively to society.
            </li>
            <li className="list-item-9">
              <strong>Adapt and collaborate</strong>: We will continuously
              monitor our AI systems, engage with our users and the wider
              community, and evolve our strategies to enhance safety and
              effectiveness.
              <br />
            </li>
            <li className="list-item-10">
              <strong>Advance AI safety exploration</strong>: We will support
              and contribute to research efforts that tackle the unique trust
              and safety challenges in generative AI, fostering a safer AI
              landscape for all.
            </li>
          </ul>
        </Container>
      </div>
      <Divider className="mt-10" />
      <Footer />
    </>
  );
}
