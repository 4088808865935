import MarconipyApi from "@/utils/marconipyApi";
import { ContentSet } from "@/utils/types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthenticationContext";
import { useNavigate } from "react-router-dom";

type ContentSetContextType = {
  contentset: ContentSet | null;
  updateContentSetLocally: (contentset: ContentSet) => void;
  updateContentSetOptimistically: (
    contentset: ContentSet,
    updateFunction: any
  ) => void;
  reloadContentSet: () => Promise<ContentSet | null>;
};

const ContentSetContext = createContext<ContentSetContextType>({
  contentset: null,
  updateContentSetLocally: () => {},
  updateContentSetOptimistically: () => {},
  reloadContentSet: async () => null,
});

export const useContentSetContext = () => {
  const context = useContext(ContentSetContext);
  if (!context) {
    throw new Error(
      "useContentsetContext must be used within a ContentsetProvider"
    );
  }
  return context;
};

export const ContentSetContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isAuth } = useAuth();
  const [loaded, setLoaded] = useState(false);
  const [contentsetUUID, setContentsetUUID] = useState<string | null>(null);
  const [contentset, setContentset] = useState<ContentSet | null>(null);
  const navigate = useNavigate();

  const loadContentsets = useCallback(async () => {
    MarconipyApi.loadToken();
    let contentsets =
      (await MarconipyApi.getContentSets()) as any as ContentSet[];
    setLoaded(true);
    if (contentsets.length > 0) {
      let _contentset = contentsets[0];
      setContentset(_contentset);
      setContentsetUUID(_contentset.uuid);
    } else {
      return [];
    }
    return contentsets;
  }, []);

  const reloadContentSet = useCallback(async () => {
    let _contentset: ContentSet | null = null;
    if (contentsetUUID == null) {
      const _contentsets = await loadContentsets();
      if (_contentsets.length == 0) {
        return null;
      } else {
        _contentset = _contentsets[0];
      }
    } else {
      _contentset = (await MarconipyApi.getContentSetByUUID(
        contentsetUUID
      )) as any as ContentSet;
    }
    setContentset(_contentset);
    return _contentset;
  }, [contentsetUUID, loadContentsets]);

  const updateContentSetOptimistically = useCallback(
    async (contentset: ContentSet, updateFunction: any) => {
      setContentset(contentset);
      await updateFunction();
      await reloadContentSet();
    },
    [reloadContentSet]
  );

  const updateContentSetLocally = useCallback(
    (contentset: ContentSet) => {
      setContentset(contentset);
    },
    [setContentset]
  );

  useEffect(() => {
    if (isAuth) {
      loadContentsets();
    } else {
      setContentsetUUID(null);
      setContentset(null);
      setLoaded(false);
    }
  }, [isAuth, loadContentsets]);

  useEffect(() => {
    if (loaded && contentset == null) {
      navigate("/new/default");
    }
  }, [loaded, contentset, navigate]);

  return (
    <ContentSetContext.Provider
      value={{
        contentset,
        updateContentSetOptimistically,
        updateContentSetLocally,
        reloadContentSet,
      }}
    >
      {children}
    </ContentSetContext.Provider>
  );
};
