import { useEffect, useState, useCallback } from "react";
import { Story, UserQuery } from "utils/types";
import { StorySocialBar } from "@/components/StorySocialBar";
import SubchapterTitle from "@/components/SubchapterTitle";
import MarconipyApi from "@/utils/marconipyApi";
import { StoryDetailTopRow } from "@/components/StoryDetailTopRow";
import { UserQueryInput } from "@/components/UserQueryInput";
import { UserQueryQuestionAnswer } from "@/components/UserQueryQuestionAnswer";
import PublicSignupCTACard from "@/components/PublicSignupCTACard";
import BulletpointsBlock from "@/components/BulletpointsBlock";
import useSourceMap from "@/hooks/useSourceMap";
import LongformBlock from "@/components/LongformBlock";

type StoryDetailViewProps = {
  story: Story;
  onPlay?: (uuid: string) => void;
  onRefreshNeeded?: () => void;
  onAuthRedirect?: () => void;
  debug?: Record<string, any>;
};

const StoryDetailView = ({
  story,
  onRefreshNeeded,
  onAuthRedirect,
  debug,
}: StoryDetailViewProps) => {
  const [storyState, setStoryState] = useState<Story>(story);
  const [storyDebug] = useState<Record<string, any>>(debug ?? {});
  const [userqueries, setUserQueries] = useState<UserQuery[]>([]);
  const [isLoadingUserQueries, setIsLoadingUserQueries] = useState(true);

  // const [isLoadingCustomization, setLoadingCustomization] = useState(false);
  // const { customizationPromptsActive, customizeStories } =
  //   useCustomInstructions({
  //     stories: [story],
  //     autoUpdateCustomizations: false,
  //     onUpdateStories: (stories) => {
  //       setStoryState(stories[0]);
  //       setStoryDebug({ ...storyDebug, ...stories[0].customization?.debug });
  //       setLoadingCustomization(false);
  //     },
  //   });

  const grabUserQueries = useCallback(async () => {
    let queriesUUUIDS = (await MarconipyApi.getQueriesPerStory(
      story.uuid
    )) as any as string[];
    setUserQueries([]);
    let grabbedQueries = [];
    for (const queryUUID of queriesUUUIDS) {
      let query = (await MarconipyApi.getQuery(queryUUID)) as any as UserQuery;
      grabbedQueries.push(query);
    }
    setUserQueries(grabbedQueries);
    setIsLoadingUserQueries(false);
  }, [story.uuid]);

  useEffect(() => {
    // Define an async function inside useEffect
    const fetchStory = async () => {
      try {
        const completeStory = (await MarconipyApi.getStory(
          story.uuid
        )) as any as Story;
        setStoryState(completeStory);
        await grabUserQueries();
      } catch (error) {
        console.error("Error fetching story:", error);
        // handle error appropriately
      }
    };

    // Call the async function
    fetchStory();
  }, [grabUserQueries, story.uuid]);

  const addUserQuery = async (query: string) => {
    let newuserquery = (await MarconipyApi.addQuery(
      query,
      story.uuid
    )) as any as UserQuery;
    //check if the query is already in the list
    if (userqueries.find((userquery) => userquery.uuid === newuserquery.uuid)) {
      return;
    }
    setUserQueries([...userqueries, newuserquery]);
  };
  const updateQuery = async (
    queryUUID: string,
    updatedData: Partial<UserQuery>
  ) => {
    setUserQueries((currentQueries) =>
      currentQueries.map((query) =>
        query.uuid === queryUUID ? { ...query, ...updatedData } : query
      )
    );
  };

  const onReply = async (squery: string, related_userquery_uuid: string) => {
    let newuserquery = (await MarconipyApi.addQuery(squery, story.uuid, [
      related_userquery_uuid,
    ])) as any as UserQuery;
    //check if the query is already in the list
    if (userqueries.find((userquery) => userquery.uuid === newuserquery.uuid)) {
      return;
    }
    grabUserQueries();
  };

  const sourceIdsMap = useSourceMap(storyState);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      // Assuming there's a method to refresh queries that are not completed
      const refreshedQueries = await Promise.all(
        userqueries.map(async (query) => {
          if (query.status !== "completed") {
            let updatedQuery = (await MarconipyApi.getQuery(
              query.uuid
            )) as any as UserQuery;
            updateQuery(query.uuid, updatedQuery);
          }
          return query;
        })
      );

      setUserQueries(refreshedQueries);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [userqueries, story.uuid]);

  return (
    <div className="pb-8 bg-gray-50 dark:bg-black text-black dark:text-white max-w-full mx-auto rounded-lg">
      {story.top_image && (
        <div className="w-full h-72">
          <img
            src={story.top_image}
            className="w-full h-full object-cover object-center rounded-t-lg"
          />
        </div>
      )}
      <div className="p-4 flex flex-col gap-2">
        <StoryDetailTopRow
          story={storyState}
          sources={sourceIdsMap}
          debug={storyDebug}
        />
        <div
          id={`$bulletpoints_${storyState.uuid}`}
          className="flex flex-col gap-4 my-4"
        >
          <SubchapterTitle title={"In brief"} className="pr-4" />
          <BulletpointsBlock
            sources={sourceIdsMap}
            content={storyState.bulletpoints_content}
            content_json={storyState.bulletpoints_content_json}
          />
        </div>
        <div className="pb-4">
          <StorySocialBar
            story={storyState}
            showViews={false}
            onRefreshNeeded={onRefreshNeeded}
            onAuthRedirect={onAuthRedirect}
          />
        </div>
        <PublicSignupCTACard onAuthRedirect={onAuthRedirect} />
        <div
          id={`$longform_${storyState.uuid}`}
          className="flex flex-col gap-4 my-4"
        >
          <SubchapterTitle title={"In detail"} className="pr-4" />
          <LongformBlock
            sources={sourceIdsMap}
            content={storyState.longform_content}
            content_json={storyState.longform_content_json}
          />
        </div>
        <StorySocialBar
          story={storyState}
          showViews={false}
          onRefreshNeeded={onRefreshNeeded}
          onAuthRedirect={onAuthRedirect}
        />
        {/* {customizationPromptsActive && (
          <div className="flex flex-col gap-4">
            <DisclosureGroup
              open={true}
              label="Insights [BETA]"
              size="lg"
            >
              <div className="flex flex-row gap-1">
                {isLoadingCustomization ? (
                  <div className="w-full">
                    <SkeletonElement height={40} />
                  </div>
                ) : (
                  <div className="rounded-lg px-4 py-2 bg-tertiary w-full">
                    {storyState.customization &&
                    storyState.customization?.relevance > 0 ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: TextFrontend.stripMarkdownLines(
                            storyState.customization?.content,
                            true
                          ),
                        }}
                      ></p>
                    ) : (
                      <p>No relevant insight for this story.</p>
                    )}
                  </div>
                )}
                <Tooltip content="Refresh this insight according to your latest custom instructions">
                  <Button
                    icon={<RiRefreshLine />}
                    variant="secondary"
                    onClick={() => {
                      setLoadingCustomization(true);
                      customizeStories([storyState]);
                    }}
                  />
                </Tooltip>
              </div>
            </DisclosureGroup>
          </div>
        )} */}
        <div className="flex flex-col gap-4 my-8">
          {!isLoadingUserQueries && (
            <SubchapterTitle
              title={
                userqueries.length == 0
                  ? "Ask Tailor about this story [beta]"
                  : `${userqueries.length} question${
                      userqueries.length > 1 ? "s" : ""
                    } [beta]`
              }
            />
          )}
          {isLoadingUserQueries && (
            <>
              <SubchapterTitle title="Loading questions" />
            </>
          )}
          {userqueries.map((userquery) => (
            <UserQueryQuestionAnswer
              key={userquery.uuid}
              userquery={userquery}
              onReply={onReply}
              other_userqueries={userqueries.filter(
                (uq) => uq.uuid != userquery.uuid
              )}
              renderOnlyTopLevel={true}
            />
          ))}
          <div className="max-w-48">
            <UserQueryInput onSubmit={addUserQuery} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryDetailView;
