import React from "react";
import { FeedSectionType } from "@/utils/types";
import { useEffect, useState } from "react";
import FullWidthFeedSection from "./styled/FullWidthFeedSection";

type SectionTitleProps = {
  section: FeedSectionType;
};

export const SectionTitle = React.forwardRef<HTMLDivElement, SectionTitleProps>(
  function SectionTitle({ section }: SectionTitleProps, ref) {
    const [title, setTitle] = useState<string>("");
    const [host, setHost] = useState<string>("");
    const [hostUrl, setHostUrl] = useState<string>("");
    const [date, setDate] = useState<Date>();
    const [coverImage, setCoverImage] = useState<string>("");

    useEffect(() => {
      if (section.metadata) {
        Object.keys(section.metadata).forEach((key) => {
          const value = section.metadata[key];
          if (key == "title") {
            setTitle(value);
          }
          if (key == "host_name") {
            setHost(value);
          }
          if (key == "host_profile_picture") {
            setHostUrl(value);
          }
          if (key == "published_at") {
            setDate(new Date(value));
          }
          if (key == "cover_image") {
            setCoverImage(value);
          }
        });
      }
    }, [section]);

    return (
      <FullWidthFeedSection
        ref={ref}
        key={`story-title`}
        className="base-shadow min-h-screen w-full text-white flex justify-center items-center bg-cover"
        style={{
          backgroundImage: `url(${coverImage})`,
        }}
      >
        <div
          className="flex flex-col gap-3 justify-center items-center text-center bg-black bg-opacity-40 w-full min-h-screen"
          style={{ backdropFilter: "blur(5px)" }}
        >
          <h2 className="">{date?.toDateString()}</h2>
          <h1 className="text-5xl font-agipo uppercase">{title}</h1>
          {hostUrl != "" && (
            <div className="flex flex-col justify-center items-center gap-2 mt-20">
              <div className="w-10 h-10 flex-shrink-0">
                <img
                  className="rounded-full"
                  src={hostUrl}
                  alt={`${host} profile picture`}
                />
              </div>
              <p className="text-sm">{host}</p>
            </div>
          )}
        </div>
      </FullWidthFeedSection>
    );
  }
);
