import { Playable } from "@/utils/types";
import React from "react";

interface AudioPlayerContextProps<T extends Playable> {
  isPlaying: boolean;
  playlist: T[];
  currentPlayable: T | null;
  currentPlayableIndex: number;
  currentPosition: number;
  currentPositionAdjusted: number;
  currentPlayableChapterIndex: number;
  duration: number | null;
  playAudio: () => void;
  pauseAudio: () => void;
  skipForward: () => void;
  skipBack: () => void;
  addToStartOfPlaylist: (item: T) => void;
  addToEndOfPlaylist: (item: T) => void;
  updatePlaylist: (items: T[]) => void;
  skipToPosition: (position: number) => void;
  skipToChapter: (
    index: number,
    item?: T,
    callback?: (index: number) => void
  ) => void;
  skipToPlayable: (
    uuid: string,
    item?: T,
    callback?: (uuid: string) => void
  ) => void;
  cleanQueue: () => void;
}

export const AudioPlayerContext = React.createContext<
  AudioPlayerContextProps<Playable>
>({
  isPlaying: false,
  playlist: [],
  currentPlayable: null,
  currentPlayableIndex: 0,
  currentPosition: 0,
  currentPositionAdjusted: 0,
  currentPlayableChapterIndex: 0,
  duration: null,
  playAudio: () => {},
  pauseAudio: () => {},
  skipForward: () => {},
  skipBack: () => {},
  addToStartOfPlaylist: () => {},
  addToEndOfPlaylist: () => {},
  updatePlaylist: () => {},
  skipToPosition: () => {},
  skipToChapter: () => {},
  skipToPlayable: () => {},
  cleanQueue: () => {},
});

export const useAudioPlayerContext = () => {
  const context = React.useContext(AudioPlayerContext);
  if (!context) {
    throw new Error(
      "useAudioPlayerContext must be used within a AudioPlayerContextProvider"
    );
  }
  return context;
};
