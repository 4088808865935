import NavigationBar from "@/components/NavigationBar";
import { Outlet } from "react-router-dom";

export default function RootStatic() {
  return (
    <>
      {/* <div className=""> */}
      <NavigationBar />
      <div className="relative font-sans tracking-wide text-black dark:text-white bg-tertiary dark:bg-black-deep min-h-screen z-10">
        <div className="static grid grid-cols-12">
          <Outlet />
        </div>
        {/* <SVGBackground /> */}
      </div>
      {/* </div> */}
    </>
  );
}
