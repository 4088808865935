import { useState, useRef } from "react";
import Modal from "./styled/Modal";
import classnames from "classnames";
import Label from "./styled/Label";
import Button from "./styled/Button";
import MarconipyApi from "@/utils/marconipyApi";
import { usePostHog } from "posthog-js/react";
import Textarea from "./styled/Textarea";

type ReportModalProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  story_uuid: string;
};

export const ReportModal = ({
  isOpen,
  setOpen,
  story_uuid,
}: ReportModalProps) => {
  const categoryRef = useRef<HTMLSelectElement>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [comment, setComment] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [errors, setErrors] = useState<string | null>(null);
  const posthog = usePostHog();

  const sendReport = async () => {
    setIsLoading(true);
    if (!selectedCategory) {
      setIsLoading(false);
      setErrors("Please select a reason");
      return;
    }
    try {
      await MarconipyApi.createReport(
        story_uuid,
        selectedCategory,
        comment,
        {}
      );
      posthog?.capture("report sent", {
        category: selectedCategory,
        comment: comment,
        story_uuid: story_uuid,
      });
      setIsLoading(false);
      setIsCompleted(true);
    } catch (e: any) {
      setIsLoading(false);
      setErrors(e.message || "An error occurred");
    }
  };

  const handleCategoryChange = () => {
    setSelectedCategory(categoryRef.current?.value || null);
  };

  return (
    <>
      <Modal open={isOpen} onClose={() => setOpen(false)}>
        <Modal.Title>Report story</Modal.Title>
        <Modal.MainContainer>
          <Modal.Body>
            {errors && (
              <div className="grid grid-cols-12 gap-4 mb-2">
                <div className="col-span-12">
                  <p className="text-red-500">{errors}</p>
                </div>
              </div>
            )}
            {!isCompleted && (
              <>
                <Label>Reason</Label>
                <div className="grid grid-cols-12 gap-4 mb-6">
                  <div className="col-span-12">
                    <select
                      aria-label="PM"
                      ref={categoryRef}
                      onChange={handleCategoryChange}
                      className={classnames(
                        "rounded-lg bg-tertiary p-2 outline-none lg dark:bg-black-light dark:text-white w-full"
                      )}
                    >
                      <option key={0} label="Select a reason"></option>
                      <option
                        key={1}
                        label="Biased content (e.g. politically biased)"
                        value={"biased-content"}
                      ></option>
                      <option
                        key={2}
                        label="Non-factual news (e.g. fake, misinformation..)"
                        value={"fake-content"}
                      ></option>
                      <option
                        key={3}
                        label="Gambling content"
                        value={"gambling"}
                      ></option>
                      <option
                        key={4}
                        label="Promotional content"
                        value={"promotional"}
                      ></option>
                      <option
                        key={5}
                        label="Irrelevant"
                        value={"irrelevant"}
                      ></option>
                      <option
                        key={6}
                        label="Violence"
                        value={"violence"}
                      ></option>
                      <option key={7} label="Other" value={"other"}></option>
                    </select>
                  </div>
                </div>
                <Label>Tell us more (optional)</Label>
                <div className="grid grid-cols-12 gap-4 mb-6">
                  <div className="col-span-12">
                    <Textarea
                      value={comment}
                      placeholder="Give us more detail"
                      onValueChange={(value) => setComment(value)}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-4 justify-center">
                  <Button
                    className="col-span-6"
                    variant="primary"
                    onClick={sendReport}
                    disabled={isLoading || !selectedCategory}
                  >
                    Send Report
                  </Button>
                </div>
              </>
            )}
            {isCompleted && (
              <>
                <div className="grid grid-cols-12 gap-4 mb-2">
                  <div className="col-span-12">
                    <h2>Thank you for your report!</h2>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-4 mb-2">
                  <div className="col-span-12">
                    <p>
                      Your report has been submitted and will be reviewed by our
                      team.
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-12 gap-4 justify-center">
                  <Button
                    className="col-span-6"
                    variant="primary"
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </Button>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal.MainContainer>
      </Modal>
    </>
  );
};
