import React, { useState, useEffect } from "react";
import { SourceSet, SourceItem } from "@/utils/types";
import MarconipyApi from "@/utils/marconipyApi";
import {
  RiSpeakerFill,
  RiNewspaperLine,
  RiYoutubeFill,
  RiTwitterFill,
  RiQuestionMark,
} from "react-icons/ri";
import { TextFrontend } from "@/utils/Text";
import Button from "@/components/styled/Button";
import Divider from "@/components/styled/Divider";

interface SourceSetInformationPartialViewProps {
  sourceset: SourceSet;
  showAddRemoveButton?: boolean;
  added?: boolean;
  functionToAddButton: (uuid: string) => void;
  functionToRemoveButton: (uuid: string) => void;
}

export const SourceSetInformationPartialView: React.FC<
  SourceSetInformationPartialViewProps
> = ({
  sourceset,
  showAddRemoveButton = false,
  added = false,
  functionToAddButton,
  functionToRemoveButton,
}) => {
  const [currentSourceSet, setCurrentSourceSet] = useState(sourceset);

  useEffect(() => {
    const retrieveInfo = async () => {
      try {
        const data = (await MarconipyApi.getSourceSet(
          currentSourceSet.uuid
        )) as any as SourceSet;
        setCurrentSourceSet(data);
      } catch (error) {
        console.error("Error getting contentsets:", error);
      }
    };
    retrieveInfo();
  }, [currentSourceSet.uuid]);

  const fromSourceSetTypeToIcon = (type: string) => {
    if (type === "text") {
      return <RiNewspaperLine />;
    } else if (type === "podcast") {
      return <RiSpeakerFill />;
    } else if (type === "youtube") {
      return <RiYoutubeFill />;
    } else if (type === "twitter") {
      return <RiTwitterFill />;
    } else {
      return <RiQuestionMark />;
    }
  };

  return (
    <div className="text-left flex flex-col gap-2 dark:text-white">
      {currentSourceSet.description !== "default" && (
        <p>{currentSourceSet.description}</p>
      )}
      <div className="flex gap-2">
        <div className="truncate">
          {fromSourceSetTypeToIcon(sourceset.type)}
        </div>
        <small>{TextFrontend.humanUrl(currentSourceSet.rss_url)}</small>
      </div>
      {showAddRemoveButton && (
        <>
          {added ? (
            <Button
              variant="primary"
              $fullwidth
              onClick={() => functionToRemoveButton(currentSourceSet.uuid)}
            >
              Remove Publication
            </Button>
          ) : (
            <Button
              variant="primary"
              $fullwidth
              onClick={() => functionToAddButton(currentSourceSet.uuid)}
            >
              Add Publication
            </Button>
          )}
        </>
      )}
      <Divider />
      <div>
        <p className="font-semibold font-sans">Recently published</p>
        <ul className="list-disc list-inside overflow-hidden">
          {currentSourceSet.sourceitems?.map((item: SourceItem) => (
            <small key={item.uuid} className="truncate">
              <li>{item.title}</li>
            </small>
          ))}
        </ul>
      </div>
    </div>
  );
};
