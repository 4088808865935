import { useState, useEffect, useRef, useCallback } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import SwitchToAppPanel from "@/components/SwitchToAppPanel";
import { Helmet } from "react-helmet";
import { Story } from "utils/types";
import { RiEyeFill } from "react-icons/ri";
import { usePostHog } from "posthog-js/react";
import classnames from "classnames";
import Divider from "@/components/styled/Divider";
import { useAuth } from "@/contexts/AuthenticationContext";
import { TextFrontend } from "@/utils/Text";
import { AuthModal } from "@/components/auth/AuthModal";
import { useTrackView } from "@/utils/tracking";
import { Footer } from "@/components/Footer";
import { SmallPlayer } from "@/components/AudioPlayer";
import Card from "@/components/styled/Card";
import StoryDetailView from "./StoryDetailView";
import { MetaHeadEmbed } from "@phntms/react-share";

type SharedStoryPartialViewProps = {
  story: Story;
};

export default function SharedStoryView(props: SharedStoryPartialViewProps) {
  const { isAuth } = useAuth();
  const [storyState, setStoryState] = useState<Story>(props.story);
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const posthog = usePostHog();
  const intervalRef = useRef<number | null>(null);
  const { views, trackView } = useTrackView(props.story.views_count);

  useEffect(() => {
    const trackReadTime = () => {
      posthog?.capture("read to 5 seconds public", {
        story_uuid: storyState?.uuid,
      });
    };
    // @ts-ignore

    async function handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        // The tab is active, start the interval if not already started
        if (intervalRef.current != null) {
          clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
          trackReadTime();
        }, 5000);
      } else {
        // The tab is inactive, clear the interval
        if (intervalRef.current != null) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }
    }

    // Listen for visibility change events
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Call the handler once to start the interval if the page is visible on load
    handleVisibilityChange();

    // Cleanup
    return () => {
      if (intervalRef.current != null) {
        clearInterval(intervalRef.current);
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [posthog, storyState]);

  const refreshStory = useCallback(async () => {
    if (storyState) {
      let story: Story | null = null;
      story = (await MarconipyApi.getStory(storyState.uuid)) as any as Story;
      if (story) {
        setStoryState(story);
      }
    }
  }, [storyState]);

  const jsonNewsArticleSchema = () => {
    return JSON.stringify({
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      headline: storyState.title,
      image: storyState.images,
      datePublished: storyState?.published_at
        ? storyState?.published_at.toString()
        : storyState?.first_sourceitem_published_at.toString(),
      dateModified: storyState?.published_at
        ? storyState?.published_at.toString()
        : storyState?.first_sourceitem_published_at.toString(),
      author: [
        {
          "@type": "Organization",
          name: "Tailor Media, Inc.",
          url: "https://tailor.news/",
        },
      ],
    });
  };

  useEffect(() => {
    trackView("story", props.story.uuid);
  }, [trackView, props.story.uuid]);

  if (!storyState) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{storyState.title || ""}</title>
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Tailor News RSS Feed"
          href="/rss.xml"
        />
        <meta property="og:title" content={storyState.title} />
        <meta property="og:site_name" content="Tailor" />
        <meta
          property="og:description"
          content={storyState.bulletpoints_content.substring(0, 197) + "..."}
        />
        <meta
          property="og:image"
          content={
            storyState.top_image ||
            storyState.cover_image?.res_full ||
            undefined
          }
        />
        <meta property="og:url" content={storyState.permalink} />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Tailor" />
        <meta property="article:publisher" content="Tailor" />
        <meta
          property="article:published_time"
          content={storyState?.first_sourceitem_published_at.toString()}
        ></meta>
        {storyState.audio_url && (
          <meta property="og:audio" content={storyState.audio_url} />
        )}
        {storyState.audio_url == "podcast" && (
          <meta property="og:audio:type" content="audio/mpeg" />
        )}
        {/* <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" /> */}
        <script type="application/ld+json">{jsonNewsArticleSchema()}</script>
        <meta property="og:locale" content={storyState.language || "en"} />
        <link
          rel="canonical"
          href={`https://tailor.news/story/${storyState.uuid}`}
        />
        <MetaHeadEmbed
          render={(meta: React.ReactNode) => <>{meta}</>}
          siteTitle="Tailor"
          pageTitle={storyState.title || "Tailor News - Loading"}
          titleTemplate="[pageTitle] | [siteTitle]"
          description={
            storyState.bulletpoints_content.substring(0, 197) + "..."
          }
          baseSiteUrl="https://tailor.news"
          pagePath={`story/${storyState.uuid}`}
          keywords={["Tailor", "news"]}
          imageUrl={storyState.top_image || undefined}
          imageAlt="Tailor News"
          twitter={{
            cardSize: "large",
            siteUsername: "@tailormedia_ai",
            creatorUsername: "@tailormedia_ai",
          }}
        />
      </Helmet>
      <div
        className={classnames(
          "col-span-12 grid grid-cols-12 overflow-y-scroll"
        )}
      >
        {isAuth && (
          <SwitchToAppPanel deepLink={"storyDetail?id=" + storyState.uuid} />
        )}
        <div className="absolute bottom-0 left-0 right-0 z-10 grid grid-cols-12">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-start-3 lg:col-span-8">
            <SmallPlayer
              showTitle
              className="bg-white dark:bg-black border-t border-l border-r rounded-t-lg border-gray-200 p-2"
            />
          </div>
        </div>
        <div className="pt-2 px-0 md:pt-6  md:px-2 col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 xl:col-start-4 xl:col-span-6 flex flex-col gap-4">
          {storyState.user && (
            <>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col sm:flex-row gap-4 sm:justify-between">
                  <Card className="flex gap-4">
                    <div className="w-10 h-10 flex-shrink-0">
                      <img
                        src={storyState?.user.profile_picture.res_100}
                        className="rounded-full"
                        alt={`${storyState?.user.full_name} portrait`}
                      />
                    </div>
                    <div className="flex flex-col justify-center">
                      <small>
                        Shared by
                        <span className="pl-1 text-xs font-semibold">
                          {storyState?.user.full_name}
                        </span>
                      </small>
                      <div className="flex flex-row gap-4">
                        {views > 1 && (
                          <div className="flex flex-row gap-1">
                            <RiEyeFill />
                            <small className="">
                              {TextFrontend.formatNumber(views)}
                            </small>
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </>
          )}
          <StoryDetailView
            story={storyState}
            onRefreshNeeded={refreshStory}
            onAuthRedirect={() => setAuthModalOpen(true)}
          />
        </div>
        {!isAuth && (
          <>
            <Divider className="col-span-12" />
            <Footer />
            <div className="mb-20"></div>
          </>
        )}
      </div>
      {!isAuth && (
        <AuthModal
          isOpen={isAuthModalOpen}
          setOpen={setAuthModalOpen}
          redirectToAfterLogin={window.location.pathname}
          title="Sign up to get the full story."
          subtitle={
            <>
              Tailor is a personal assistant
              <br />
              that reads every piece of news.
            </>
          }
        />
      )}
    </>
  );
}
