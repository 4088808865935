// import { useEffect } from "react";
import Button from "@/components/styled/Button";

type OnboardingWelcomeStepProps = {
  nextStep: () => void;
};

const OnboardingWelcomeStep = (props: OnboardingWelcomeStepProps) => {
  return (
    <div className="flex flex-col justify-center text-center align-left gap-4">
      <div
        className="rounded-lg w-full h-60 bg-cover bg-center bg-no-repeat mx-auto mb-4"
        style={{
          backgroundImage: "url('/calm-landscape.png')",
        }}
      ></div>
      <h1 className="text-4xl font-bold">Welcome to Tailor!</h1>
      <p className="text-lg">
        Summarize, explain
        <br />
        and fact-check anything.
      </p>
      <p className="text-lg">{`It's ChatGPT for the latest news.`}</p>
      <Button
        onClick={() => props.nextStep()}
        type="button"
        variant="primary"
        className="mt-4"
      >
        {"Let's Start!"}
      </Button>
    </div>
  );
};

export default OnboardingWelcomeStep;
