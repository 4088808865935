import { useCallback, useMemo, useReducer } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import { v4 as uuidv4 } from "uuid";

export const useTrackView = (initialViews: number) => {
  const [views, increaseView] = useReducer(
    (state: number) => state + 1,
    initialViews
  );
  const userDistinctId = useMemo(() => {
    const anonUUID = localStorage.getItem("u_uuid");
    if (anonUUID) {
      return anonUUID;
    }
    const newUuid = uuidv4();
    localStorage.setItem("u_uuid", newUuid);
    return newUuid;
  }, []);

  const trackView = useCallback(
    (type: string, uuid: string): void => {
      const trackedViews = localStorage.getItem(`${userDistinctId}-v`);
      if (trackedViews === null) {
        const newTrackedViews = {
          [uuid]: new Date().getTime(),
        };
        localStorage.setItem(
          `${userDistinctId}-v`,
          JSON.stringify(newTrackedViews)
        );
      } else {
        const parsedTrackedViews: Record<string, number> =
          JSON.parse(trackedViews);
        const fourHoursAgo = new Date(Date.now() - 14400000).getTime();
        if (parsedTrackedViews[uuid] > fourHoursAgo) {
          return;
        }
        Object.keys(parsedTrackedViews).forEach((key) => {
          if (parsedTrackedViews[key] < fourHoursAgo) {
            delete parsedTrackedViews[key];
          }
        });
        parsedTrackedViews[uuid] = new Date().getTime();
        localStorage.setItem(
          `${userDistinctId}-v`,
          JSON.stringify(parsedTrackedViews)
        );
      }
      if (type == "story") {
        MarconipyApi.trackStoryEventPublic(uuid, "view");
      }
      increaseView();
    },
    [userDistinctId]
  );

  return { views, trackView };
};
