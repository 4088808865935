import Card from "@/components/styled/Card";
import Logo from "@/components/styled/Logo";
import Switch from "@/components/styled/Switch";
import MaintenanceView from "@/screens/MaintenanceView";
import UpdateRequired from "@/screens/UpdateRequiredView";
import posthog from "posthog-js";
import { useFeatureFlagEnabled } from "posthog-js/react";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { RiCloseLine } from "react-icons/ri";
import { useAuth } from "./AuthenticationContext";

export enum FeatureFlags {
  DEBUG_MODE = "debug-mode",
  FEED_DEBUG_MODE = "feed-debug-mode",
  UNDER_MAINTENANCE = "under-maintenance",
  ASK_QUICK_REPLIES = "ask-quick-replies",
  CUSTOMIZATION_PROMPTS = "customization-prompts",
  SITEWIDE_MESSAGE = "sitewide-message",
}

type FeatureFlagsContextType = {
  flags: Record<string, boolean>;
  isFeatureEnabled: (flag: string) => boolean;
};

const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  flags: {},
  isFeatureEnabled: () => false,
});

export const useFeatureFlagsContext = () => {
  return useContext(FeatureFlagsContext);
};

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const { updateRequired } = useAuth();
  const [flags, setFlags] = useState<Record<string, boolean>>({});
  const [expandAdminView, setExpandAdminView] = useState<boolean>(false);
  const hasDebugMode = useFeatureFlagEnabled(FeatureFlags.DEBUG_MODE);

  useEffect(() => {
    posthog.onFeatureFlags((flags: string[]) => {
      const flagsObject = flags.reduce(
        (
          acc: Record<string, boolean>,
          flag: string
        ): Record<string, boolean> => {
          if (flag == FeatureFlags.DEBUG_MODE) {
            return acc;
          }
          acc[flag] = posthog.isFeatureEnabled(flag) ?? false;
          return acc;
        },
        Object.values(FeatureFlags).reduce(
          (acc: Record<string, boolean>, flag: string) => {
            if (flag == FeatureFlags.DEBUG_MODE) {
              return acc;
            }
            acc[flag] = false;
            return acc;
          },
          {}
        )
      );
      setFlags(flagsObject);
    });
  }, []);

  const isFeatureEnabled = useCallback(
    (flag: string): boolean => {
      return flags[flag] ?? false;
    },
    [flags]
  );

  useEffect(() => {
    if (hasDebugMode) {
      localStorage.setItem("override-feature-flags", JSON.stringify(flags));
    }
  }, [flags, hasDebugMode]);

  const isMaintenance = isFeatureEnabled(FeatureFlags.UNDER_MAINTENANCE);

  return (
    <FeatureFlagsContext.Provider value={{ flags, isFeatureEnabled }}>
      {!isMaintenance && !updateRequired && <>{children}</>}
      {!isMaintenance && updateRequired && <UpdateRequired />}
      {isMaintenance && <MaintenanceView />}
      {hasDebugMode && (
        <div className="absolute top-4 right-8 bg-white dark:bg-black rounded-lg">
          <div className="relative">
            {!expandAdminView && (
              <div className="w-10 h-10">
                <Logo
                  variant="icon"
                  className="rounded-lg"
                  onClick={() => setExpandAdminView(true)}
                />
              </div>
            )}
            {expandAdminView && (
              <Card className="p-4 pt-8 flex flex-col gap-2">
                <RiCloseLine
                  className="absolute top-2 right-2 cursor-pointer"
                  onClick={() => setExpandAdminView(false)}
                />
                {Object.keys(flags).map((flag) => (
                  <div
                    key={flag}
                    className="text-sm dark:text-white flex flex-row justify-between gap-2 items-center"
                  >
                    {flag}
                    <Switch
                      checked={flags[flag]}
                      onChange={(value) =>
                        setFlags({
                          ...flags,
                          [flag]: value,
                        })
                      }
                    />
                  </div>
                ))}
              </Card>
            )}
          </div>
        </div>
      )}
    </FeatureFlagsContext.Provider>
  );
};
