import React, { useState, ReactNode, useCallback, useEffect } from "react";
import { AudioPlayerContext } from "@/contexts/AudioPlayerContext";
import AudioPlayer from "react-h5-audio-player";
import { usePostHog } from "posthog-js/react";
import { Story, Playable, PlayableChapter } from "@/utils/types";
import H5AudioPlayer from "react-h5-audio-player";
import { useAuth } from "@/contexts/AuthenticationContext";
import { useLocation } from "react-router";
import MarconipyApi from "@/utils/marconipyApi";

interface AudioPlayerManagerProps {
  children: ReactNode;
}

export const storyToPlayable = (story: Story): Playable => {
  return {
    uuid: story.uuid,
    title: story.title,
    audioUrl: story.audio_url ?? "",
    duration: story.duration,
    coverImage: story.top_image ?? story.cover_image?.res_full ?? "",
    publishedAt: story.first_sourceitem_published_at,
    trackingMetadata: {
      isOwner: story.is_owner,
    },
  };
};

export const AudioPlayerManager: React.FC<AudioPlayerManagerProps> = ({
  children,
}) => {
  const { isAuth } = useAuth();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPlayable, setCurrentPlayable] = useState<Playable | null>(null);
  const [currentPosition, setCurrentPosition] = useState(0);
  const posthog = usePostHog();
  const [playlist, setPlaylist] = useState<Playable[]>([]);
  const playerRef = React.useRef<H5AudioPlayer | null>(null);
  const [chapterIndex, setChapterIndex] = useState<number>(0);
  const location = useLocation();
  const [secondsListenedTo, setSecondsListenedTo] = useState<number>(0);

  const currentPlayableIndex = currentPlayable
    ? playlist.findIndex((p) => p.uuid === currentPlayable.uuid)
    : -1;

  const playAudio = useCallback(() => {
    setIsPlaying(true);
    if (playerRef.current && playerRef.current.audio.current) {
      playerRef.current.audio.current.play();
    }
  }, []);
  const pauseAudio = useCallback(() => {
    setIsPlaying(false);
    if (playerRef.current && playerRef.current.audio.current) {
      playerRef.current.audio.current.pause();
    }
  }, []);

  const addToStartOfPlaylist = useCallback(
    (playable: Playable, play: boolean = true) => {
      const playAudio = (newplaylist: Playable[]) => {
        if (newplaylist.length > 0 && currentPlayable != newplaylist[0]) {
          setCurrentPlayable(newplaylist[0]);
        }
      };
      const newPlaylist = [playable, ...playlist];
      setPlaylist(newPlaylist);
      if (play) {
        playAudio(newPlaylist);
      }
    },
    [currentPlayable, playlist]
  );

  const addToEndOfPlaylist = useCallback(
    (playable: Playable) => {
      const newPlaylist = [...playlist, playable];
      setPlaylist(newPlaylist);
    },
    [playlist]
  );

  const updatePlaylist = useCallback(
    (playableList: Playable[]) => {
      const newPlayables = playableList.filter(
        (playable) => !playlist.find((p) => p.uuid === playable.uuid)
      );
      if (newPlayables.length === 0) {
        return;
      }
      setPlaylist(
        [...playlist, ...newPlayables].sort((a, b) => {
          if (a.publishedAt && b.publishedAt) {
            return (
              new Date(b.publishedAt).getTime() -
              new Date(a.publishedAt).getTime()
            );
          }
          return 0;
        })
      );
      if (playlist.length == 0) {
        setCurrentPlayable(playableList[0]);
      }
    },
    [playlist]
  );

  const skipToPosition = useCallback((position: number) => {
    if (playerRef.current && playerRef.current.audio.current) {
      setCurrentPosition(position);
      playerRef.current.audio.current.currentTime = position;
    }
  }, []);

  const trackPlay = () => {
    posthog?.capture("play", {
      trigger: "playerLarge-button",
      uuid: currentPlayable?.uuid,
      ...currentPlayable?.trackingMetadata,
    });
    setIsPlaying(true);
  };
  const trackPause = () => {
    posthog?.capture("pause", {
      trigger: "playerLarge-button",
      uuid: currentPlayable?.uuid,
      ...currentPlayable?.trackingMetadata,
    });
    setIsPlaying(false);
  };
  const trackEnded = () => {
    posthog?.capture("listened to full audio", {
      trigger: "playerLarge-button",
      uuid: currentPlayable?.uuid,
      ...currentPlayable?.trackingMetadata,
    });
    if (currentPlayable) {
      // find index of playlist item with uuid == currentPlayable.uuid
      if (
        currentPlayableIndex === -1 ||
        currentPlayableIndex === playlist.length - 1
      ) {
        setCurrentPosition(currentPlayable.duration);
        return;
      }
      setCurrentPlayable(playlist[currentPlayableIndex + 1]);
      setCurrentPosition(0);
      playAudio();
    }
  };

  const handleListenUpdate = (event: any) => {
    const { currentTime } = event.target;
    if (currentPlayable && isPlaying) {
      let updatedSeconds = secondsListenedTo + 1;
      if (updatedSeconds == 5) {
        posthog?.capture("listened to 5 seconds", {
          uuid: currentPlayable?.uuid,
          ...currentPlayable?.trackingMetadata,
        });
        if (isAuth) {
          MarconipyApi.updateStoryMeta(currentPlayable.uuid, currentTime);
        }
        updatedSeconds = 0;
      }
      setSecondsListenedTo(updatedSeconds);
      setCurrentPosition(currentTime);
    }
  };

  const skipForward = useCallback(() => {
    let playerPosition = 0;
    if (!currentPlayable) {
      return;
    }
    if (currentPlayable.chapters && currentPlayable.chapters.length > 0) {
      let chapters: PlayableChapter[] = currentPlayable.chapters;
      let chapterDurations: number[] = [];
      for (let chapter of chapters) {
        let chapterLength: number =
          (chapter.audioEndTimeMS - chapter.audioStartTimeMS) / 1000;
        chapterDurations.push(chapterLength);
      }
      if (playerRef.current && playerRef.current.audio.current) {
        playerPosition = nextChapterStart(
          playerRef.current.audio.current.currentTime,
          chapterDurations
        );
      }
    } else {
      if (currentPlayableIndex === -1) {
        return;
      }
      if (currentPlayableIndex === playlist.length - 1) {
        return;
      }
      setCurrentPlayable(playlist[currentPlayableIndex + 1]);
    }
    if (playerRef.current && playerRef.current.audio.current) {
      setCurrentPosition(playerPosition);
      playerRef.current.audio!.current.currentTime = playerPosition;
    }
  }, [currentPlayable, currentPlayableIndex, playlist]);

  const skipBack = useCallback(() => {
    let playerPosition = 0;
    if (!currentPlayable) {
      return;
    }
    if (currentPlayable?.chapters && currentPlayable.chapters.length > 0) {
      let chapters: PlayableChapter[] = currentPlayable.chapters;
      let chapterDurations: number[] = [];
      for (let chapter of chapters) {
        let chapterLength: number =
          (chapter.audioStartTimeMS - chapter.audioEndTimeMS) / 1000;
        chapterDurations.push(chapterLength);
      }
      if (playerRef.current && playerRef.current.audio.current) {
        playerPosition = previousChapterStart(
          playerRef.current.audio.current.currentTime,
          chapterDurations
        );
      }
    } else {
      const currentPlayableIndex = playlist
        .map((playable) => playable.uuid)
        .indexOf(currentPlayable.uuid);
      if (currentPlayableIndex === -1) {
        return;
      }
      if (currentPlayableIndex === 0) {
        return;
      }
      setCurrentPlayable(playlist[currentPlayableIndex - 1]);
    }
    if (playerRef.current && playerRef.current.audio.current) {
      setCurrentPosition(playerPosition);
      playerRef.current.audio!.current.currentTime = playerPosition;
    }
  }, [currentPlayable, playlist]);

  const nextChapterStart = (
    currentTime: number,
    chapterDurations: number[]
  ) => {
    let chapterStart: number = 0;
    for (let duration of chapterDurations) {
      if (chapterStart + duration > currentTime) {
        // We are in the middle of the chapter or just past it
        return chapterStart + duration + 5; // Beginning of next chapter
      }
      chapterStart += duration;
    }
    return chapterStart; // If no next chapter, return 0.0
  };
  const previousChapterStart = (
    currentTime: number,
    chapterDurations: number[]
  ) => {
    let chapterStart: number = 0;
    for (let duration of chapterDurations) {
      if (chapterStart + duration + 5 > currentTime) {
        // We are in the middle of the chapter or just past it
        return chapterStart; // Beginning of next chapter
      }
      chapterStart += duration;
    }
    return chapterStart; // If no next chapter, return 0.0
  };

  const skipToPlayable = useCallback(
    (uuid: string, item?: Playable, callback?: (uuid: string) => void) => {
      let playable = playlist.find((p) => p.uuid === uuid);
      if (!playable && item) {
        playable = item;
        addToStartOfPlaylist(playable);
      }
      if (!playable) {
        return;
      }
      setCurrentPlayable(playable);
      setTimeout(() => {
        if (playable) {
          skipToPosition(0);
          playAudio();
          callback?.(uuid);
        }
      }, 50);
    },
    [addToStartOfPlaylist, playAudio, playlist, skipToPosition]
  );

  const skipToChapter = useCallback(
    async (
      index: number,
      playable?: Playable,
      callback?: (index: number) => void
    ) => {
      if (!playable && !currentPlayable) {
        return;
      }
      if (playable && (!isPlaying || currentPlayable?.uuid !== playable.uuid)) {
        addToStartOfPlaylist(playable);
      }
      let activePlayable = playable ? playable : currentPlayable!;
      if (!activePlayable.chapters) {
        return;
      }
      const chapter = activePlayable.chapters[index];
      setTimeout(() => {
        const smallDelay = index > 0 ? 1.5 : 0;
        skipToPosition(chapter.audioStartTimeMS / 1000 + smallDelay);
        callback?.(index);
      }, 50);
    },
    [currentPlayable, isPlaying, addToStartOfPlaylist, skipToPosition]
  );

  useEffect(() => {
    setTimeout(() => {});
    if (playerRef.current && playerRef.current.audio.current) {
      if (
        currentPlayable &&
        (!currentPlayable.duration || currentPlayable.duration == 0) &&
        playerRef.current &&
        playerRef.current.audio.current &&
        playerRef.current.audio.current.duration
      ) {
        const duration = playerRef.current.audio.current.duration;
        setCurrentPlayable({
          ...currentPlayable,
          duration,
        });
      }
    }
    // handle playable that for whatever reason don't have a proper duration set
    // setTimeout is used to ensure that the duration is set after the audio has loaded
  }, [currentPlayable, playerRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentPlayable && currentPlayable.chapters) {
      for (let i = 0; i < currentPlayable.chapters.length; i++) {
        const chapter = currentPlayable?.chapters[i];
        if (
          chapter.audioStartTimeMS / 1000 - 5 < currentPosition &&
          chapter.audioEndTimeMS / 1000 > currentPosition
        ) {
          setChapterIndex(i);
          break;
        }
      }
    }
  }, [currentPlayable, currentPosition]);

  useEffect(() => {
    if (currentPlayable && currentPlayable.chapters) {
      if (
        currentPosition <
        currentPlayable.chapters[0].audioStartTimeMS / 1000
      ) {
        skipToPosition(currentPlayable.chapters[0].audioStartTimeMS / 1000);
        setChapterIndex(0);
      }
    }
  }, [currentPlayable, currentPosition, skipToPosition]);

  useEffect(() => {
    if (currentPlayable && currentPlayable.chapters) {
      if (
        currentPosition >
        currentPlayable.chapters[currentPlayable.chapters.length - 1]
          .audioEndTimeMS /
          1000
      ) {
        pauseAudio();
        skipToPosition(currentPlayable.chapters[0].audioStartTimeMS);
        setChapterIndex(0);
      }
    }
  }, [currentPlayable, currentPosition, pauseAudio, skipToPosition]);

  useEffect(() => {
    // stop playing if we navigate away from the listen page
    // or we logout
    if (!isAuth) {
      setCurrentPlayable(null);
      setPlaylist([]);
      setIsPlaying(false);
      setChapterIndex(0);
      setCurrentPosition(0);
    }
  }, [isAuth]);

  useEffect(() => {
    setCurrentPlayable(null);
    setPlaylist([]);
    setIsPlaying(false);
    setChapterIndex(0);
    setCurrentPosition(0);
  }, [location.pathname]);

  let currentPositionAdjusted = currentPosition;
  if (currentPlayable && currentPlayable.chapters) {
    currentPositionAdjusted -=
      currentPlayable.chapters[0].audioStartTimeMS / 1000;
    if (currentPositionAdjusted < 0) {
      currentPositionAdjusted = 0;
    }
  }

  const cleanQueue = useCallback(() => {
    setPlaylist([]);
    setCurrentPlayable(null);
    setIsPlaying(false);
    setChapterIndex(0);
    setCurrentPosition(0);
  }, []);

  return (
    <AudioPlayerContext.Provider
      value={{
        isPlaying,
        playlist,
        currentPlayable,
        currentPlayableIndex,
        currentPosition,
        currentPositionAdjusted,
        currentPlayableChapterIndex: chapterIndex,
        duration: currentPlayable?.duration ?? null,
        playAudio,
        pauseAudio,
        skipForward,
        skipBack,
        addToStartOfPlaylist,
        addToEndOfPlaylist,
        skipToPosition,
        skipToChapter,
        skipToPlayable,
        updatePlaylist,
        cleanQueue,
      }}
    >
      {children}
      {currentPlayable != null && (
        <div className="hidden">
          <AudioPlayer
            ref={playerRef}
            src={currentPlayable.audioUrl}
            onPlay={trackPlay}
            onPause={trackPause}
            onEnded={trackEnded}
            listenInterval={1000}
            onListen={handleListenUpdate}
            onClickNext={skipForward}
            onClickPrevious={skipBack}
            showSkipControls={true}
            showJumpControls={false}
          />
        </div>
      )}
    </AudioPlayerContext.Provider>
  );
};
