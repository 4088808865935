import { useState } from "react";
import { useLoaderData, redirect } from "react-router-dom";
import MarconipyApi from "@/utils/marconipyApi";
import { NotificationSettings, User } from "utils/types";
import { RiUser3Line, RiFileCopyLine, RiRssFill } from "react-icons/ri";
import { ContentSet } from "utils/types";
import Switch from "@/components/styled/Switch";
import Label from "@/components/styled/Label";
import Input from "@/components/styled/Input";
import Divider from "@/components/styled/Divider";
import Button from "@/components/styled/Button";
import { NavigationButton } from "@/components/styled/NavigationButton";
import Card from "@/components/styled/Card";
import SubchapterTitle from "@/components/SubchapterTitle";
import { ChangeTimeOfDay } from "./Onboarding/OnboardingFrequencyStep";
import tw from "tailwind-styled-components";
import { useContentSetContext } from "@/contexts/ContentSetContext";

type userDataResponse = {
  notificationSettings?: NotificationSettings;
  contentset?: ContentSet;
  profile?: User;
};

const SettingsRow = tw.div`flex justify-between items-center h-8`;
const SettingsLabel = tw.p`font-sans text-sm`;

export default function AccountView() {
  const usr = useLoaderData() as userDataResponse;
  const { contentset } = useContentSetContext();
  const [notify, setNotify] = useState(
    usr.notificationSettings?.notify_on_new_content || false
  );
  const [notifyOnMarketing, setNotifyOnMarketing] = useState(
    usr.notificationSettings?.notify_on_marketing || false
  );
  const [emailNotification, setEmailNotification] = useState(
    usr.notificationSettings?.email_notification || false
  );
  const [iosNotification, setiOSNotification] = useState(
    usr.notificationSettings?.ios_notification || false
  );

  const handleChangeNotifyNewContent = async (value: boolean) => {
    setNotify(value);
    await MarconipyApi.changeNotificationSettings({
      notify_on_new_content: value,
    });
  };
  const handleChangeNotifyOnMarketing = async (value: boolean) => {
    setNotifyOnMarketing(value);
    await MarconipyApi.changeNotificationSettings({
      notify_on_marketing: value,
    });
  };
  const handleEmailNotification = async (value: boolean) => {
    setEmailNotification(value);
    await MarconipyApi.changeNotificationSettings({
      email_notification: value,
    });
  };
  const handleiOSNotification = async (value: boolean) => {
    setiOSNotification(value);
    await MarconipyApi.changeNotificationSettings({
      ios_notification: value,
    });
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://tailor.news/feeds/${contentset?.uuid}/digest.xml`
      );
      // props.onShare?.();
      alert("Link copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="overflow-y-auto col-span-12 grid grid-cols-12 px-4 gap-4">
      <div className="col-span-12 sm:col-span-12 md:col-start-3 md:col-span-8 lg:col-start-4 lg:col-span-6 mb-80 mt-4">
        <Card className="flex flex-col gap-4">
          <h1>Settings</h1>
          <SubchapterTitle title="Login details" />
          <div className="flex flex-col gap-2">
            <div>
              <Label>Email address</Label>
              <Input
                readOnly
                value={usr.profile?.email ?? ""}
                type="Email"
                placeholder="name@example.com"
              />
            </div>
            <div>
              <Label>Name</Label>
              <Input
                readOnly
                value={usr.profile?.first_name ?? ""}
                type="text"
                placeholder="First name"
              />
            </div>
          </div>
          <Divider />
          <SubchapterTitle title="Notifications" />
          <div className="flex flex-col gap-4">
            <SettingsRow>
              <SettingsLabel>Send me email notifications</SettingsLabel>
              <Switch
                checked={emailNotification}
                onChange={handleEmailNotification}
              />
            </SettingsRow>
            <SettingsRow>
              <SettingsLabel>Send me app notifications on iOS</SettingsLabel>
              <Switch
                checked={iosNotification}
                onChange={handleiOSNotification}
              />
            </SettingsRow>
            <SettingsRow>
              <SettingsLabel>Notify me daily on new stories</SettingsLabel>
              <Switch
                checked={notify}
                onChange={handleChangeNotifyNewContent}
              />
            </SettingsRow>
            {notify && (
              <SettingsRow>
                <SettingsLabel>Notify me at</SettingsLabel>
                <ChangeTimeOfDay variant="sm" />
              </SettingsRow>
            )}
            <SettingsRow>
              <SettingsLabel>
                {`Keep me subscribed to the Tailor's newsletter`}
              </SettingsLabel>
              <Switch
                checked={notifyOnMarketing}
                onChange={handleChangeNotifyOnMarketing}
              />
            </SettingsRow>
            <Divider />
          </div>
          <SubchapterTitle title="Advanced" />
          <div className="flex flex-col gap-4">
            <SettingsRow>
              <SettingsLabel>
                <div className="flex">
                  <RiRssFill className="mr-2" />
                  RSS feed of your daily digest
                </div>
              </SettingsLabel>
              {/* limit width of input */}
              <div className="flex">
                <Button
                  className="p-0 mr-2"
                  variant="plain"
                  onClick={handleCopy}
                  size="sm"
                >
                  <RiFileCopyLine className={"lg"} />
                </Button>
                <Input
                  readOnly={true}
                  value={`https://tailor.news/feeds/${contentset?.uuid}/digest.xml`}
                />
              </div>
            </SettingsRow>
            <Divider />
          </div>
          <div className="flex flex-col gap-2">
            <NavigationButton
              to={`/logout`}
              variant="secondary"
              className="rounded-lg"
              icon={<RiUser3Line />}
            >
              Logout
            </NavigationButton>
          </div>
        </Card>
      </div>
    </div>
  );
}

export async function loader({}: { params: any }) {
  try {
    await MarconipyApi.loadToken();
    let notificationSettings = await MarconipyApi.getNotificationSettings();
    let profile = await MarconipyApi.getUser();
    return {
      notificationSettings: notificationSettings,
      profile: profile,
    };
  } catch (e: any) {
    console.log(e);
    return redirect("/login");
  }
}
