import React, { useEffect } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import {
  redirect,
  useLoaderData,
  Await,
  defer,
  useAsyncError,
} from "react-router-dom";
import { Story } from "@/utils/types";
import SharedStoryView from "./partials/SharedStoryView";
import { Loading } from "@/components/Loading";
import { FeedPartialView } from "./partials/FeedPartialView";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import * as Sentry from "@sentry/react";
import Card from "@/components/styled/Card";
import Button from "@/components/styled/Button";

type userDataResponse = {
  story: Story;
};

const LoadedStoryView = ({ story }: { story: Story }) => {
  const { contentset } = useContentSetContext();
  const isAuth = MarconipyApi.isAuth();

  if (isAuth && contentset) {
    return (
      <FeedPartialView
        feed={{
          sections: [
            {
              uuid: story.uuid,
              type: "detail",
              story,
              metadata: {},
            },
          ],
          pagination_timestamp: null,
          status: "published",
          category: null,
          categories: null,
        }}
        canRequestNewContent={false}
        canLoadNewContent={true}
        canShowGeneratingView={false}
        advancedFeedOptions={false}
        disableScroll={false}
      />
    );
  } else {
    return <SharedStoryView story={story} />;
  }
};

export default function StoryView() {
  const data = useLoaderData() as userDataResponse;

  return (
    <>
      <Helmet>
        <title>Your news, reimagined - Tailor - Loading</title>
      </Helmet>
      <React.Suspense fallback={<Loading />}>
        <Await resolve={data.story} errorElement={<StoryViewError />}>
          {(loadedStory) => <LoadedStoryView story={loadedStory} />}
        </Await>
      </React.Suspense>
    </>
  );
}

function StoryViewError() {
  const error = useAsyncError() as any;
  useEffect(() => {
    Sentry.captureException(error);
    console.error(error);
  }, [error]);
  return (
    <div className="pt-6 px-4 col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 xl:col-start-4 xl:col-span-6 flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row gap-4 sm:justify-between">
          <Card className="flex gap-4 flex-col">
            <h1>{`${
              error.response && error.response.status == 404
                ? "Ouch! We could not find the story you are looking for"
                : "Woops, there was an error"
            }`}</h1>
            <p>This should not happen. Anyways.</p>
            {error && error.response && error.response.status == 404 && (
              <p>
                You can try{" "}
                <a className="underline hover:no-underline" href="/ask">
                  to Ask Tailor about the news you are looking for.
                </a>
              </p>
            )}
            <Button
              variant="primary"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Go back to Home
            </Button>
          </Card>
        </div>
      </div>
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  try {
    if (params.storyId) {
      if (!MarconipyApi.isAuth()) {
        let story = MarconipyApi.getStory(
          params.storyId
        ) as Promise<any> as Promise<Story>;
        return defer({ story });
      } else {
        let story = MarconipyApi.getStory(
          params.storyId
        ) as Promise<any> as Promise<Story>;
        return defer({ story });
      }
    } else {
      return redirect("/");
    }
  } catch (e: any) {
    if (e.json && e.json.detail && e.json.detail.includes("Banned")) {
      return redirect("/banned");
    } else if (
      e["json"] &&
      e["json"]["detail"] &&
      e["json"]["detail"] == "waitlisted"
    ) {
      console.log("Waitlist!");
      let story = await MarconipyApi.getStory(params.storyId);
      return { story };
    } else {
      return redirect("/", 302);
    }
  }
}
