import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import { redirect, useNavigate } from "react-router-dom";
import Container from "@/components/styled/Container";
import PasswordForgottenForm from "@/components/auth/PasswordForgottenForm";
import Card from "@/components/styled/Card";

export default function PasswordForgotten() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Password Forgotten - Tailor</title>
        <meta
          name="description"
          content="Forgot the password of your Tailor account? Reset it here!"
        />
        <meta property="og:title" content="Login - Tailor" />
        <meta
          property="og:description"
          content="Forgot the password of your Tailor account? Reset it here!"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
      </Helmet>
      <div className="col-span-12 grid grid-cols-12">
        <Container>
          <Card className="flex flex-col gap-2">
            <h2>{"Let's change your password"}</h2>
            <PasswordForgottenForm onLoginRedirect={() => navigate("/login")} />
          </Card>
        </Container>
      </div>
    </>
  );
}

export async function loader({}: { params: any }) {
  try {
    //check if the user is already logged in
    const loggedIn = MarconipyApi.isAuth();
    if (loggedIn) {
      console.log("User is already logged in");
      return redirect("/");
    } else {
      return false;
    }
  } catch (e) {
    throw e;
  }
}
