import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./styled/Button";

const SwitchToAppPanel = ({}: { deepLink?: string }) => {
  const [open, setOpen] = useState(false);
  const [canDisplay, setCanDisplay] = useState(false);

  useEffect(() => {
    // @ts-ignore
    setOpen(/iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  }, []);

  useEffect(() => {
    const lastDisplayed = localStorage.getItem("tailor-app-panel");
    if (lastDisplayed) {
      const lastDisplayedDate = new Date(lastDisplayed);
      // if lastDisplayedDate is more than 30 day ago, display again
      const now = new Date();
      const diff = now.getTime() - lastDisplayedDate.getTime();
      const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      if (diffDays > 30) {
        setCanDisplay(true);
      }
    } else {
      setCanDisplay(true);
      localStorage.setItem("tailor-app-panel", new Date().toISOString());
    }
  }, []);

  const close = () => {
    localStorage.setItem("tailor-app-panel", new Date().toISOString());
    setOpen(false);
  };

  if (!canDisplay) {
    return null;
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Dialog.Overlay
          className="fixed inset-0 bg-black opacity-30"
          onClick={close}
        />
        <Dialog.Panel>
          <div className="absolute inset-x-0 bottom-0 bg-white dark:bg-black text-black dark:text-white p-4 z-20 border flex flex-col gap-4">
            <div>
              <h2>Tailor is better on the app!</h2>
              <p>
                Listen to your personalized podcast on the go, ask questions and
                more!
              </p>
            </div>
            <div className="flex gap-2">
              {/* <Button variant="primary" href={"tailormediatailorapp://" + deepLink}> */}
              <Button
                variant="primary"
                href="https://testflight.apple.com/join/jlQPI7I8"
              >
                Switch to the app
              </Button>
              <Button variant="secondary" onClick={close}>
                Not now
              </Button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </Transition.Root>
  );
};

export default SwitchToAppPanel;
