import { Fragment, useEffect, useState } from "react";
import { SourceItem, SourceItemStoryMeta } from "utils/types";
import {
  RiArrowDownSLine,
  RiArrowRightSLine,
  RiCheckFill,
  RiCloseFill,
  RiExternalLinkLine,
} from "react-icons/ri";
import SubchapterTitle from "@/components/SubchapterTitle";
import Card from "@/components/styled/Card";
import Divider from "@/components/styled/Divider";
import MarconipyApi from "@/utils/marconipyApi";
import SourceSetImage from "@/components/SourceSetImage";
import SourceItemScoreCard from "@/components/SourceItemScoreCard";
import classNames from "classnames";
import SourceItemRowItem from "@/components/SourceItemRowItem";
import Button from "@/components/styled/Button";
import { Transition } from "@headlessui/react";

type SourceItemAnalysisDetailViewProps = {
  sourceitemstorymeta: SourceItemStoryMeta;
};

const SourceItemAnalysisDetailView = ({
  sourceitemstorymeta,
}: SourceItemAnalysisDetailViewProps) => {
  const [sourceitemstorymetaState, setSourceitemstorymetaState] =
    useState<SourceItemStoryMeta>(sourceitemstorymeta);

  useEffect(() => {
    // Define an async function inside useEffect
    const fetchSourceItemStoryMeta = async () => {
      try {
        const completeSourceitemstorymeta =
          (await MarconipyApi.getSourceItemStoryMeta(sourceitemstorymeta.uuid, [
            "sources",
          ])) as any as SourceItemStoryMeta;
        setSourceitemstorymetaState(completeSourceitemstorymeta);
      } catch (error) {
        console.error("Error fetching sourceitemstorymeta:", error);
        // handle error appropriately
      }
    };

    // Call the async function
    fetchSourceItemStoryMeta();
  }, [sourceitemstorymeta.uuid]);

  return (
    <Card className="p-0 pb-8 text-black dark:text-white">
      <div className="p-4 flex flex-col gap-4">
        <h1 className="text-left my-4">
          {sourceitemstorymetaState.sourceitem?.title}
        </h1>
        <div className="flex flex-row gap-2 items-left">
          <a
            className="min-w-48 flex flex-col gap-4"
            href={sourceitemstorymetaState.sourceitem?.link}
            target="_blank"
            rel="noreferrer"
          >
            <div className="flex gap-2 items-center">
              {sourceitemstorymetaState.sourceitem &&
                sourceitemstorymetaState.sourceitem.sourceset &&
                sourceitemstorymetaState.sourceitem.sourceset.image && (
                  <div className="flex flex-row gap-1">
                    <SourceSetImage
                      key={sourceitemstorymetaState.uuid}
                      image={
                        sourceitemstorymetaState.sourceitem.sourceset.image
                      }
                      alt={sourceitemstorymetaState.sourceitem.sourceset.name}
                      size="sm"
                    />
                  </div>
                )}
              <div className="flex flex-col justify-center">
                {sourceitemstorymetaState.sourceitem?.sourceset?.name}
              </div>
              <RiExternalLinkLine />
            </div>
          </a>
        </div>
        {sourceitemstorymetaState.sourceitem &&
          sourceitemstorymetaState.sourceitem.image_url && (
            <div className="w-full h-48">
              <img
                src={sourceitemstorymetaState.sourceitem.image_url}
                className="w-full h-full object-cover object-center rounded-lg"
              />
            </div>
          )}
        <Divider />
        <div className="flex flex-col gap-4">
          <SourceItemScoreCard sourceitemstorymeta={sourceitemstorymetaState} />
          <Divider />
          <SubchapterTitle title="Content analysis" />
          <div className="flex flex-col text-sm text-gray-800 dark:text-white bg-tertiary dark:bg-black-light border dark:border-0 rounded-lg p-2 gap-2">
            <p className="uppercase text-xs">Legend</p>
            <div className="flex items-center gap-1">
              <RiCheckFill className="text-green-500" />
              <p className="text-sm">{`Confirmed by additional sources.`}</p>
            </div>
            <div className="flex items-center gap-1">
              <RiCloseFill className="text-red-500" />
              <p className="text-sm">{`Unsupported by the available sources.`}</p>
            </div>
            <div className="flex items-center gap-1">
              <RiCloseFill className="text-gray-500 dark:text-gray-900" />
              <p className="text-sm">{`Not enough sources to verify the statement.`}</p>
            </div>
          </div>
          <ul
            role="list"
            className="divide-y divide-gray-100 dark:divide-gray-900"
          >
            {sourceitemstorymetaState.metadata &&
              sourceitemstorymetaState.metadata["tagged_content"] &&
              sourceitemstorymetaState.metadata["tagged_content"].map(
                (claim: any, index: number) => (
                  <ClaimView key={index} claim={claim} />
                )
              )}
          </ul>
        </div>
      </div>
    </Card>
  );
};

const ClaimView = ({ claim }: { claim: any }) => {
  const [isOpen, setIsOpen] = useState(false);

  // claim = {
  //   ...claim,
  //   fact_checked: true,
  // };

  const toggleOpen = () => setIsOpen(!isOpen);
  return (
    <li className="flex flex-col justify-between gap-x-6 py-4">
      <div className="sm:flex sm:flex-col md:grid md:grid-cols-12">
        <div className="flex min-w-0 col-span-8">
          <div className="flex text-justify items-center">
            <div className="flex-shrink-0 w-8">
              {claim["fact_checked"] == true && (
                <RiCheckFill className="text-green-500" />
              )}
              {claim["fact_checked"] != true &&
                claim["sources"] &&
                claim["sources"].length > 0 && (
                  <RiCloseFill className="text-red-500" />
                )}
              {claim["fact_checked"] != true &&
                claim["sources"] &&
                claim["sources"].length == 0 && (
                  <RiCloseFill className="text-gray-500 dark:text-gray-900" />
                )}
            </div>
            <p className="leading-6 text-black dark:text-white">
              {claim["content"].replace("- ", "")}
            </p>
          </div>
        </div>
        <div
          className={classNames(
            "flex flex-col items-end col-span-4 py-2 md:py-0 justify-center flex-grow-0"
          )}
        >
          <Button
            className="flex items-center"
            variant={claim["sources"].length > 0 ? "secondary" : "plain"}
            size="sm"
            onClick={toggleOpen}
            disabled={claim["sources"].length == 0}
            icon={
              claim["sources"].length > 0 ? (
                isOpen ? (
                  <RiArrowDownSLine />
                ) : (
                  <RiArrowRightSLine />
                )
              ) : null
            }
          >
            {claim["sources"].length > 0
              ? `Compared with ${claim["sources"].length} source${
                  claim["sources"].length > 1 ? "s" : ""
                }`
              : "No sources available"}
          </Button>
        </div>
      </div>
      {claim["sources"] && claim["sources"].length > 0 && (
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-300"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition ease-in duration-200"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div
            className="mt-4 flex gap-2 transition-all snap-x pb-4 snap-mandatory scroll-smooth overflow-x-scroll dark:[color-scheme:dark]"
            style={{
              direction: "rtl",
            }}
          >
            {claim["sources"].map((sourceitem: SourceItem) => (
              <div className="snap-start" key={sourceitem.uuid}>
                <SourceItemRowItem sourceitem={sourceitem} />
              </div>
            ))}
          </div>
        </Transition>
      )}
    </li>
  );
};

export default SourceItemAnalysisDetailView;
