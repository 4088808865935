import Button from "@/components/styled/Button";

function UpdateRequired() {
  // Function to refresh the page
  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <div className="grid grid-cols-12 font-sans tracking-wide text-black dark:bg-black dark:text-white pt-16 overflow-hidden h-screen w-screen items-center">
      <div className="flex flex-col gap-4 p-2 col-span-12 md:col-span-6 md:col-start-4 lg:col-span-4 lg:col-start-5 items-center text-center">
        <h1>Update Required</h1>
        <p>
          A new version of the app is available. Please refresh this page to
          continue using the app.
        </p>
        <Button onClick={handleRefreshClick} variant="primary">
          Refresh Page
        </Button>
      </div>
    </div>
  );
}

export default UpdateRequired;
