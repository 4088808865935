import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import { DebounceInput } from "react-debounce-input";

const InputWithButton = ({
  onSubmit,
  onCancel,
  onChange,
  placeholder,
  buttonText,
  autofocus,
  startingValue,
  variant,
}: {
  onSubmit: (value: string) => void;
  onCancel?: () => void;
  onChange?: (value: string) => void;
  placeholder: string;
  buttonText: string;
  autofocus?: boolean;
  startingValue?: string;
  variant?: "primary" | "secondary";
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(startingValue ?? "");
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  useEffect(() => {
    if (autofocus) {
      ref.current?.focus();
    }
    setValue(startingValue ?? "");
  }, [autofocus, startingValue]);

  return (
    <form
      className="w-full relative"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(value);
      }}
    >
      <div className="flex flex-row rounded-lg shadow divide-x divide-gray-100 w-full">
        <div className="bg-white dark:bg-black rounded-l-lg flex flex-row flex-grow">
          <DebounceInput
            inputRef={ref}
            autoFocus={autofocus ?? false}
            value={value}
            placeholder={placeholder}
            onChange={handleInputChange}
            className="rounded-l-lg w-full flex-grow-1 border-0 ring-0 outline-0 pl-2 dark:bg-black"
          />
          <button
            className={classNames(
              "w-10 h-10 flex items-center justify-center",
              {
                "hidden pointer-events-none": value == "",
                "cursor-pointer hover:bg-primary-light dark:hover:text-primary":
                  value != "",
              }
            )}
            onClick={() => {
              setValue("");
              onCancel && onCancel();
              ref.current?.focus();
            }}
            type="button"
          >
            <RiCloseLine />
          </button>
        </div>
        <button
          type="submit"
          className={classNames(
            "dark:bg-black rounded-r-lg py-2 hover:bg-primary-light hover:text-primary px-8",
            variant === "primary" ? "bg-primary text-white" : "bg-white"
          )}
        >
          {buttonText}
        </button>
      </div>
    </form>
  );
};

export default InputWithButton;
