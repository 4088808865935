import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import {
  RiFeedbackLine,
  RiSmartphoneLine,
  RiCloseLine,
  RiMenuLine,
  RiSearch2Line,
  RiAppleFill,
  RiGlobeLine,
  RiPlayFill,
  RiProfileLine,
  RiSeedlingLine,
  RiHistoryFill,
} from "react-icons/ri";
import { useAuth } from "@/contexts/AuthenticationContext";
import { usePostHog } from "posthog-js/react";
import Logo from "@/components/styled/Logo";
import Button from "@/components/styled/Button";
import {
  NavigationButton,
  MobileNavigationButton,
} from "@/components/styled/NavigationButton";
import Modal from "@/components/styled/Modal";
import Label from "@/components/styled/Label";
import Textarea from "@/components/styled/Textarea";
import Toast from "@/components/styled/Toast";
import { NavLink } from "react-router-dom";
import { NavigationTabs } from "./styled/Tabs";
import classNames from "classnames";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import MarconipyApi from "@/utils/marconipyApi";

const NavigationBar = () => {
  const { isAuth } = useAuth();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [feedback, setFeedback] = useState("");
  const { contentset } = useContentSetContext();
  const posthog = usePostHog();

  const handleSubmitFeedback = () => {
    setShowFeedbackModal(false);
    setShowSuccessToast(true);
    MarconipyApi.createFeedback(feedback);
    posthog?.capture("feedback", {
      trigger: "FeedbackButtonWeb",
      text: feedback,
    });
    setFeedback("");
    setTimeout(() => {
      setShowSuccessToast(false);
    }, 2000);
  };
  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false);
  };
  const handleShowFeedbackModal = () => setShowFeedbackModal(true);
  const toggleShowSuccessToast = () => setShowSuccessToast(!showSuccessToast);

  const isOnboarding =
    !contentset ||
    (contentset && contentset.draft) ||
    window.location.pathname.includes("/new");

  return (
    <>
      <div
        className={classNames("fixed h-16 w-screen z-10", {
          "md:h-screen md:w-40 max-w-40": isAuth,
        })}
      >
        <Disclosure
          as="nav"
          className="bg-white h-full border-r border-gray shadow dark:bg-black dark:border-black-deep"
        >
          {({ open }) => (
            <>
              <div className="flex flex-col h-full">
                <div className="relative flex flex-col justify-between h-full">
                  {/* Mobile menu button */}
                  <div className="absolute inset-y-0 right-0 flex items-center md:hidden mr-4">
                    <Disclosure.Button
                      className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                      aria-label="Open main menu"
                    >
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <RiCloseLine
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <RiMenuLine
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div
                    className={classNames("flex flex-1 gap-8 h-full", {
                      "flex-row": !isAuth,
                      "flex-col": isAuth,
                    })}
                  >
                    <div className="flex flex-shrink-0 items-left p-4">
                      {isAuth && (
                        <NavLink to="/discover">
                          <Logo className="w-32" />
                        </NavLink>
                      )}
                      {!isAuth && (
                        <a href="/">
                          <Logo className="w-32" />
                        </a>
                      )}
                    </div>
                    <div
                      className={classNames("hidden md:flex", {
                        "flex-row w-full justify-end": !isAuth,
                        "flex-col pb-4 h-full": isAuth,
                        "justify-end": isAuth && isOnboarding,
                        "justify-between": isAuth && !isOnboarding,
                      })}
                    >
                      {isAuth && !isOnboarding && (
                        <div className="flex flex-col gap-2">
                          <NavigationButton
                            to="/discover"
                            target="_blank"
                            rel="noreferrer"
                            icon={<RiGlobeLine />}
                            size="lg"
                            variant="plain"
                            align="left"
                          >
                            Discover
                          </NavigationButton>
                          <NavigationButton
                            to="/search"
                            target="_blank"
                            rel="noreferrer"
                            icon={<RiSearch2Line />}
                            size="lg"
                            variant="plain"
                            align="left"
                            $fullwidth
                          >
                            <p className="flex w-full gap-2">
                              <span>Ask</span>
                              <span className="text-xs border p-1 rounded-lg">
                                beta
                              </span>
                            </p>
                          </NavigationButton>
                          <NavigationButton
                            to="/listen"
                            target="_blank"
                            rel="noreferrer"
                            icon={<RiPlayFill />}
                            size="lg"
                            variant="plain"
                            align="left"
                          >
                            Podcast
                          </NavigationButton>
                        </div>
                      )}
                      <div
                        className={classNames("flex gap-2", {
                          "flex-col": isAuth,
                          "flex-row pr-4": !isAuth,
                        })}
                      >
                        {!isAuth && (
                          <div className="flex flex-row gap-4">
                            <NavigationButton
                              to="/login"
                              target="_blank"
                              rel="noreferrer"
                              size="lg"
                              variant="plain"
                              align="center"
                            >
                              Login
                            </NavigationButton>
                            <NavigationButton
                              to="/signup"
                              target="_blank"
                              rel="noreferrer"
                              size="lg"
                              variant="primary"
                              align="center"
                            >
                              Signup
                            </NavigationButton>
                          </div>
                        )}

                        {isAuth && (
                          <>
                            {!isOnboarding && (
                              <>
                                <NavigationButton
                                  to="/edit"
                                  icon={<RiSeedlingLine />}
                                  size="sm"
                                  variant="plain"
                                  align="left"
                                  className="rounded-none"
                                >
                                  Edit interests
                                </NavigationButton>
                                <NavigationButton
                                  to="/library"
                                  icon={<RiHistoryFill />}
                                  size="sm"
                                  variant="plain"
                                  align="left"
                                  className="rounded-none"
                                >
                                  Library
                                </NavigationButton>
                              </>
                            )}
                            <Button
                              onClick={handleShowFeedbackModal}
                              icon={<RiFeedbackLine />}
                              size="sm"
                              variant="plain"
                              align="left"
                              className="px-4 rounded-none"
                            >
                              Send feedback
                            </Button>
                          </>
                        )}
                        {isAuth && (
                          <>
                            <Button
                              href="https://testflight.apple.com/join/jlQPI7I8"
                              target="_blank"
                              rel="noreferrer"
                              icon={<RiAppleFill />}
                              size="sm"
                              variant="plain"
                              align={isAuth ? "left" : "center"}
                              className="px-4 rounded-none"
                            >
                              Try the iOS app
                            </Button>
                            <NavigationButton
                              to="/settings"
                              icon={<RiProfileLine />}
                              size="sm"
                              variant="plain"
                              align="left"
                              className="rounded-none"
                            >
                              Settings
                            </NavigationButton>
                            <div className="px-2">
                              <a
                                href="https://www.producthunt.com/posts/tailor-78fc1f19-962e-4158-808c-dce6e26f28d1?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-tailor&#0045;78fc1f19&#0045;962e&#0045;4158&#0045;808c&#0045;dce6e26f28d1"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=445180&theme=light&period=daily"
                                  alt="Tailor - Supercharge&#0032;your&#0032;news&#0032;with&#0032;AI | Product Hunt"
                                  style={{ width: 250, height: 54 }}
                                />
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"></div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="flex flex-col gap-1 pb-4 pt-2 bg-white dark:bg-black border-b">
                  {isAuth && (
                    <>
                      {!isOnboarding && (
                        <>
                          <Disclosure.Button
                            as={MobileNavigationButton}
                            to="/edit"
                            icon={<RiSeedlingLine />}
                            variant="plain"
                            size="sm"
                          >
                            Edit interests
                          </Disclosure.Button>
                          <Disclosure.Button
                            as={MobileNavigationButton}
                            to="/library"
                            icon={<RiHistoryFill />}
                            variant="plain"
                            size="sm"
                          >
                            Library
                          </Disclosure.Button>
                        </>
                      )}
                      <Disclosure.Button
                        as={Button}
                        href="https://testflight.apple.com/join/jlQPI7I8"
                        target="_blank"
                        rel="noreferrer"
                        icon={<RiSmartphoneLine />}
                        variant="plain"
                        size="sm"
                        $fullwidth
                      >
                        Try the iOS app!
                      </Disclosure.Button>
                      <Disclosure.Button
                        as={Button}
                        onClick={handleShowFeedbackModal}
                        icon={<RiFeedbackLine />}
                        variant="plain"
                        size="sm"
                        $fullwidth
                      >
                        Send feedback
                      </Disclosure.Button>
                      <Disclosure.Button
                        as={MobileNavigationButton}
                        to="/settings"
                        icon={<RiProfileLine />}
                        variant="plain"
                        size="sm"
                      >
                        Settings
                      </Disclosure.Button>
                    </>
                  )}
                  {!isAuth && (
                    <>
                      <Disclosure.Button
                        as={MobileNavigationButton}
                        to="/signup"
                        variant="plain"
                        size="sm"
                      >
                        Signup
                      </Disclosure.Button>
                      <Disclosure.Button
                        as={MobileNavigationButton}
                        to="/login"
                        variant="plain"
                        size="sm"
                      >
                        Login
                      </Disclosure.Button>
                    </>
                  )}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div className="absolute bottom-0 left-0 right-0"></div>
        {isAuth && (
          <>
            <Modal open={showFeedbackModal} onClose={handleCloseFeedbackModal}>
              <Modal.Title>Share your feedback with us</Modal.Title>
              <Modal.MainContainer>
                <Modal.Body>
                  <Label>Leave a comment here</Label>
                  <Textarea value={feedback} onValueChange={setFeedback} />
                </Modal.Body>
              </Modal.MainContainer>
              <Modal.Footer>
                <Button variant="primary" onClick={handleSubmitFeedback}>
                  Send feedback
                </Button>
              </Modal.Footer>
            </Modal>
            <Toast open={showSuccessToast} onClose={toggleShowSuccessToast}>
              <Toast.Body>Thank you for your feedback!</Toast.Body>
            </Toast>
          </>
        )}
      </div>
      {!isOnboarding && (
        <div className="block md:hidden absolute z-10 w-full bottom-0">
          <NavigationTabs
            tabs={[
              {
                label: "Ask [beta]",
                to: "/search",
                icon: <RiSearch2Line />,
              },
              {
                label: "Discover",
                to: "/discover",
                icon: <RiGlobeLine />,
              },
              {
                label: "Podcast",
                to: "/listen",
                icon: <RiPlayFill />,
              },
            ]}
          />
        </div>
      )}
    </>
  );
};

export default NavigationBar;
