import Button from "@/components/styled/Button";
import { Link } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";

type BackButtonProps = {
  label?: string;
  url?: string;
  onClick?: () => void;
};

const BackButton = (props: BackButtonProps) => {
  const button = (
    <Button
      variant="secondary"
      size="sm"
      onClick={props.onClick}
      icon={<RiArrowLeftSLine />}
    >
      {props.label != undefined && props.label}
      {props.label == undefined && <span>Back</span>}
    </Button>
  );
  return (
    <>
      {props.url != undefined ? <Link to={props.url}>{button}</Link> : button}
    </>
  );
};

export default BackButton;
