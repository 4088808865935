import React from "react";

type JSONRendererProps = {
  data: { [key: string]: any }; // Accepts a JSON object
};

const unSlug = (value: string) => {
  value = value.replaceAll("_", "-");
  return value
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const flattenObject = (
  obj: any,
  parentKey = "",
  result: Record<string, string> = {}
) => {
  for (let [key, value] of Object.entries(obj)) {
    const fullKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof value === "object" && !Array.isArray(value) && value !== null) {
      flattenObject(value, fullKey, result);
    } else {
      result[key] =
        typeof value === "string" ? unSlug(value) : (value as string);
    }
  }
  return result;
};

const JSONRenderer: React.FC<JSONRendererProps> = ({ data }) => {
  const flattenedData = flattenObject(data);

  return (
    <table className="table-fixed">
      <tbody>
        {Object.entries(flattenedData).map(([key, value]) => (
          <tr key={key}>
            <td className="font-bold uppercase text-xs px-2 py-2 w-40 align-text-top">
              {key}
            </td>
            <td className="px-1 py-2 items-center">
              {typeof value === "string" ? value : JSON.stringify(value)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default JSONRenderer;
