import React, { useEffect } from "react";
import { SourceItemStoryMeta } from "@/utils/types";
import MarconipyApi from "@/utils/marconipyApi";
import Modal from "@/components/styled/Modal";
import Container from "@/components/styled/Container";
import SourceItemAnalysisDetailView from "@/screens/partials/SourceItemAnalysisDetailView";
import SourceItemScoreCard from "./SourceItemScoreCard";
import SourceSetImage from "./SourceSetImage";
import Divider from "./styled/Divider";
import Button from "./styled/Button";
import { RiExternalLinkLine } from "react-icons/ri";

type SourceItemAnalysisCardProps = {
  meta: SourceItemStoryMeta;
};

export const SourceItemAnalysisCard = ({
  meta,
}: SourceItemAnalysisCardProps) => {
  const [sourceitemstorymeta, setSourceItemStoryMeta] = React.useState(meta);
  const [firstRefreshDone, setFirstRefreshDone] = React.useState(false);
  const [openReadMoreModal, setOpenReadMoreModal] = React.useState(false);

  useEffect(() => {
    const refresh = async () => {
      const newmeta = (await MarconipyApi.getSourceItemStoryMeta(
        sourceitemstorymeta.uuid
      )) as any as SourceItemStoryMeta;
      setSourceItemStoryMeta(newmeta);
    };

    const refreshAfterWait = async () => {
      await new Promise((r) => setTimeout(r, 2000));
      await refresh();
    };
    if (sourceitemstorymeta.analysis_status != "completed") {
      refreshAfterWait();
    } else {
      if (!firstRefreshDone) {
        setFirstRefreshDone(true);
        refresh();
      }
    }
  }, [firstRefreshDone, sourceitemstorymeta]);

  const onReadMore = () => {
    if (sourceitemstorymeta.analysis_status != "completed") {
      return;
    }
    setOpenReadMoreModal(true);
  };

  return (
    <>
      <div
        onClick={onReadMore}
        className="flex flex-col gap-4 overflow-auto my-4 cursor-pointer bg-white dark:bg-gray-800 rounded-lg"
      >
        <div
          id={`${sourceitemstorymeta.uuid}`}
          className="flex flex-col rounded-lg"
        >
          {sourceitemstorymeta.sourceitem?.image_url && (
            <img
              className="h-40 rounded-t-lg object-cover object-center"
              src={sourceitemstorymeta.sourceitem.image_url}
            />
          )}
          <div className="flex flex-col gap-4 border-x border-b dark:border-0 rounded-b-lg">
            <div className="flex flex-col gap-4 pt-4 px-4">
              <h2>{sourceitemstorymeta.sourceitem?.title ?? "Loading"}</h2>
              <div className="flex flex-row gap-2 items-left">
                <a
                  className="min-w-48 flex flex-col gap-4"
                  href={sourceitemstorymeta.sourceitem?.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex gap-2 items-center">
                    {sourceitemstorymeta.sourceitem &&
                      sourceitemstorymeta.sourceitem.sourceset &&
                      sourceitemstorymeta.sourceitem.sourceset.image && (
                        <div className="flex flex-row gap-1">
                          <SourceSetImage
                            key={sourceitemstorymeta.uuid}
                            image={
                              sourceitemstorymeta.sourceitem.sourceset.image
                            }
                            alt={sourceitemstorymeta.sourceitem.sourceset.name}
                            size="sm"
                          />
                        </div>
                      )}
                    <div className="flex flex-col justify-center">
                      {sourceitemstorymeta.sourceitem?.sourceset?.name}
                    </div>
                    <RiExternalLinkLine />
                  </div>
                </a>
              </div>
            </div>
            <Divider />
            <div className="flex flex-col gap-4 px-4 pb-4">
              <SourceItemScoreCard sourceitemstorymeta={sourceitemstorymeta} />
              {sourceitemstorymeta.analysis_status == "completed" && (
                <Button className="w-full" onClick={onReadMore}>
                  Read full analysis
                </Button>
              )}
              {sourceitemstorymeta.analysis_status != "completed" && (
                <Button className="w-full" disabled={true}>
                  Analyzing...
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openReadMoreModal}
        onClose={() => {
          setOpenReadMoreModal(false);
        }}
        size="lg"
      >
        <Modal.Body>
          <Container className="p-0">
            <SourceItemAnalysisDetailView
              sourceitemstorymeta={sourceitemstorymeta}
            />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
