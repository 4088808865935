import { FeedSectionType, Story } from "@/utils/types";
import SourcesBadges from "./SourcesBadges";
import { TextFrontend } from "@/utils/Text";

type StoryCardProps = {
  section: FeedSectionType;
};

export const StoryCard = ({ section }: StoryCardProps) => {
  const story = section.story as Story;
  return (
    <div className="col-span-6 shadow border border-gray bg-white dark:bg-black hover:shadow-lg transition-all ease-in-out rounded-lg">
      <a href={`/story/${story.uuid}`} className="mt-0 mb-8 lg:px-0">
        <div
          id={`card_${story.uuid}`}
          className="pt-4 px-4 flex flex-col gap-4"
        >
          <h2 className="text-left w-full hover:underline">{story.title}</h2>
          <div className="flex flex-row gap-4 items-center">
            <SourcesBadges story={story} />
            {story.first_sourceitem_published_at && (
              <div className="text-xs">
                <span className="opacity-70">
                  {story.first_sourceitem_published_at?.toString()}
                </span>
              </div>
            )}
          </div>
          {story.top_image && (
            <div className="w-full h-48 rounded-lg border border-gray-300 dark:border-gray-600">
              <img
                src={story.top_image}
                className="rounded-md w-full h-full object-cover object-center"
              />
            </div>
          )}
          {story.bulletpoints_content && story.bulletpoints_content != "" && (
            <p
              className="pr-8 opacity-70 truncate hover:underline"
              dangerouslySetInnerHTML={{
                __html: TextFrontend.stripMarkdownLines(
                  story.bulletpoints_content,
                  true,
                  true
                ).split("<br/>")[0],
              }}
            ></p>
          )}
        </div>
        <br />
      </a>
    </div>
  );
};
