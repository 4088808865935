import { ImgHTMLAttributes } from "react";

export default function Logo({
  variant,
  ...props
}: ImgHTMLAttributes<HTMLImageElement> & {
  variant?: "full" | "icon" | "svg";
}) {
  const fromVariantToSrc = (variant: string) => {
    switch (variant) {
      case "icon":
        return "/logo-512x512.png";
      case "svg":
        return "/logo.svg";
      default:
        return "/navbar_logo.png";
    }
  };

  return (
    <img
      src={fromVariantToSrc(variant || "full")}
      alt="Tailor Logo"
      {...props}
    />
  );
}
