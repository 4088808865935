import { TextFrontend } from "@/utils/Text";
import CitationTooltip from "./CitationTooltip";

const BulletpointsBlock = ({
  content,
  content_json,
  sources,
}: {
  content: string;
  sources: Record<string, any>;
  content_json?: any;
}) => {
  const shouldShowCitations = content_json && content_json.value;
  return (
    <div className="flex flex-col gap-6">
      {!shouldShowCitations && (
        <p
          className="mb-4"
          dangerouslySetInnerHTML={{
            __html: TextFrontend.stripMarkdownLines(content, true),
          }}
        ></p>
      )}
      {shouldShowCitations &&
        content_json.value.map((item: any, index: number) => (
          <div className="flex flex-row gap-2" key={index}>
            <div className="h-8 w-8 flex items-center items-top justify-center rounded-lg bg-gray-100 dark:bg-black-deep flex-none text-sm">
              {item.emoji}
            </div>
            <p key={index}>
              <span className="font-bold pr-2 items-baseline">
                {item.one_word_title} —
              </span>
              <span className="items-baseline">{item.text}</span>{" "}
              {item.source_uuids?.map((source: any, index: number) => (
                <CitationTooltip
                  key={index}
                  index={sources[source].index}
                  sourceitem={sources[source].sourceitem}
                />
              ))}
            </p>
          </div>
        ))}
    </div>
  );
};

export default BulletpointsBlock;
