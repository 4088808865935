import classNames from "classnames";

type ScoreCircleProps = {
  score: string;
  colorVariant?: "warning" | "alert" | "default" | "good" | "loading";
  variant?: "default" | "primary";
  label: string;
};

export default function ScoreCircle({
  score,
  label,
  colorVariant = "default",
  variant = "default",
}: ScoreCircleProps) {
  return (
    <div className="flex items-center">
      <div
        className={classNames(
          "rounded-full flex flex-shrink-0 items-center justify-center text-mdflex-none text-gray-700 dark:text-black",
          {
            "w-12 h-12": variant == "primary",
            "w-10 h-10": variant == "default",
            "bg-primary dark:bg-primary": colorVariant === "alert",
            "bg-yellow dark:bg-yellow": colorVariant === "warning",
            "bg-gray-200": colorVariant === "default",
            "bg-green dark:bg-green": colorVariant === "good",
            "bg-gray-300 animate-pulse": colorVariant === "loading",
          }
        )}
      >
        <span
          className={classNames("text-sm font-bold", {
            "text-black": colorVariant === "warning",
            "text-white dark:text-black": colorVariant === "alert",
            "text-gray": colorVariant === "default",
          })}
        >
          {score}
        </span>
      </div>
      <span
        className={classNames("text-sm ml-2 text-wrap", {
          "font-bold": variant == "primary",
        })}
      >
        {label}
      </span>
    </div>
  );
}
