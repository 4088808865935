import { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Digest } from "utils/types";
import classnames from "classnames";
import Divider from "@/components/styled/Divider";
import { useAuth } from "@/contexts/AuthenticationContext";
import { AuthModal } from "@/components/auth/AuthModal";
import { Footer } from "@/components/Footer";
import { SmallPlayer } from "@/components/AudioPlayer";
import { FeedPartialView } from "./FeedPartialView";
import Card from "@/components/styled/Card";
import { TextFrontend } from "@/utils/Text";
import FeedSection from "@/components/styled/FeedSection";
import { DigestShareButton } from "@/components/DigestShareButton";
import PublicSignupCTACard from "@/components/PublicSignupCTACard";
import { MetaHeadEmbed } from "@phntms/react-share";
import { useSearchParams } from "react-router-dom";

type SharedDigestPartialViewProps = {
  digest: Digest;
};

export default function SharedDigestView(props: SharedDigestPartialViewProps) {
  const { isAuth } = useAuth();
  const [digestState] = useState<Digest>(props.digest);
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const jsonNewsArticleSchema = () => {
    return JSON.stringify({
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      headline: digestState.title,
      image: digestState.top_image,
      datePublished: digestState.published_at,
      dateModified: digestState.published_at,
      author: [
        {
          "@type": "Organization",
          name: "Tailor Media, Inc.",
          url: "https://tailor.news/",
        },
      ],
    });
  };

  const shareText = useMemo(() => {
    let text = "From Tailor News: " + digestState.title + "\n\n";
    let _text = digestState.stories.reduce((acc, story) => {
      return (
        acc + story.title + "\n\n" + story.bulletpoints_content + "\n\n===\n\n"
      );
    }, "");
    text += _text + "Read more at " + digestState.permalink;
    return text;
  }, [digestState.permalink, digestState.stories, digestState.title]);

  if (!digestState) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{digestState.title || ""}</title>
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Tailor News RSS Feed"
          href="/rss.xml"
        />
        <meta property="og:title" content={digestState.title} />
        <meta property="og:site_name" content="Tailor" />
        <meta property="og:description" content={digestState.description} />
        <meta
          property="og:image"
          content={digestState.top_image || undefined}
        />
        <meta property="og:url" content={digestState.permalink} />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Tailor" />
        <meta property="article:publisher" content="Tailor" />
        <meta
          property="article:published_time"
          content={digestState.published_at.toString()}
        ></meta>
        {/* <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" /> */}
        <script type="application/ld+json">{jsonNewsArticleSchema()}</script>
        <meta property="og:locale" content={digestState.language || "en"} />
        <link
          rel="canonical"
          href={`https://tailor.news/digest/${digestState.uuid}`}
        />
        <MetaHeadEmbed
          render={(meta: React.ReactNode) => <>{meta}</>}
          siteTitle="Tailor"
          pageTitle={digestState.title || "Tailor News - Loading"}
          titleTemplate="[pageTitle] | [siteTitle]"
          description={digestState.description}
          baseSiteUrl="https://tailor.news"
          pagePath={`digest/${digestState.uuid}`}
          keywords={["Tailor", "news"]}
          imageUrl={digestState.top_image || undefined}
          imageAlt="Tailor News"
          twitter={{
            cardSize: "large",
            siteUsername: "@tailormedia_ai",
            creatorUsername: "@tailormedia_ai",
          }}
        />
      </Helmet>
      <div
        className={classnames(
          "col-span-12 grid grid-cols-12 overflow-y-scroll"
        )}
      >
        {/* {isAuth && (
          <SwitchToAppPanel deepLink={"storyDetail?id=" + digestState.uuid} />
        )} */}
        <div className="absolute bottom-0 left-0 right-0 z-10 grid grid-cols-12">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 lg:col-start-3 lg:col-span-8">
            <SmallPlayer
              showTitle
              className="bg-white dark:bg-black border-t border-l border-r rounded-t-lg border-gray-200 p-2"
            />
          </div>
        </div>
        <FeedSection>
          <Card className="relative flex flex-col gap-4 overflow-auto my-4 cursor-pointer p-4">
            <p>{digestState.contentset_title}</p>
            <h1 className="text-left w-full">{digestState.title}</h1>
            <p
              className="line-clamp-12"
              dangerouslySetInnerHTML={{
                __html: TextFrontend.stripMarkdownLines(
                  digestState.description,
                  true
                ),
              }}
            />
            <div className="flex flex-row gap-4 items-center justify-between">
              <div className="text-xs">
                {TextFrontend.formatDate(new Date(digestState.published_at))}
              </div>
              <DigestShareButton
                digest={digestState}
                canShare={true}
                url={digestState.permalink}
                text={shareText}
                open={searchParams.get("share") === "true"}
              />
            </div>
          </Card>
        </FeedSection>
        <FeedPartialView
          feed={{
            sections: digestState.stories.map((story) => ({
              uuid: story.uuid,
              type: "story",
              story,
              metadata: {},
            })),
            pagination_timestamp: null,
            status: "published",
            category: null,
            categories: null,
          }}
          canRequestNewContent={false}
          canLoadNewContent={false}
          canShowGeneratingView={false}
          advancedFeedOptions={false}
          disableScroll={true}
          onAuthRedirect={() => setAuthModalOpen(true)}
          footer={
            <>
              <Divider />
              <PublicSignupCTACard
                onAuthRedirect={() => setAuthModalOpen(true)}
              />
            </>
          }
        />
        {!isAuth && (
          <>
            <Divider className="col-span-12" />
            <Footer />
            <div className="mb-20"></div>
          </>
        )}
      </div>
      {!isAuth && (
        <AuthModal
          isOpen={isAuthModalOpen}
          setOpen={setAuthModalOpen}
          redirectToAfterLogin={window.location.pathname}
          title="Sign up to get the full story."
          subtitle={
            <>
              Tailor is a personal assistant
              <br />
              that reads every piece of news.
            </>
          }
        />
      )}
    </>
  );
}
