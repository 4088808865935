import {
  RiSpeakerFill,
  RiNewspaperLine,
  RiYoutubeFill,
  RiTwitterFill,
  RiQuestionMark,
} from "react-icons/ri";

export class TypeUtils {
  static fromContentSetTypeToHuman = (
    type: string,
    capitalize: boolean = false
  ): string => {
    if (type === "newsletter") {
      return TypeUtils.capitalizeFirstLetterIfNeeed("newsletter", capitalize);
    } else if (type === "podcast") {
      return TypeUtils.capitalizeFirstLetterIfNeeed("podcast", capitalize);
    } else if (type === "composite") {
      return TypeUtils.capitalizeFirstLetterIfNeeed("daily", capitalize);
    } else if (type === "story") {
      return TypeUtils.capitalizeFirstLetterIfNeeed("story", capitalize);
    } else {
      return "questionmark";
    }
  };
  static capitalizeFirstLetterIfNeeed(
    input: string,
    capitalize: boolean
  ): string {
    if (capitalize) {
      return input.charAt(0).toUpperCase() + input.slice(1);
    } else {
      return input;
    }
  }
  static fromContentSetTypeToEpisode(
    type: string,
    capitalize: boolean = false
  ): string {
    if (type == "podcast") {
      return TypeUtils.capitalizeFirstLetterIfNeeed("episode", capitalize);
    }
    if (type == "newsletter") {
      return TypeUtils.capitalizeFirstLetterIfNeeed("issue", capitalize);
    }
    if (type == "composite") {
      return TypeUtils.capitalizeFirstLetterIfNeeed("daily", capitalize);
    }
    throw new Error("Unknown type: " + type);
  }
  static fromSourceSetTypeToHuman = (
    type: string,
    capitalize: boolean = false
  ): string => {
    if (type === "youtube") {
      return TypeUtils.capitalizeFirstLetterIfNeeed("youtube", capitalize);
    } else if (type === "twitter") {
      return TypeUtils.capitalizeFirstLetterIfNeeed("twitter", capitalize);
    } else if (type === "text") {
      return TypeUtils.capitalizeFirstLetterIfNeeed("newspaper", capitalize);
    } else {
      return "questionmark";
    }
  };

  static fromSourceSetTypeToIcon = (type: string) => {
    if (type === "text") {
      return <RiNewspaperLine />;
    } else if (type === "podcast") {
      return <RiSpeakerFill />;
    } else if (type === "youtube") {
      return <RiYoutubeFill />;
    } else if (type === "twitter") {
      return <RiTwitterFill />;
    } else {
      return <RiQuestionMark />;
    }
  };
}
