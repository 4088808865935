import React, { useState } from "react";
import Button from "./styled/Button";
import { RiSendPlane2Line } from "react-icons/ri";
import { useAuth } from "@/contexts/AuthenticationContext";
import { AuthModal } from "./auth/AuthModal";

interface UserQueryInputProps {
  onSubmit: (query: string) => void;
  autofocus?: boolean;
}

export const UserQueryInput: React.FC<UserQueryInputProps> = ({
  onSubmit,
  autofocus = false,
}) => {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isAuth } = useAuth();

  const onLocalSubmit = async function (e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!isAuth) {
      showSignUpValuePropBox();
      return;
    }
    setIsLoading(true);
    onSubmit(query);
    setQuery(""); // Clear the input field upon submit
    setIsLoading(false);
  };
  const [showSignUpValuePropBoxOverlayer, setShowSignUpValuePropBoxOverlayer] =
    useState(false);

  const showSignUpValuePropBox = () => {
    setShowSignUpValuePropBoxOverlayer(!showSignUpValuePropBoxOverlayer);
  };

  return (
    <>
      <form onSubmit={onLocalSubmit} className="flex flex-row gap-2">
        <input
          type="text"
          value={query} // Control the input value
          onChange={(e) => setQuery(e.target.value)}
          className="rounded-lg pl-2 py-2 dark:bg-black w-full border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50" // Adjusted styling
          autoFocus={autofocus}
          placeholder="What is the cause for this?"
        />
        <Button
          type="submit"
          variant="primary"
          disabled={isLoading || query.length == 0} // Disable the button while loading
        >
          <RiSendPlane2Line />
        </Button>
      </form>
      <AuthModal
        isOpen={showSignUpValuePropBoxOverlayer}
        setOpen={showSignUpValuePropBox}
        title="Sign up to ask questions about the latest news."
        redirectToAfterLogin={window.location.pathname}
        subtitle={
          <>
            Tailor is a personal assistant
            <br />
            that reads every piece of news.
          </>
        }
      />
    </>
  );
};
