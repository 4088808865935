import classNames from "classnames";
import { ButtonHTMLAttributes, Ref, forwardRef } from "react";
import tw from "tailwind-styled-components";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: "primary" | "secondary" | "tertiary" | "plain";
  size?: "sm" | "md" | "lg";
  href?: string;
  target?: string;
  icon?: React.ReactNode;
  $fullwidth?: boolean;
  children?: React.ReactNode;
  speechReaderOnly?: string;
  hideTextOnMobile?: boolean;
  align?: "left" | "center" | "right";
};

const StyledButton = tw.button<ButtonProps>`
${({ variant }) =>
  variant === "primary"
    ? "bg-primary text-white hover:bg-secondary dark:hover:bg-gray-900 font-bold"
    : variant == "tertiary"
    ? "bg-white dark:bg-black text-primary border border-primary hover:bg-primary-light dark:hover:bg-gray-900 dark:hover:text-primary"
    : variant == "plain"
    ? "bg-white dark:bg-black text-black dark:text-white hover:text-primary hover:bg-primary-light dark:hover:bg-gray-900 dark:hover:text-primary"
    : "bg-white dark:bg-black text-black dark:text-white hover:text-primary border border-gray-200 hover:border-primary dark:hover:bg-gray-900 dark:hover:text-primary"}
${({ size }) =>
  size === "sm"
    ? "text-sm py-1.5 px-4"
    : size === "md"
    ? "text-lg py-2.5 px-4"
    : "text-base py-2 px-4"}
${({ $fullwidth }) => ($fullwidth ? "w-full" : "")}
${({ disabled }) => (disabled ? "opacity-50 cursor-not-allowed" : "")}
${({ align }) =>
  align === "left"
    ? "justify-start"
    : align === "right"
    ? "justify-end"
    : "items-center justify-center"}
flex flex-shrink-0 rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary`;

const Button = forwardRef(function Button(
  { children, speechReaderOnly, hideTextOnMobile, ...props }: ButtonProps,
  ref: Ref<HTMLButtonElement>
) {
  if (props.href) {
    children = (
      <a
        href={props.href}
        target={props.target ?? "_blank"}
        rel="noreferrer"
        className="flex items-center"
      >
        {children}
      </a>
    );
  }
  if (props.icon) {
    children = (
      <div className="flex gap-2 items-center">
        {props.icon && <div className="flex items-center">{props.icon}</div>}
        {children && (
          <div
            className={classNames({
              "hidden sm:block": hideTextOnMobile,
            })}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
  return (
    <StyledButton ref={ref} type="button" {...props}>
      {speechReaderOnly && <span className="sr-only">{speechReaderOnly}</span>}
      {children}
    </StyledButton>
  );
});

export default Button;
