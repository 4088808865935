import classnames from "classnames";
import { NavLink } from "react-router-dom";

export const NavigationTabs = ({
  tabs,
}: {
  tabs: { label: string; to: string; icon?: React.ReactNode }[];
}) => {
  return (
    <nav
      className="isolate flex divide-x shadow border-t border-gray-200 dark:border-black h-14 lg:rounded-t"
      aria-label="Tabs"
    >
      {tabs.map((tab, index) => (
        <NavLink
          to={tab.to}
          key={tab.to}
          className={({ isActive }) =>
            classnames(
              {
                "lg:rounded-tl": index == 0,
                "lg:rounded-tr": index == tabs.length - 1,
                "text-black dark:text-white bg-white dark:bg-black border-t-2 border-t-primary":
                  isActive,
                "text-gray-500 hover:text-gray-700 bg-slate-50 dark:bg-black-deep dark:border-black-light":
                  !isActive,
              },
              "group relative min-w-0 flex-1 overflow-hidden text-center text-sm font-medium hover:bg-gray-50 dark:hover:bg-black-deep focus:z-10"
            )
          }
        >
          <button>
            <div className="flex flex-col items-center gap-0 justify-center p-2">
              {tab.icon && (
                <span className="flex items-center justify-center">
                  {tab.icon}
                </span>
              )}
              <span className="flex-shrink-0 tex-sm">{tab.label}</span>
            </div>
          </button>
        </NavLink>
      ))}
    </nav>
  );
};
