import { Helmet } from "react-helmet";
import Container from "@/components/styled/Container";
import { Footer } from "@/components/Footer";
import Divider from "@/components/styled/Divider";

export default function AINews() {
  return (
    <>
      <Helmet>
        <title>AI news: The best way to get your news, powered by an AI</title>
        <meta
          name="description"
          content="Being on top of what is happening is increasingly difficult. Tailor is your best AI news companion: It will provide daily briefs, tell you what is real, what is fake, and what is an opinion."
        />
        <meta
          property="og:title"
          content="AI news: The best way to get your news, powered by an AI"
        />
        <meta
          property="og:description"
          content="Being on top of what is happening is increasingly difficult. Tailor is your best AI news companion: It will provide daily briefs, tell you what is real, what is fake, and what is an opinion."
        />
        <meta property="og:url" content="https://tailor.news/ai-news" />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
        <link rel="canonical" href="https://tailor.news/ai-news" />
      </Helmet>
      <div className="col-span-10 col-start-2">
        <Container className="my-20 pb-20 bg-white dark:bg-black rounded-lg">
          <h1 className="static">AI News</h1>
          <h2>Get your news every day with Tailor</h2>
          <p>
            It is increasingly difficult to navigate the vast amount of
            information available every day. Sifting through the mountain of
            content it's difficult and tedious. It's a noisy world. Meet the
            best AI news: Tailor. First of all: Tailor helps you make sense of
            the noise in just a few clicks. It's like having your personal
            content assistant. Tailor creates a unique, curated, selection of
            what is important for you, every day, based on your interests.
            Everyone has a unique set of interests and topics they are
            interested in. Everybody deserves their own personalized podcast,
            newsletter, and post, every day. Secondly, anybody has their own
            specific allotted time to get up to speed during the day. Is your
            commute 30 minutes? Or 10? Or do you really just have that 3-minute
            walking distance to your cafe and that's all the time you have in
            the world? Tailor has you covered. With Tailor, you can define
            exactly how much time you want to dedicate to getting up to speed
            with everything happening in the world and in your professional
            space.
          </p>
          <img
            src="https://assets-global.website-files.com/6418714a2d680d05514cca34/651d8c3286fad1ea7130d592_695825_a%20vortex%20of%20newspapers%2C%20international%20typographic%20_xl-1024-v1-0-p-1080.png"
            alt="The nosie of the news, illustration"
          />
          <h2>The AI news revolution is here</h2>
          <p>
            Everybody has their own favorite format. Some folks really like
            video. Some other people like to listen to a lot of audio. In some
            situations, a very short and to-the-point bullet point list is
            everything you need. But this is the issue: traditional news does
            not work that way. In fact, it is static. A podcast will always be
            audio. A TV broadcast will always be a video. Luckily, with Tailor
            the format does not matter. If you are interested in that long paper
            but you need to drive, that's fine! Tailor will turn it into a
            classy podcast. Also consider that the other way around is true, of
            course. If you don't feel like watching a long video, Tailor will
            boil it down to a short summary with emojis, just for you. In
            conclusion, if you are interested in AI news, Tailor is the right
            app for you. You can start using it for free now!
          </p>
        </Container>
      </div>
      <div className="col-span-12">
        <Divider />
        <Footer />
      </div>
    </>
  );
}
