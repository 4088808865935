import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import { redirect, useNavigate } from "react-router-dom";
import SignupForm from "@/components/auth/SignupForm";
import Card from "@/components/styled/Card";
import Container from "@/components/styled/Container";
import Button from "@/components/styled/Button";

export default function Signup() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Signup - Tailor</title>
        <meta
          name="description"
          content="Create your Tailor account. Get an A.I. to help you understand the news."
        />
        <meta property="og:title" content="Signup - Tailor" />
        <meta
          property="og:description"
          content="Create your Tailor account. Get an A.I. to help you understand the news"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
        <link rel="canonical" href="https://tailor.news/signup" />
      </Helmet>

      <div className="col-span-12 sm:col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 grid grid-cols-12">
        <Container>
          <Card className="flex flex-col gap-2">
            <h1>Signup</h1>
            {/* <SignupForm onLoginRedirect={() => navigate("/login")} /> */}
            <br />
            <br />
            <Button
              variant="primary"
              onClick={() =>
                (window.location.href = "https://tally.so/r/wQ0Kr1")
              }
            >
              Join the waitlist
            </Button>
          </Card>
        </Container>
      </div>
    </>
  );
}

export async function loader({}: { params: any }) {
  try {
    //check if the user is already logged in
    const loggedIn = MarconipyApi.isAuth();
    if (loggedIn) {
      console.log("User is already logged in");
      return redirect("/");
    } else {
      return false;
    }
  } catch (e) {
    throw e;
  }
}
