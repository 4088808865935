import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import { loader as rootLoader } from "@/screens/Root";
import Home, { loader as homeLoader } from "@/screens/Home";
import { loader as confirmemailviewLoader } from "@/screens/ConfirmEmail";
import { loader as loginLoader } from "@/screens/Login";
import { loader as loginSocialLoader } from "@/screens/SocialLogin";
import { loader as logoutLoader } from "@/screens/Logout";
import { loader as signupLoader } from "@/screens/Signup";
import { loader as passwordForgottenLoader } from "@/screens/PasswordForgotten";

import { loader as OnboardingLoader } from "@/screens/OnboardingView";
import { loader as FailedContentsetLoader } from "@/screens/FailedContentsetView";

import { PostHogProvider } from "posthog-js/react";
import { loader as accountLoader } from "./screens/AccountView";
import { loader as editLoader } from "./screens/ContentSetEditView";
import { loader as redirectorLoader } from "./screens/Redirector";
import { loader as waitlistLoader } from "./screens/WaitlistView";
import { Loading } from "@/components/Loading";
import { loader as bannedViewLoader } from "./screens/BannedView";
import { loader as storyLoader } from "./screens/StoryView";
import { loader as digestLoader } from "./screens/DigestView";
import { loader as feedLoader } from "./screens/FeedView";
import { loader as featuredLoader } from "./screens/Featured";
import { loader as archiveLoader } from "./screens/ArchiveView";
import { AuthProvider } from "./contexts/AuthenticationContext";
import { loader as searchLoader } from "./screens/SearchView";
import { loader as podcastLoader } from "./screens/PodcastView";
import { loader as historyFeedLoader } from "./screens/HistoryView";
import { TailorErrorBoundary } from "./contexts/TailorErrorBoundary";
import TermsOfUse from "./screens/static/TermsOfUse";
import RootStatic from "./screens/static/RootStatic";
import PrivacyPolicy from "./screens/static/PrivacyPolicy";
import Careers from "./screens/static/Careers";
import ContactUs from "./screens/static/ContactUs";
import AINews from "./screens/static/AINews";
import OurPrinciples from "./screens/static/OurPrinciples";
import Blog from "./screens/static/Blog";

const Root = React.lazy(() => import("@/screens/Root"));
const ConfirmEmailView = React.lazy(() => import("@/screens/ConfirmEmail"));
const Login = React.lazy(() => import("@/screens/Login"));
const SocialLogin = React.lazy(() => import("@/screens/SocialLogin"));
const Logout = React.lazy(() => import("@/screens/Logout"));
const Signup = React.lazy(() => import("@/screens/Signup"));
const PasswordForgotten = React.lazy(
  () => import("@/screens/PasswordForgotten")
);
const OnboardingView = React.lazy(() => import("@/screens/OnboardingView"));
const FailedContentSetView = React.lazy(
  () => import("@/screens/FailedContentsetView")
);
const AccountView = React.lazy(() => import("@/screens/AccountView"));
const Redirector = React.lazy(() => import("@/screens/Redirector"));
const WaitlistView = React.lazy(() => import("@/screens/WaitlistView"));
const FeedView = React.lazy(() => import("@/screens/FeedView"));
const HistoryView = React.lazy(() => import("@/screens/HistoryView"));
const FeaturedView = React.lazy(() => import("@/screens/Featured"));
const ArchiveView = React.lazy(() => import("@/screens/ArchiveView"));
const BannedView = React.lazy(() => import("@/screens/BannedView"));
const StoryView = React.lazy(() => import("@/screens/StoryView"));
const DigestView = React.lazy(() => import("@/screens/DigestView"));
const NotFoundView = React.lazy(() => import("@/screens/NotFoundPage"));
const SearchView = React.lazy(() => import("@/screens/SearchView"));
const PodcastView = React.lazy(() => import("@/screens/PodcastView"));
const EditView = React.lazy(() => import("@/screens/ContentSetEditView"));

const withSuspense = (component: any) => {
  return <React.Suspense fallback={<Loading />}>{component}</React.Suspense>;
};

const overriddenFeatureFlags =
  localStorage.getItem("override-feature-flags") ?? "{}";
export const overriddenFeatureFlagsObject = JSON.parse(overriddenFeatureFlags);

const isAgent =
  navigator.userAgent.includes("Cypress") ||
  navigator.userAgent.includes("CACHEAUTOAGENT");
const canTrack = !isAgent;

const posthogoptions = {
  api_host: import.meta.env.VITE_REACT_APP_PROXY_POSTHOG_HOST,
  ui_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
  debug: window.location.hostname.includes("localhost"),
  bootstrap: {
    featureFlags: {
      ...overriddenFeatureFlagsObject,
      "under-maintenance": Boolean(
        import.meta.env.VITE_REACT_APP_UNDER_MAINTENANCE
      ),
    },
  },
  autocapture: canTrack,
  capture_pageview: canTrack,
  capture_pageleave: canTrack,
  disable_persistence: !canTrack,
  disable_session_recording: !canTrack,
  opt_out_capturing_by_default: !canTrack,
  opt_out_persistence_by_default: !canTrack,
};

let router = createBrowserRouter([
  {
    path: "/",
    element: <RootStatic />,
    children: [
      {
        path: "/",
        element: <Home />,
        loader: homeLoader,
      },
      {
        path: "/terms-of-use",
        element: <TermsOfUse />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/careers",
        element: <Careers />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      {
        path: "/ai-news",
        element: <AINews />,
      },
      {
        path: "/principles",
        element: <OurPrinciples />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      // {
      //   path: "/country-unavailable",
      //   element: <NotAvailableInYourCountry />,
      // },
    ],
  },
  {
    path: "/",
    element: withSuspense(<Root />),
    loader: rootLoader,
    errorElement: <TailorErrorBoundary />,
    children: [
      // {
      //   path: "/",
      //   element: withSuspense(<Home />),
      //   loader: homeLoader,
      // },
      {
        path: "/confirm-email/:key",
        element: withSuspense(<ConfirmEmailView />),
        loader: confirmemailviewLoader,
      },
      {
        path: "/login",
        element: withSuspense(<Login />),
        loader: loginLoader,
      },
      {
        path: "/logout",
        element: withSuspense(<Logout />),
        loader: logoutLoader,
      },
      {
        path: "/signup",
        element: withSuspense(<Signup />),
        loader: signupLoader,
      },
      {
        path: "/accounts/:provider/login/callback/",
        element: withSuspense(<SocialLogin />),
        loader: loginSocialLoader,
      },
      {
        path: "/forgot-password",
        element: withSuspense(<PasswordForgotten />),
        loader: passwordForgottenLoader,
      },
      {
        path: "/settings",
        element: withSuspense(<AccountView />),
        loader: accountLoader,
      },
      {
        path: "/edit",
        element: withSuspense(<EditView />),
        loader: editLoader,
      },
      {
        path: "/discover",
        element: withSuspense(<FeedView />),
        loader: feedLoader,
      },
      {
        path: "/library",
        element: withSuspense(<HistoryView />),
        loader: historyFeedLoader,
      },
      {
        path: "/search",
        element: withSuspense(<SearchView />),
        loader: searchLoader,
      },
      {
        path: "/listen",
        element: withSuspense(<PodcastView />),
        loader: podcastLoader,
      },
      {
        path: "/generating", // legacy
        element: <Navigate to="/discover" />,
      },
      {
        path: "/waitlist",
        element: withSuspense(<WaitlistView />),
        loader: waitlistLoader,
      },
      {
        path: "/banned",
        element: withSuspense(<BannedView />),
        loader: bannedViewLoader,
      },
      {
        path: "/failed",
        element: withSuspense(<FailedContentSetView />),
        loader: FailedContentsetLoader,
      },
      {
        path: "/new/",
        element: withSuspense(<OnboardingView />),
        loader: OnboardingLoader,
      },
      {
        path: "/new/:contentsetId",
        element: withSuspense(<OnboardingView />),
        loader: OnboardingLoader,
      },
      {
        path: "/s/:digestId", // legacy
        element: <Navigate to="/discover" />,
      },
      {
        path: "/story/:storyId",
        element: withSuspense(<StoryView />),
        loader: storyLoader,
      },
      {
        path: "/digest/:digestId",
        element: withSuspense(<DigestView />),
        loader: digestLoader,
      },
      {
        path: "/featured",
        element: withSuspense(<FeaturedView />),
        loader: featuredLoader,
      },
      {
        path: "/featured/:categoryId",
        element: withSuspense(<FeaturedView />),
        loader: featuredLoader,
      },
      {
        path: "/featured/archive/:year/:month/",
        element: withSuspense(<FeaturedView />),
        loader: featuredLoader,
      },
      {
        path: "/featured/archive",
        element: withSuspense(<ArchiveView />),
        loader: archiveLoader,
      },
      {
        path: "/r/:redirect_type/:uuid",
        element: withSuspense(<Redirector />),
        loader: redirectorLoader,
      },
    ],
  },
  {
    path: "*",
    element: withSuspense(<NotFoundView />),
  },
]);

Sentry.init({
  dsn: "https://75afcc84cd2eaab239ac8f7b5570df28@o4504827057537024.ingest.sentry.io/4506003090046976",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/app\.usetailor\.com/,
        /^https:\/\/tailor\.news/,
      ],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.MODE == "production",
});

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default function App() {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <PostHogProvider
        apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
        options={posthogoptions}
      >
        <React.Suspense fallback={<Loading />}>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </React.Suspense>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  );
}
