// src/components/MessageBubble.tsx
import { SourceSet, Story } from "@/utils/types";
import React, { useEffect, useState } from "react";
import Tooltip from "./styled/Tooltip";
import SourceSetImage from "./SourceSetImage";

type SourcesBadgesProps = {
  story: Story;
};

const NUM_BADGES = 3;

const SourcesBadges: React.FC<SourcesBadgesProps> = ({ story }) => {
  const [sourceSetWithImages, setSourceSetWithImages] = useState<SourceSet[]>(
    []
  );
  const [extraCount, setExtraCount] = useState<number>(0);

  useEffect(() => {
    let sourcesets: SourceSet[] = [];
    let count = 0;
    if (story.sourcesets) {
      for (const sourceset of Object.values(story.sourcesets)) {
        if (sourceset.image) {
          const isSourceSetInList = sourcesets.find(
            (s) => s.uuid === sourceset.uuid
          );
          if (!isSourceSetInList) {
            if (count <= NUM_BADGES) {
              sourcesets.push(sourceset);
            }
            count++;
          }
        }
      }
    }
    if (story.sourceitems && count < NUM_BADGES) {
      for (const sourceitem of story.sourceitems) {
        if (sourceitem.sourceset?.image) {
          const isSourceSetInList = sourcesets.find(
            (s) => s.uuid === sourceitem.sourceset?.uuid
          );
          if (!isSourceSetInList) {
            if (count <= NUM_BADGES) {
              sourcesets.push(sourceitem.sourceset);
            }
            count++;
          }
        }
      }
    }
    setSourceSetWithImages(sourcesets);
    setExtraCount(count - NUM_BADGES > 0 ? count - NUM_BADGES : 0);
  }, [story]);

  return (
    <div className="flex flex-row gap-1 relative items-center">
      {sourceSetWithImages.length > 0 &&
        sourceSetWithImages.map((sourceset) => {
          return (
            <Tooltip
              key={sourceset.uuid}
              content={sourceset.name}
              placement="top"
            >
              <SourceSetImage
                image={sourceset.image}
                alt={sourceset.name}
                size={"sm"}
              />
            </Tooltip>
          );
        })}
      {extraCount > 0 && (
        <div className="flex gap-1 text-center justify-center items-center h-8 min-h-8 w-8 min-w-8 max-w-6 max-h-6 rounded-full flex-shrink-0 overflow-hidden border">
          <span className="text-xs px-1">+{extraCount}</span>
        </div>
      )}
    </div>
  );
};

export default SourcesBadges;
