import Button from "./styled/Button";
import {
  RiPlayFill,
  RiSkipBackLine,
  RiPauseFill,
  RiSkipForwardLine,
  RiCloseLine,
} from "react-icons/ri";
import ProgressBar from "@/components/styled/ProgressBar";
import { useAudioPlayerContext } from "@/contexts/AudioPlayerContext";
import classNames from "classnames";
import { TextFrontend } from "@/utils/Text";

type AudioPlayerProps = {
  className?: string;
  showTitle?: boolean;
  showEmptyPlayer?: boolean;
  showImage?: boolean;
  showCloseButton?: boolean;
};

export const SmallPlayer = ({
  showTitle,
  className,
  showImage,
  showEmptyPlayer,
  showCloseButton,
}: AudioPlayerProps) => {
  const {
    playlist,
    playAudio,
    pauseAudio,
    duration,
    currentPlayableIndex: index,
    isPlaying,
    currentPlayable: playable,
    currentPosition,
    currentPositionAdjusted,
    skipToPosition,
    skipForward,
    skipBack,
    cleanQueue,
  } = useAudioPlayerContext();

  if (!playable && !showEmptyPlayer) {
    return null;
  }

  let audioProgress = duration ? (currentPosition / duration) * 100 : 0;
  let chapterWidths = [100];
  let chapters = playable?.chapters ?? null;
  if (chapters) {
    audioProgress =
      ((currentPosition - chapters[index].audioStartTimeMS / 1000) /
        chapters[index].duration) *
      100;
    chapterWidths = duration
      ? chapters.map((chapter) => (chapter.duration / duration) * 100)
      : [];
  } else {
    chapterWidths = [100];
  }

  return (
    <div className={classNames("flex flex-col gap-2", className)}>
      {showImage && (
        <div className="w-80 h-80 md:w-96 md:h-96 mx-auto shadow bg-primary-light dark:bg-gray-900 rounded-lg flex items-center justify-center">
          {playable && playable.coverImage && (
            <img
              src={playable.coverImage}
              className="w-full h-full object-cover object-center rounded-lg"
            />
          )}
          {!playable && <p>Loading new audio...</p>}
        </div>
      )}
      <div className="grid grid-cols-12 items-center gap-8 px-4">
        <div className="col-span-4 flex flex-col gap-2 py-2">
          {showCloseButton && (
            <button
              type="button"
              className="rounded-md bg-white dark:bg-black text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={cleanQueue}
            >
              <span className="sr-only">Close</span>
              <RiCloseLine className="h-6 w-6" aria-hidden="true" />
            </button>
          )}
          {showTitle && playable && (
            <p className="text-sm truncate text-ellipsis">
              {chapters ? chapters[index].title : playable.title}
              {chapters ? chapters[index].title : playable.title}
              {chapters ? chapters[index].title : playable.title}
            </p>
          )}
        </div>
        <div className="col-span-4 flex justify-center gap-4 items-center">
          {(playlist.length > 0 || (chapters && chapters.length > 0)) && (
            <Button
              onClick={skipBack}
              className="border border-gray-200 hover:border-primary rounded-full h-10 w-10"
              icon={<RiSkipBackLine className="h-6 w-6" />}
              disabled={index <= 0}
            />
          )}
          {!isPlaying && (
            <Button
              onClick={playAudio}
              className="border border-gray-200 hover:border-primary rounded-full h-10 w-10"
              icon={<RiPlayFill className="h-6 w-6" />}
              disabled={!playable}
            />
          )}
          {isPlaying && (
            <Button
              onClick={pauseAudio}
              className="border border-gray-200 hover:border-primary rounded-full h-10 w-10"
              icon={<RiPauseFill className="h-6 w-6" />}
            />
          )}
          {(playlist.length > 0 || (chapters && chapters?.length > 0)) && (
            <Button
              onClick={skipForward}
              className="border border-gray-200 hover:border-primary rounded-full h-10 w-10"
              icon={<RiSkipForwardLine className="h-6 w-6" />}
              disabled={
                chapters
                  ? index == chapters.length - 1
                  : index == playlist.length - 1
              }
            />
          )}
        </div>
        <div className="col-span-4 flex flex-row items-center gap-2 flex-grow">
          <span className="text-xs">
            {TextFrontend.formatDuration(currentPositionAdjusted)}
          </span>
          <div className="w-full">
            <ProgressBar
              widths={chapterWidths}
              steps={chapters ? chapters.length : 1}
              currentStep={0}
              stepProgress={audioProgress ?? 0}
              onClick={(position: number, _: number) => {
                skipToPosition(duration ? duration * (position / 100) : 0);
              }}
            />
          </div>
          <span className="text-xs">
            {TextFrontend.formatDuration(playable?.duration ?? 0)}
          </span>
        </div>
      </div>
    </div>
  );
};
