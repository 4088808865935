import { SourceItem } from "@/utils/types";
import SourceSetImage from "./SourceSetImage";
import Tooltip from "./styled/Tooltip";
import { TextFrontend } from "@/utils/Text";

const CitationTooltip = ({
  index,
  sourceitem,
}: {
  index: number;
  sourceitem?: SourceItem | null;
}) => {
  if (!sourceitem) {
    return (
      <span className="border border-bg-tertiary dark:border-gray-50 hover:border-primary hover:bg-primary hover:text-white rounded-full px-1.5 py-0.5 text-sm mx-1 cursor-pointer">
        {index}
      </span>
    );
  }
  return (
    <span className="inline-flex items-baseline">
      <Tooltip
        type="popover"
        content={
          <div className="rounded-t-lg w-52 gap-2 flex flex-col">
            {sourceitem.sourceset && (
              <div className="flex gap-2 items-center">
                {sourceitem.sourceset.image && (
                  <SourceSetImage
                    image={sourceitem.sourceset.image}
                    size="sm"
                  />
                )}
                <p className="text-xs text-ellipsis">
                  {sourceitem.sourceset.name}
                </p>
              </div>
            )}
            {sourceitem.image_url && (
              <img
                src={sourceitem.image_url}
                className="w-full h-20 object-cover object-center rounded-t-lg"
              />
            )}
            <p className="text-xs font-bold">{sourceitem.title}</p>
            {sourceitem.excerpt && (
              <p className="text-xs">
                {TextFrontend.unescapeString(sourceitem.excerpt)}
              </p>
            )}
          </div>
        }
      >
        <a
          className="border border-bg-tertiary dark:border-gray-50 hover:border-primary hover:bg-primary hover:text-white rounded-full px-1.5 py-0.5 text-sm mx-1 cursor-pointer"
          href={sourceitem.link}
          target="_blank"
          rel="noreferrer"
        >
          {index}
        </a>
      </Tooltip>
    </span>
  );
};

export default CitationTooltip;
