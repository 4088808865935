import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import { redirect, useLoaderData } from "react-router-dom";
import { User } from "@/utils/types";
import Container from "@/components/styled/Container";

type userDataResponse = {
  user?: any;
};

export default function BannedView() {
  const usr = useLoaderData() as userDataResponse;

  return (
    <>
      <Helmet>
        <title>{"You are banned"}</title>
      </Helmet>
      <Container>
        {/* {redirectTo && <Navigate to={redirectTo} />} */}
        <div className="flex flex-col gap-4">
          <h1>Banned</h1>
          <h2>
            Hey {usr.user.first_name}
            {", your account was suspended from using Tailor."}
          </h2>
          <p>
            If you have any questions, or would like to appeal your ban, please
            contact us at:
            <a href="mailto:info@tailor.news">info@tailor.news</a>
          </p>
        </div>
      </Container>
    </>
  );
}

export async function loader({}: { params: any }) {
  try {
    //check if the user is already logged in
    const loggedIn = MarconipyApi.isAuth();
    if (!loggedIn) {
      console.log("User is not logged in");
      return redirect("/");
    } else {
      let user: User | null = MarconipyApi.user;
      if (!user) {
        user = await MarconipyApi.getUser();
      }
      return { user: user };
    }
  } catch (e) {
    throw e;
  }
}
