import { useAuth } from "@/contexts/AuthenticationContext";
import Button from "./styled/Button";

const PublicSignupCTACard = ({
  onAuthRedirect,
}: {
  onAuthRedirect?: () => void;
}) => {
  const { isAuth } = useAuth();

  if (isAuth) {
    return null;
  }
  return (
    <div className="border rounded-lg p-4 flex flex-col gap-6 bg-tertiary dark:bg-black-deep">
      <h2>Tailor is a personal assistant for the news</h2>
      <div className="flex flex-col md:flex-row gap-4 md:gap-2">
        <div className="flex flex-col gap-4">
          <p>
            Supercharge your news understanding with Tailor!
            <br />
            Tailor uses A.I. to summarize, fact-check, and link thousands of
            articles, podcasts, and YouTube videos. Get digestible, unbiased
            news stories and podcasts created just for you! All of this, for
            free.
          </p>
          <p className="font-bold flex flex-row gap-2">
            Not convinced? Watch the video{" "}
            <span className="hidden md:block">👉</span>
            <span className="md:hidden">👇</span>
          </p>
        </div>
        <iframe
          src="https://www.youtube.com/embed/McqS7L9ayF0?si=VQUjjSiFBTXxCUP1"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
      <Button variant="primary" onClick={onAuthRedirect} title="Sign up">
        Sign up for free
      </Button>
    </div>
  );
};

export default PublicSignupCTACard;
