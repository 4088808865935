import { RiFlag2Fill, RiInformationLine } from "react-icons/ri";
import { FeedInfoModal } from "./FeedInfoModal";
import { Story } from "utils/types";
import { ReportModal } from "./ReportModal";
import { useState } from "react";
import Menu from "./styled/Menu";

type StoryResidualMenuProps = {
  story: Story;
  debug?: Record<string, any>;
};

export const StoryResidualMenu = ({ story, debug }: StoryResidualMenuProps) => {
  const [showFeedInfoModal, setShowFeedInfoModal] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);

  const items = [
    {
      label: "Report",
      icon: <RiFlag2Fill />,
      onClick: () => setShowReportModal(true),
    },
  ];

  if (debug && debug.interests) {
    items.splice(0, 0, {
      label: "Why am I seeing this?",
      icon: <RiInformationLine />,
      onClick: () => setShowFeedInfoModal(true),
    });
  }

  return (
    <>
      <Menu items={items} />
      {showFeedInfoModal && (
        <FeedInfoModal
          isOpen={showFeedInfoModal}
          setOpen={setShowFeedInfoModal}
          debug={debug}
        />
      )}
      {showReportModal && (
        <ReportModal
          isOpen={showReportModal}
          setOpen={setShowReportModal}
          story_uuid={story.uuid}
        />
      )}
    </>
  );
};
