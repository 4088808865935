import { useEffect, useState } from "react";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import Divider from "@/components/styled/Divider";
import Card from "@/components/styled/Card";
import SubchapterTitle from "@/components/SubchapterTitle";
import OnboardingTopicsStep from "./Onboarding/OnboardingTopicsStep";
import SourceSetMosaicView from "./partials/SourceSetMosaicView";
import SelectSourceSetsPartialView from "./partials/SelectSourceSetsPartialView";
import MarconipyApi from "@/utils/marconipyApi";
import Textarea from "@/components/styled/Textarea";
import { CustomizationPrompt } from "@/utils/types";
import Button from "@/components/styled/Button";
import {
  FeatureFlags,
  useFeatureFlagsContext,
} from "@/contexts/FeatureFlagsContext";

export default function EditYourInterestsView() {
  const { contentset, reloadContentSet } = useContentSetContext();
  const [advancedPublicationSettings, setAdvancedPublicationSettings] =
    useState(false);
  const [customizationPrompt, setCustomizationPrompt] = useState("");
  const [hasPromptChanged, setHasPromptChanged] = useState(false);
  const { isFeatureEnabled } = useFeatureFlagsContext();
  const customizationPromptsActive = isFeatureEnabled(
    FeatureFlags.CUSTOMIZATION_PROMPTS
  );

  const switchPublicationSettings = () => {
    setAdvancedPublicationSettings(!advancedPublicationSettings);
  };

  const onTopicsUpdated = () => {
    if (!contentset) {
      return;
    }
    MarconipyApi.generateContentSet(contentset.uuid).then(() => {
      reloadContentSet();
    });
  };

  const onPromptUpdated = () => {
    MarconipyApi.updateCustomizationPrompt(customizationPrompt);
    setHasPromptChanged(false);
  };

  useEffect(() => {
    const loadPrompt = async () => {
      let prompt_obj =
        (await MarconipyApi.getCustomizationPrompt()) as any as CustomizationPrompt | null;
      if (prompt_obj) {
        setCustomizationPrompt(prompt_obj.prompt);
      }
    };
    loadPrompt();
  }, []);

  const isOnboarding = contentset && contentset.draft;

  return (
    <div className="overflow-y-auto col-span-12 grid grid-cols-12 px-4 gap-4">
      <div className="col-span-12 sm:col-span-12 md:col-start-3 md:col-span-8 lg:col-start-4 lg:col-span-6 mb-80 mt-4">
        <Card className="flex flex-col gap-4">
          <h1>Edit your interests</h1>
          {!isOnboarding && (
            <>
              {customizationPromptsActive && (
                <>
                  <SubchapterTitle title="Custom instructions" />
                  <p>
                    What would you like Tailor to know about you to provide you
                    better insights from the news?
                  </p>
                  <Textarea
                    value={customizationPrompt}
                    onValueChange={(value) => {
                      setCustomizationPrompt(value);
                      setHasPromptChanged(true);
                    }}
                    maxLength={2000}
                  />
                  <Button
                    onClick={onPromptUpdated}
                    disabled={!hasPromptChanged}
                  >
                    Save changes
                  </Button>
                  <Divider />
                </>
              )}
              <SubchapterTitle title="Topics you follow" />
              <OnboardingTopicsStep
                nextStep={onTopicsUpdated}
                buttonLabel="Save changes"
                showConfirmation
                buttonVariant="secondary"
              />
              <Divider />
              <SubchapterTitle title="Publications you follow" />
              <div className="flex flex-col">
                {!advancedPublicationSettings && (
                  <SourceSetMosaicView
                    sourcesets={contentset?.sourcesets ?? []}
                    advancedModeAction={switchPublicationSettings}
                    advancedModeCTA="Add or remove"
                    showHost={false}
                  />
                )}
                {advancedPublicationSettings && (
                  <SelectSourceSetsPartialView isOnboarding={false} />
                )}
              </div>
            </>
          )}
        </Card>
      </div>
    </div>
  );
}

export async function loader({}: { params: any }) {
  return null;
}
