import { SourceItem } from "utils/types";
import {
  RiSpeakerFill,
  RiNewspaperLine,
  RiYoutubeFill,
  RiTwitterFill,
  RiQuestionMark,
  RiRedditFill,
} from "react-icons/ri";
import { TextFrontend } from "@/utils/Text";
import SourceSetImage from "./SourceSetImage";

type SourceItemRowItemProps = {
  sourceitem: SourceItem;
  index?: number;
};

const SourceItemRowItem = (props: SourceItemRowItemProps) => {
  const fromSourceSetTypeToIcon = (type: string) => {
    if (type === "text") {
      return <RiNewspaperLine />;
    } else if (type === "podcast") {
      return <RiSpeakerFill />;
    } else if (type === "youtube") {
      return <RiYoutubeFill />;
    } else if (type === "twitter") {
      return <RiTwitterFill />;
    } else if (type == "reddit") {
      return <RiRedditFill />;
    } else {
      return <RiQuestionMark />;
    }
  };

  return (
    <a
      href={props.sourceitem.link}
      target="_blank"
      rel="noreferrer"
      className="flex flex-col rounded-lg bg-white dark:bg-black-deep border border-gray-200 h-full flex-shrink-0 w-60"
    >
      <div className="w-full h-20 relative bg-tertiary dark:bg-gray-900 rounded-t-lg">
        <div className="absolute top-2 left-2 h-6 w-6 flex flex-shrink-0 items-center justify-center bg-primary-light dark:bg-gray-300 dark:text-black rounded-full text-xs">
          {props.index
            ? props.index
            : fromSourceSetTypeToIcon(props.sourceitem.type)}
        </div>
        {props.sourceitem.image_url && (
          <img
            src={props.sourceitem.image_url}
            className="w-full h-full object-cover object-center rounded-t-lg"
          />
        )}
      </div>
      <div className="text-sm md:text-xs flex flex-col gap-2 overflow-hidden justify-between p-2 h-full">
        <p className="font-semibold text-xs p-0">{props.sourceitem.title}</p>
        <div className="flex flex-row items-left gap-2">
          <SourceSetImage
            image={props.sourceitem.sourceset?.image ?? ""}
            size="sm"
          />
          <div className="text-xs">
            <span className="underline hover:no-underline">
              {TextFrontend.humanUrl(props.sourceitem.link)}
            </span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default SourceItemRowItem;
