import { useState, useCallback, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { SectionRenderer } from "@/components/SectionRenderer";
import { FeedSectionType, FeedAPIResponse } from "@/utils/types";
import SwitchToAppPanel from "@/components/SwitchToAppPanel";
import classnames from "classnames";
import {
  RiHistoryFill,
  RiArrowDownLine,
  RiFile4Line,
  RiLoader2Line,
  RiSparklingLine,
  RiStackLine,
  RiThumbUpLine,
} from "react-icons/ri";
import { SmallPlayer } from "@/components/AudioPlayer";
import FullWidthFeedSection from "@/components/styled/FullWidthFeedSection";
import Modal from "@/components/styled/Modal";
import StoryDetailView from "./StoryDetailView";
import useFeed from "@/hooks/useFeed";
import GeneratingView from "../GeneratingView";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import FeedSection from "@/components/styled/FeedSection";
import SelectMenu, { SelectOption } from "@/components/styled/SelectMenu";
import { Loading } from "@/components/Loading";

type FeedPartialViewProps = {
  feed: FeedAPIResponse;
  canRequestNewContent: boolean;
  canLoadNewContent: boolean;
  canShowGeneratingView: boolean;
  disableScroll: boolean;
  advancedFeedOptions: boolean;
  defaultFeedType?: string;
  hideShareButton?: boolean;
  onAuthRedirect?: () => void;
  footer?: React.ReactNode;
  // autoupdateCustomizations?: boolean;
};

export function FeedPartialView({
  feed,
  canRequestNewContent = true,
  canLoadNewContent = true,
  disableScroll = false,
  canShowGeneratingView = false,
  advancedFeedOptions = false,
  defaultFeedType = "new",
  onAuthRedirect = () => {},
  // autoupdateCustomizations = false,
  footer,
}: FeedPartialViewProps) {
  const [sectionDetail, setSectionDetal] = useState<FeedSectionType | null>(
    null
  );
  const { contentset } = useContentSetContext();

  const shouldShowGeneratingView =
    canShowGeneratingView &&
    contentset &&
    contentset.status != "published" &&
    contentset.status != "published_and_generating";
  const {
    cardRefs,
    containerRef,
    sections,
    refreshFeed,
    isGenerating,
    isLoading: feedIsLoading,
    newStoriesNotFound,
  } = useFeed(feed, {
    canLoadNewContent: canLoadNewContent && !shouldShowGeneratingView,
    canRequestNewContent,
    refreshOnScroll: true,
    engagementTrackingType: sectionDetail ? "detail" : "feed",
    // autoupdateCustomizations,
  });
  const [openReadMoreModal, setOpenReadMoreModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const feedTypeOptions = useMemo(
    () => [
      {
        label: "History",
        value: "history",
        icon: <RiHistoryFill />,
      },
      {
        label: "Liked",
        value: "liked",
        icon: <RiThumbUpLine />,
      },
      {
        label: "New",
        value: "new",
        icon: <RiSparklingLine />,
      },
    ],
    []
  );

  const topicOptions = useMemo(() => {
    let options = [
      {
        label: "All interests",
        value: "all",
        icon: <RiStackLine />,
      },
    ];
    if (contentset && contentset.topics) {
      options = options.concat(
        contentset.topics.map((topic) => ({
          label: topic.name,
          value: topic.uuid,
          icon: <RiFile4Line />,
        }))
      );
    }
    return options;
  }, [contentset]);
  const [feedType, setFeedType] = useState<SelectOption>(
    defaultFeedType == "new"
      ? {
          label: "New stories",
          value: "new",
          icon: <RiSparklingLine />,
        }
      : feedTypeOptions.find((option) => option.value == defaultFeedType) ??
          feedTypeOptions[0]
  );
  const [topicFilter, setTopicFilter] = useState<SelectOption>(topicOptions[0]);

  const onReadMore = useCallback(
    async (uuid: string) => {
      const section = sections.find((section) => section.uuid == uuid) ?? null;
      if (section && section.story) {
        setSectionDetal(section);
        setOpenReadMoreModal(true);
      }
    },
    [sections]
  );

  const onFeedTypeChanged = useCallback(
    (selection: SelectOption) => {
      setIsLoading(true);
      setFeedType(selection);
      refreshFeed(true, selection.value).then(() => {
        setIsLoading(false);
      });
    },
    [refreshFeed]
  );

  const onTopicFilterChanged = useCallback(
    (selection: SelectOption) => {
      setIsLoading(true);
      setTopicFilter(selection);
      refreshFeed(true, null, selection.value).then(() => {
        setIsLoading(false);
      });
    },
    [refreshFeed]
  );

  if (isLoading || feedIsLoading) {
    return <Loading />;
  }

  return (
    <>
      {contentset && contentset.draft && <Navigate to="/new" />}
      <SwitchToAppPanel />
      <div
        ref={containerRef}
        className={classnames(
          "relative col-span-12 grid grid-cols-12 pb-80 dark:[color-scheme:dark]" +
            (!disableScroll ? " overflow-y-scroll" : "")
        )}
      >
        {shouldShowGeneratingView && <GeneratingView />}
        {advancedFeedOptions && (
          <>
            <FeedSection className="pt-4 flex gap-2">
              <SelectMenu
                options={feedTypeOptions}
                onSelect={onFeedTypeChanged}
                value={feedType}
              />
              <SelectMenu
                options={topicOptions}
                onSelect={onTopicFilterChanged}
                value={topicFilter}
              />
            </FeedSection>
          </>
        )}
        {sections.map((section: FeedSectionType, index: number) => {
          return (
            <div
              className="col-span-12 grid grid-cols-12 py-4 lg:px-0"
              key={index}
            >
              <SectionRenderer
                ref={(el) => (cardRefs.current[index] = el)}
                section={section}
                index={index}
                onReadMore={onReadMore}
                className="pb-2"
                onAuthRedirect={onAuthRedirect}
              />
            </div>
          );
        })}
        {footer && <FeedSection>{footer}</FeedSection>}
        {canLoadNewContent && (
          <FullWidthFeedSection
            key="footer"
            className="m-0 p-0 flex items-center justify-center"
          >
            {newStoriesNotFound && !isGenerating && (
              <>
                <small>No other stories for now!</small>
              </>
            )}
            {canRequestNewContent && isGenerating && (
              <small className="flex gap-2 items-center">
                <RiLoader2Line className="animate-spin" />
                Researching new stories...
              </small>
            )}
            {!newStoriesNotFound && !isGenerating && (
              <small className="flex gap-2 items-center">
                <RiArrowDownLine />
                Scroll down to get more stories
              </small>
            )}
          </FullWidthFeedSection>
        )}
      </div>
      <div className="absolute bottom-0 inset-x-0 z-10 hidden md:grid grid-cols-12 -ml-4">
        <div className="col-span-8 col-start-3">
          <SmallPlayer
            showTitle
            className="bg-white dark:bg-black border-t border-l border-r border-gray-200 p-2 mb-4 rounded-full shadow-lg"
          />
        </div>
      </div>
      <Modal
        open={openReadMoreModal}
        onClose={() => {
          setOpenReadMoreModal(false);
        }}
        size="xl"
        className="lg:ml-40"
        absoluteChildren={
          <div className="absolute bottom-0 inset-x-0 z-10 hidden md:grid grid-cols-12 ml-36">
            <div className="col-span-8 col-start-3">
              <SmallPlayer
                showTitle
                className="bg-white dark:bg-black border-t border-l border-r border-gray-200 p-2 mb-4 rounded-full shadow-lg"
              />
            </div>
          </div>
        }
      >
        <Modal.Body>
          {sectionDetail && sectionDetail.story && (
            <StoryDetailView
              story={sectionDetail.story}
              debug={sectionDetail.debug}
              onAuthRedirect={onAuthRedirect}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
