import { usePostHog } from "posthog-js/react";
import Button from "./styled/Button";
import { RiThumbUpLine, RiThumbDownLine } from "react-icons/ri";
import { TextFrontend } from "@/utils/Text";

type LikeButtonProps = {
  canLike: boolean;
  liked: boolean;
  likesCount: number;
  onClick: () => void;
  isDisliked?: boolean;
};

export const LikeButton = (props: LikeButtonProps) => {
  const posthog = usePostHog();

  const trackLike = async () => {
    posthog?.capture(props.liked ? actionLabel()[1] : actionLabel()[0], {
      trigger: "userquery_like",
    });
    props.onClick();
  };

  const actionLabel = () => {
    if (!props.isDisliked) {
      return ["Like", "Remove like"];
    } else {
      return ["Dislike", "Remove dislike"];
    }
  };

  return (
    <>
      {props.canLike && (
        <>
          <Button
            variant={props.liked ? "primary" : "secondary"}
            onClick={trackLike}
            icon={!props.isDisliked ? <RiThumbUpLine /> : <RiThumbDownLine />}
            title={props.liked ? actionLabel()[1] : actionLabel()[0]}
            aria-label={props.liked ? actionLabel()[1] : actionLabel()[0]}
            role="button"
          >
            {props.likesCount > 1 &&
              !props.isDisliked &&
              TextFrontend.formatNumber(props.likesCount)}
          </Button>
        </>
      )}
    </>
  );
};
