import { Helmet } from "react-helmet";

import Container from "@/components/styled/Container";
import Logo from "@/components/styled/Logo";

export default function MaintenanceView() {
  return (
    <>
      <Helmet>
        <title>{"We're under maintenance"}</title>
      </Helmet>
      <Container className="text-center items-center h-screen dark:bg-black dark:text-white justify-center">
        <div className="w-20 h-20">
          <Logo variant="icon" className="rounded-lg" />
        </div>
        <h1>{`We're currently under scheduled maintenance`}</h1>
        <h2>
          {`We're undergoing scheduled maintenance to migrate our cloud infrastructure. We appreciate your patience. We'll be back on Monday!`}
        </h2>
      </Container>
    </>
  );
}
