import { FC } from "react";
import {
  RiNewspaperLine,
  RiHeadphoneLine,
  RiYoutubeFill,
  RiTwitterFill,
  RiQuestionMark,
  RiMastodonFill,
  RiLinksFill,
  RiQuestionLine,
  RiSearchLine,
} from "react-icons/ri";

export class IconsUtils {
  static fromSourceSetTypeToIcon(type: string): FC {
    switch (type) {
      case "text":
        return RiNewspaperLine;
      case "podcast":
        return RiHeadphoneLine;
      case "youtube":
        return RiYoutubeFill;
      case "twitter":
        return RiTwitterFill;
      case "mastodon":
        return RiMastodonFill;
      default:
        return RiQuestionMark;
    }
  }
  static fromUserQueryTypeToIcon(type: string): FC {
    switch (type) {
      case "url":
        return RiLinksFill;
      case "search":
        return RiSearchLine;
      case "question":
        return RiQuestionLine;
      default:
        return RiQuestionLine;
    }
  }
}
