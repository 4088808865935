import { Ref, forwardRef } from "react";
import { NavLink } from "react-router-dom";
import Button, { ButtonProps } from "@/components/styled/Button";
import classnames from "classnames";
import classNames from "classnames";

export const NavigationButton = forwardRef(function NavigationButton(
  { children, ...props }: ButtonProps & { to: string },
  ref: Ref<HTMLButtonElement>
) {
  return (
    <NavLink
      to={props.to ?? ""}
      className={({ isActive }) =>
        classnames(
          "flex items-center",
          {
            "bg-primary-light dark:bg-gray-900 dark:text-black": isActive,
            "border-transparent hover:bg-primary-light dark:hover:bg-gray-900":
              !isActive,
          },
          props.className
        )
      }
    >
      <Button
        ref={ref}
        {...props}
        $fullwidth
        className={classNames(
          {
            "bg-transparent dark:bg-transparent rounded-none":
              props.variant != "primary",
          },
          "rounded-lg"
        )}
      >
        {children}
      </Button>
    </NavLink>
  );
});

export const MobileNavigationButton = forwardRef(
  function MobileNavigationButton(
    { children, ...props }: ButtonProps & { to: string },
    ref: Ref<HTMLButtonElement>
  ) {
    return (
      <NavLink
        to={props.to ?? ""}
        className={({ isActive }) =>
          classnames("border-l-4 flex items-center w-full rounded-none", {
            "border-primary bg-primary-light": isActive,
            "border-transparent hover:bg-primary-light active:bg-primary-light":
              !isActive,
          })
        }
      >
        <Button
          ref={ref}
          {...props}
          className={props.variant != "primary" ? "bg-transparent" : ""}
          $fullwidth
        >
          {children}
        </Button>
      </NavLink>
    );
  }
);
