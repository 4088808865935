import { Suggestion } from "@/utils/types";
import { RiArrowRightSLine } from "react-icons/ri";

type SearchResultProps<T extends Suggestion> = {
  value: T;
  icon: any | null;
  callback?: (value: T) => void;
};

const SearchResult = <T extends Suggestion>(props: SearchResultProps<T>) => {
  const callback = () => {
    if (props.callback) {
      props.callback(props.value as T);
    }
  };

  return (
    <div
      onClick={callback}
      className="flex w-full justify-between items-center cursor-pointer hover:bg-primary-light hover:dark:bg-black-light px-2 py-2 rounded-sm"
    >
      <div className="flex gap-2 items-center overflow-hidden">
        <div className="w-4 h-4">{props.icon && props.icon}</div>
        <span className="truncate">{props.value.name}</span>
      </div>
      <div className="w-4 h-4">
        <RiArrowRightSLine />
      </div>
    </div>
  );
};

export default SearchResult;
