import { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLoaderData } from "react-router-dom";

import { useContentSetContext } from "@/contexts/ContentSetContext";
import { FeedPartialView } from "./partials/FeedPartialView";
import MarconipyApi from "@/utils/marconipyApi";
import { redirect } from "react-router-dom";
import { FeedSectionType, UserQuery } from "@/utils/types";
import FeedSection from "@/components/styled/FeedSection";
import InputWithButton from "@/components/styled/InputWithButton";
import classNames from "classnames";
import { useFeatureFlagPayload, usePostHog } from "posthog-js/react";
import { UserQueryMessageCard } from "@/components/UserQueryMessageCard";
import { QueryFeaturedBox } from "@/components/QueryFeaturedBox";
import SubchapterTitle from "@/components/SubchapterTitle";
import { RiLoader4Fill } from "react-icons/ri";
import { IconsUtils } from "@/utils/Icons";
import SearchResultUserQuerySuggestion from "@/components/SearchResultUserQuerySuggestion";
import {
  FeatureFlags,
  useFeatureFlagsContext,
} from "@/contexts/FeatureFlagsContext";

export default function SearchView() {
  const data = useLoaderData() as any;
  const { contentset } = useContentSetContext();
  const [sections, setSections] = useState<FeedSectionType[]>([]);
  const posthog = usePostHog();
  const [userquery, setUserquery] = useState<UserQuery | null>(null);
  const [query, setQuery] = useState(data.q || "");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const intervalRef = useRef<number | null>(null);
  const { isFeatureEnabled } = useFeatureFlagsContext();
  const flagEnabled = isFeatureEnabled(FeatureFlags.ASK_QUICK_REPLIES);
  const featuredQueries = useFeatureFlagPayload(
    FeatureFlags.ASK_QUICK_REPLIES
  ) as string[];

  const onSearch = useCallback(
    async (searched_query: string) => {
      if (!searched_query || searched_query == "") {
        window.history.pushState({}, "", "/search");
      } else {
        window.history.pushState(
          {},
          "",
          `/search?q=${encodeURIComponent(searched_query)}`
        );
      }
      setQuery(searched_query);
      if (searched_query == "") {
        setSections([]);
        setSuggestions([]);
        return;
      }
      setSuggestions([]);
      setLoading(true);
      const newuserquery = (await MarconipyApi.addQuery(
        searched_query
      )) as any as UserQuery;
      setUserquery(newuserquery);
      setLoading(false);

      posthog?.capture("search", {
        query: searched_query,
      });

      setSections(newuserquery.sections);
    },
    [posthog]
  );

  const searchAutocomplete = async function (squery: string) {
    if (userquery && squery != userquery?.query) {
      setUserquery(null);
    }
    setQuery(squery);
    if (squery.length < 3) {
      setSuggestions([]);
      return;
    }
    if (userquery != null && userquery.query == squery) {
      setSuggestions([]);
      return;
    }
    let results = (await MarconipyApi.searchUserQueryAutocomplete(
      squery
    )) as any as UserQuery[];
    setSuggestions(results);
  };

  const clickedOnAutocomplete = async function (input: string) {
    setQuery(input);
    onSearch(input);
  };

  useEffect(() => {
    const updateUserQuery = async () => {
      if (!userquery || !userquery.uuid) {
        return;
      }
      setLoading(true);
      const newuserquery = (await MarconipyApi.getQuery(
        userquery.uuid
      )) as any as UserQuery;
      setUserquery(newuserquery);
      setSections(newuserquery.sections);
      setLoading(false);
      if (newuserquery) {
        setSuggestions([]);
      }
      if (newuserquery.status == "completed") {
        if (intervalRef.current != null) {
          clearInterval(intervalRef.current);
        }
      }
    };
    if (userquery && userquery.query && userquery.status == "completed") {
      return;
    }
    intervalRef.current = setInterval(updateUserQuery, 5000);

    return () => {
      if (intervalRef.current != null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [userquery]);

  useEffect(() => {
    if (data.q) {
      setQuery(data.q);
      onSearch(data.q);
    }
  }, [data.q, onSearch]);

  const onCancelQuery = useCallback(() => {
    setSections([]);
    setQuery("");
    if (intervalRef.current != null) {
      clearInterval(intervalRef.current);
    }
    window.history.pushState({}, "", "/search");
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {!query || query == ""
            ? "Ask Tailor any question about what is happening in the world"
            : query + " - Ask Tailor"}
        </title>
      </Helmet>
      <div
        className={classNames(
          "col-span-12 grid grid-cols-12 lg:px-0 overflow-y-scroll",
          {
            "mt-12": sections.length == 0,
          }
        )}
      >
        <FeedSection key={`label`} className="mt-4 h-auto flex flex-col gap-4">
          <InputWithButton
            onSubmit={onSearch}
            onChange={searchAutocomplete}
            onCancel={onCancelQuery}
            placeholder={"Search something, ask a question or paste a link"}
            buttonText={"Ask"}
            autofocus={true}
            startingValue={query}
          />
          {suggestions && suggestions.length > 0 && (
            <div className="flex flex-col mb-2">
              {suggestions?.map((userquerysuggestion) => {
                const Icon = IconsUtils.fromUserQueryTypeToIcon(
                  userquerysuggestion.type
                ); // get the icon component
                return (
                  <SearchResultUserQuerySuggestion
                    key={userquerysuggestion.query}
                    query={userquerysuggestion.query}
                    icon={<Icon />}
                    callback={clickedOnAutocomplete}
                  />
                );
              })}
            </div>
          )}
          {userquery && userquery.query == query && (
            <UserQueryMessageCard userquery={userquery} />
          )}
          {userquery &&
            sections.length > 0 &&
            userquery.status == "completed" && (
              <div className="transition-all align-middle -mb-6 mt-8">
                <SubchapterTitle title="Related stories:" />
              </div>
            )}
          {(query == null ||
            (query == "" && !!featuredQueries && flagEnabled)) && (
            <div className="w-full mt-8">
              <SubchapterTitle title="Popular" />
              <div className="flex flex-wrap gap-2 mt-2">
                {featuredQueries.map((fquery) => (
                  <QueryFeaturedBox
                    key={fquery}
                    click={function () {
                      setQuery(fquery);
                      onSearch(fquery);
                      posthog?.capture("featured query click", {
                        query: fquery,
                      });
                    }}
                    text={fquery}
                  />
                ))}
              </div>
            </div>
          )}
          {!userquery && loading && (
            <p>
              <RiLoader4Fill className="animate-spin text-3xl" />
            </p>
          )}
        </FeedSection>
        {sections.length > 0 &&
          contentset &&
          userquery &&
          userquery.status == "completed" &&
          query == userquery.query && (
            <FeedPartialView
              feed={{
                sections,
              }}
              canLoadNewContent={false}
              canRequestNewContent={false}
              disableScroll={true}
              canShowGeneratingView={false}
              advancedFeedOptions={false}
            />
          )}
      </div>
    </>
  );
}

export async function loader({ request }: { request: Request }) {
  if (!MarconipyApi.isAuth()) {
    return redirect("/login");
  }
  const sparams = new URL(request.url).searchParams;
  return {
    q: sparams.get("q"),
  };
}
