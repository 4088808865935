import { useState, useRef, useEffect } from "react";
import { ContentSet } from "utils/types";
import MarconipyApi from "@/utils/marconipyApi";
import Button from "@/components/styled/Button";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import { useOnboardingContext } from "../OnboardingView";
import classnames from "classnames";

type OnboardingFrequencyStepProps = {
  nextStep?: () => void;
};

const OnboardingFrequencyStep = (props: OnboardingFrequencyStepProps) => {
  const { setHostTitle, setHostSubtitle } = useOnboardingContext();

  useEffect(() => {
    setHostTitle("When is the best time to read your news?");
    setHostSubtitle("");
  });

  return (
    <div className="flex flex-col gap-8 mt-8">
      <div className="mx-auto">
        <ChangeTimeOfDay variant="lg" />
      </div>
      {props.nextStep && (
        <Button onClick={() => props.nextStep?.()} variant="primary" $fullwidth>
          Continue
        </Button>
      )}
    </div>
  );
};

export const ChangeTimeOfDay = ({ variant }: { variant: "lg" | "sm" }) => {
  const { contentset, updateContentSetOptimistically } = useContentSetContext();
  const hourRef = useRef<HTMLSelectElement>(null);
  const minuteRef = useRef<HTMLSelectElement>(null);
  const ampmRef = useRef<HTMLSelectElement>(null);
  const [, setNeverEdited] = useState(true);

  const handleTimeOfDayClick = () => {
    let selectedHour = parseInt(hourRef.current?.value || "0");
    if (ampmRef.current?.value === "pm") {
      selectedHour += 12;
    }
    let selectedMinute = parseInt(minuteRef.current?.value || "0");
    setNeverEdited(false);
    updateContentSetOptimistically(
      {
        ...contentset,
        gen_time_of_the_day_hour: selectedHour,
        gen_time_of_the_day_minute: selectedMinute,
      } as ContentSet,
      async () => {
        await MarconipyApi.editContentSetChangeGenTimeOfDay(
          contentset?.uuid ?? "",
          selectedHour,
          selectedMinute
        );
      }
    );
  };

  useEffect(() => {
    // Load from cloud the time of the day and parse it. It is stored in the contentset.
    if (contentset) {
      let hour = contentset.gen_time_of_the_day_hour || 9;
      let minute = contentset.gen_time_of_the_day_minute || 0;
      let ampm = "am";

      if (hour >= 12) {
        ampm = "pm";
        if (hour > 12) {
          hour -= 12;
        }
      }

      if (hourRef.current) {
        hourRef.current.value = hour.toString();
      }

      if (minuteRef.current) {
        minuteRef.current.value = minute.toString();
      }

      if (ampmRef.current) {
        ampmRef.current.value = ampm;
      }
    }
  }, [contentset]);

  return (
    <div className="flex gap-2 border border-gray rounded-lg text-black dark:text-white">
      <select
        aria-label="At what hour?"
        ref={hourRef}
        onChange={handleTimeOfDayClick}
        className={classnames(
          "rounded-lg bg-transparent text-center outline-none ring-none",
          {
            "text-2xl p-2": variant === "lg",
            "text-base p-1": variant === "sm",
          }
        )}
      >
        {Array.from({ length: 12 }, (_, i) => (
          <option key={i} value={i + 1}>
            {i + 1}
          </option>
        ))}
      </select>
      <select
        aria-label="Minute selection"
        ref={minuteRef}
        onChange={handleTimeOfDayClick}
        className={classnames(
          "rounded-lg text-2xl bg-transparent text-center outline-none ring-none",
          {
            "text-2xl p-2": variant === "lg",
            "text-base p-1": variant === "sm",
          }
        )}
      >
        {Array.from({ length: 60 }, (_, i) => (
          <option
            key={i}
            label={i < 10 ? "0" + i.toString() : i.toString()}
            value={i}
          >
            {i + 1}
          </option>
        ))}
      </select>
      <select
        aria-label="PM"
        ref={ampmRef}
        onChange={handleTimeOfDayClick}
        className={classnames(
          "rounded-lg text-2xl bg-transparent text-center outline-none ring-none",
          {
            "text-2xl p-2": variant === "lg",
            "text-base p-1": variant === "sm",
          }
        )}
      >
        <option key={0} label="am" value={"am"}></option>
        <option key={1} label="pm" value={"pm"}></option>
      </select>
    </div>
  );
};

export default OnboardingFrequencyStep;
