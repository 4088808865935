import axios, { AxiosResponse } from "axios";
import { Topic, User } from "./types";
import { posthog } from "posthog-js";

interface ApiError {
  json: object;
  noConnection?: boolean;
}

class MarconipyApi {
  static updateRequired = false;
  static token = "";
  static user: User | null = null;
  static baseUrl = (() => {
    switch (import.meta.env.MODE) {
      case "production":
        return "https://bkn2.tailormedia.ai";
      case "staging":
        return "https://staging.tailormedia.ai";
      default:
        return "http://localhost:8000";
      // return "https://bkn.tailormedia.ai";
    }
  })();

  static headers: any = {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
  };

  static client = axios.create({
    baseURL: MarconipyApi.baseUrl,
    headers: MarconipyApi.headers,
  });

  static refreshClient() {
    MarconipyApi.client = axios.create({
      baseURL: MarconipyApi.baseUrl,
      headers: MarconipyApi.headers,
    });
  }

  constructor() {}

  static noConnectionError(): ApiError {
    return { json: { server: "down" }, noConnection: true };
  }

  static checkToInvalidateToken(error: any) {
    if (error && error.response && error.response.status === 401) {
      MarconipyApi.setToken("");
      MarconipyApi.saveToken();
      MarconipyApi.logout();
      return;
    }
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.detail
    ) {
      //create an ApiError object and throw it with the detail data
      throw { json: { detail: error.response.data.detail } };
    }
    if (error && error.code == "ERR_NETWORK") {
      throw error;
    }
  }

  static setToken(token: string) {
    MarconipyApi.token = token;

    if (token !== "") {
      MarconipyApi.headers = {
        Authorization: `Token ${MarconipyApi.token}`,
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
      };
    } else {
      MarconipyApi.headers = {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
      };
    }
    MarconipyApi.saveToken();
    MarconipyApi.refreshClient();
  }

  static saveToken() {
    // Add your token saving implementation here
    localStorage.setItem("token", MarconipyApi.token);
  }

  static loadToken() {
    // Add your token loading implementation here
    const token = localStorage.getItem("token");
    if (token) {
      MarconipyApi.setToken(token);
    }
  }

  static discardToken() {
    // Add your token discarding implementation here
    localStorage.removeItem("token");
    MarconipyApi.setToken("");
  }

  static isAuth() {
    if (MarconipyApi.token === "" || MarconipyApi.token === undefined) {
      this.loadToken();
      if (MarconipyApi.token === "" || MarconipyApi.token === undefined) {
        return false;
      }
    }
    return true;
  }

  static async signup(
    email: string,
    password1: string,
    password2: string,
    first_name: string
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      username: email,
      email,
      password1,
      password2,
      first_name: first_name,
    };

    try {
      const response = await MarconipyApi.client.post(
        "/dj-rest-auth/registration/",
        authObj
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async signupSocial(
    provider: string,
    code: string
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      code: code,
    };

    try {
      const response = await MarconipyApi.client.post(
        "/dj-rest-auth/" + provider + "/",
        authObj
      );
      MarconipyApi.setToken(response.data.token);
      return response;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  static async login(
    email: string,
    password: string
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      username: email,
      email,
      password,
    };

    try {
      const response = await MarconipyApi.client.post(
        "/dj-rest-auth/login/",
        authObj
      );
      MarconipyApi.setToken(response.data.token);
      return response;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      console.log("error logging in");
      throw error;
    }
  }

  static async logout(): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post("/dj-rest-auth/logout/");
      MarconipyApi.setToken("");
      posthog?.capture("logout");
      posthog.reset();
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async resetPassword(
    email: string
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      email,
    };
    try {
      const response = await MarconipyApi.client.post(
        "/u/send-code-email/",
        authObj
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async confirmCode(
    email: string,
    code: string
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      email,
      code,
    };

    try {
      const response = await MarconipyApi.client.post(
        "/u/send-code-email/conf/",
        authObj
      );
      return response;
    } catch (error) {
      console.log("error logging in");
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async sendNewPassword(
    email: string,
    password1: string,
    password2: string,
    code: string
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      email,
      password1,
      password2,
      code,
    };

    try {
      const response = await MarconipyApi.client.post(
        "/u/send-code-email/code/",
        authObj
      );
      return response;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getSocialProviderLoginURL(
    provider: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        "/u/loginsocial/" + provider + "/"
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getNotificationSettings(): Promise<
    AxiosResponse<any> | ApiError
  > {
    try {
      const response = await MarconipyApi.client.get(`/users/notifications/`);
      return response.data;
    } catch (error) {
      console.error("Error getting notifications:", error);
      throw error;
    }
  }

  static async changeNotificationSettings(
    vars: any = {}
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      let params = vars;
      const response = await MarconipyApi.client.post(
        `/users/notifications/`,
        params
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async putProfile(
    first_name: string = "",
    email: string = "",
    last_name: string = ""
  ): Promise<AxiosResponse<any> | ApiError> {
    let authObj: Record<string, any> = {};
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    authObj["timezone"] = tz;
    if (first_name !== "") {
      authObj["first_name"] = first_name;
    }
    if (email !== "") {
      authObj["email"] = email;
    }
    if (last_name !== "") {
      authObj["last_name"] = last_name;
    }

    try {
      const response = await MarconipyApi.client.patch(
        "/dj-rest-auth/user/",
        authObj
      );
      return response;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async putSignedUpWithCode(
    code: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(`/c/my/invitecode`, {
        invite_code: code,
      });
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getUser(): Promise<User | null> {
    try {
      const response = await MarconipyApi.client.get(`/users/status/`);
      if (response.status > 299) {
        return null;
      }
      MarconipyApi.user = response.data as User;
      return MarconipyApi.user;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getInviteLink(): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/c/my/invite_link`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async confirmEmail(
    key: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/dj-rest-auth/registration/verify-email/`,
        { key: key }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async resendConfirmationEmail(
    email: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/dj-rest-auth/registration/resend-email/`,
        { email: email }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getContentSets(): Promise<AxiosResponse<any> | ApiError> {
    try {
      let url = `/c/my/contentsets`;
      const response = await MarconipyApi.client.get(url);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getFeed(
    options: {
      feedType?: string;
      topicFilter?: string;
      paginationTimestamp?: number | null;
      excludeStoryUUIDs?: string[];
      audioOnly?: boolean;
      debugMode?: boolean;
    } = {}
  ): Promise<AxiosResponse<any> | ApiError> {
    const { paginationTimestamp, excludeStoryUUIDs, audioOnly } = options;
    try {
      const params: Record<string, string> = {};
      if (paginationTimestamp) {
        params["timestamp"] = paginationTimestamp.toString();
      }
      if (excludeStoryUUIDs && excludeStoryUUIDs.length > 0) {
        params["exclude_uuids"] = excludeStoryUUIDs.join(",");
      }
      if (audioOnly) {
        params["audio_only"] = "true";
      }
      if (options.feedType) {
        params["type"] = options.feedType;
      }
      if (options.topicFilter) {
        params["topic"] = options.topicFilter;
      }
      if (options.debugMode) {
        params["debug"] = JSON.stringify(options.debugMode);
      }
      const queryString = new URLSearchParams(params).toString();
      let url = `/c/my/feed`;
      if (queryString) {
        url += "?" + queryString;
      }
      const response = await MarconipyApi.client.get(url);
      return response.data;
    } catch (error: any) {
      MarconipyApi.checkToInvalidateToken(error);
      if (
        error.response &&
        error.response.status &&
        error.response.status == 429
      ) {
        //we've been throttled. return an empty response
        return { sections: [], contentset: {} } as any;
      }
      throw error;
    }
  }

  static async addQuery(
    query: string,
    story_uuid?: string,
    related_userquery_uuids?: string[]
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      let params: any = { query: query };
      if (story_uuid) {
        params.story_uuid = story_uuid;
      }
      if (related_userquery_uuids) {
        params.related_userquery_uuids = related_userquery_uuids;
      }
      const response = await MarconipyApi.client.post(`/c/my/queries`, params);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getQuery(uuid: string): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/c/my/queries/${uuid}`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getQueryMeta(
    uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/c/my/queries/${uuid}/meta`
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async updateQueryMeta(
    uuid: string,
    params: any
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/queries/${uuid}/meta`,
        params
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async searchUserQueryAutocomplete(
    query: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/queries/autocomplete`,
        { query: query }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getQueriesPerStory(
    uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/c/my/queries/per_story/${uuid}/`
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getFeaturedFeed(
    category: string | null,
    from: string | null = null,
    to: string | null = null,
    page: number = 1
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const params: Record<string, string> = {};
      if (from) {
        params["from"] = from;
      }
      if (to) {
        params["to"] = to;
      }
      if (category) {
        params["category"] = category;
      }
      if (page) {
        params["page"] = page.toString();
      }
      const queryString = new URLSearchParams(params).toString();
      let url = `/c/featured/feed`;
      if (queryString) {
        url += "?" + queryString;
      }
      const response = await MarconipyApi.client.get(url);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getFeaturedArchive(
    category: string | null = null
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const params: Record<string, string> = {};
      if (category) {
        params["category"] = category;
      }
      const queryString = new URLSearchParams(params).toString();
      let url = `/c/featured/archive`;
      if (queryString) {
        url += "?" + queryString;
      }
      const response = await MarconipyApi.client.get(url);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async requestNewContent(): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/feed/request_new_content`
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getContentSetByUUID(
    contentset_uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/c/my/contentsets/${contentset_uuid}/`
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async addContentSet(): Promise<AxiosResponse<any> | ApiError> {
    try {
      let params = {
        requested_timeoftheday_hour: 9,
        requested_timeoftheday_minute: 0,
        topic_ids: [],
        sourceset_ids: [],
      };
      const response = await MarconipyApi.client.post(
        `/c/my/contentsets/add`,
        params
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getHosts(): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/c/my/hosts`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async editContentSetAddHost(
    contentset_uuid: string,
    host_uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/contentsets/${contentset_uuid}/edit`,
        { host_uuid: host_uuid }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async editContentSetChangeGenTimeOfDay(
    contentset_uuid: string,
    gen_time_of_the_day_hour: number,
    gen_time_of_the_day_minute: number
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/contentsets/${contentset_uuid}/edit`,
        { gen_time_of_the_day_hour, gen_time_of_the_day_minute }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getTopicsFeatured(): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/c/my/topics/featured`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getTopic(uuid: string): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/c/my/topics/${uuid}`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async updateTopics(
    contentset_uuid: string,
    params: {
      uuids: string[];
      is_onboarding?: boolean;
    }
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/contentsets/${contentset_uuid}/topics/update`,
        params
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async searchTopic(query: string): Promise<Topic | ApiError> {
    try {
      const response = await MarconipyApi.client.post(`/c/my/topics/search`, {
        query: query,
      });
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async searchTopicAutocomplete(
    query: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/topics/search_autocomplete`,
        { query: query }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getSpecialRequestsFeatured(): Promise<
    AxiosResponse<any> | ApiError
  > {
    try {
      const response = await MarconipyApi.client.get(
        `/c/my/specialrequests/featured`
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async addSpecialRequest(
    contentset_uuid: string,
    specialrequest_uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/contentsets/${contentset_uuid}/specialrequests/add`,
        { uuid: specialrequest_uuid }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async removeSpecialRequest(
    contentset_uuid: string,
    sr_uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/contentsets/${contentset_uuid}/specialrequests/${sr_uuid}/delete`
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async searchSpecialRequest(
    query: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/specialrequests/search`,
        { query: query }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async searchSpecialRequestAutocomplete(
    query: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/specialrequests/search_autocomplete`,
        { query: query }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async addSourceSet(
    contentset_uuid: string,
    params: {
      uuid?: string;
      is_onboarding?: boolean;
    }
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/contentsets/${contentset_uuid}/sourcesets/add`,
        params
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async removeSourceSet(
    contentset_uuid: string,
    params: {
      uuid: string;
      is_onboarding?: boolean;
    }
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/contentsets/${contentset_uuid}/sourcesets/${params.uuid}/delete`,
        params
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async searchSourceSet(
    query: string,
    force: boolean = false,
    limit: number = 5
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/sourcesets/search`,
        { query: query, force: force, limit: limit }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getSourceSet(
    uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/c/my/sourcesets/${uuid}/`
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getSourceItem(
    uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/c/my/sourceitems/${uuid}/`
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async searchSourceSetAutocomplete(
    query: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/sourcesets/search_autocomplete`,
        { query: query }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async sourceSetSearchByLink(
    url: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/sourcesets/search_by_link`,
        { url: url }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async generateContentSet(
    contentset_uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/contentsets/${contentset_uuid}/generate`
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async updateStoryMeta(
    story_uuid: string,
    listened_to: number
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/stories/${story_uuid}/meta`,
        { listened_to_timestamp: listened_to }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async requestStoryOnDemandAudio(
    story_uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/stories/${story_uuid}/on_demand_audio`
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async requestBatchStoriesOnDemandAudio(
    story_uuids: string[]
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/stories/on_demand_audio`,
        { uuids: story_uuids }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async skipWaitlist(
    keyword: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      let params: any = { keyword };
      const response = await MarconipyApi.client.post(
        `/users/skip_waitlist/`,
        params
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getStoriesBatch(
    story_uuids: string[]
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(`/c/my/stories`, {
        uuids: story_uuids,
      });
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getStory(uuid: string): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/c/my/stories/${uuid}/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getDigest(uuid: string): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/c/my/digests/${uuid}/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getSourceItemStoryMeta(
    uuid: string,
    extraFields: string[] = []
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      let params = {};
      if (extraFields.length > 0) {
        params = { fields: extraFields.join(",") };
      }
      const response = await MarconipyApi.client.get(
        `/c/my/sourceitemstorymeta/${uuid}/`,
        { params }
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async trackStoryEvent(
    uuid: string,
    event: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/stories/${uuid}/track`,
        {
          event: event,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async trackStoryEventPublic(
    uuid: string,
    event: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/stories/${uuid}/track_public`,
        {
          event: event,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getRecommendedTopics(
    contentSetUUID: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/c/my/contentsets/` + contentSetUUID + `/topics/recommended`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createReport(
    story_uuid: string,
    category: string,
    comment?: string | null,
    metadata?: any
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(`/c/my/reports/add`, {
        story_uuid: story_uuid,
        category: category,
        metadata: metadata,
        comment: comment,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async createFeedback(
    feedback: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(`/c/my/feedbacks/add`, {
        feedback,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getCustomizationPrompt(): Promise<
    AxiosResponse<any> | ApiError
  > {
    try {
      const response = await MarconipyApi.client.get(`/c/my/prompts/current`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async updateCustomizationPrompt(
    newPrompt: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/prompts/current/update`,
        {
          prompt: newPrompt,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async customizeStory(
    story_uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/c/my/stories/${story_uuid}/customization/new`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async getStoryCustomization(
    story_uuid: string
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/c/my/stories/${story_uuid}/customization`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default MarconipyApi;
