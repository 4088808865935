import React, { useEffect } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import { Helmet } from "react-helmet";
import {
  redirect,
  useLoaderData,
  Await,
  defer,
  useAsyncError,
} from "react-router-dom";
import { Digest } from "@/utils/types";
import SharedDigestView from "./partials/SharedDigestView";
import { Loading } from "@/components/Loading";
import * as Sentry from "@sentry/react";
import Card from "@/components/styled/Card";
import Button from "@/components/styled/Button";

type userDataResponse = {
  digest: Digest;
};

export default function DigestView() {
  const data = useLoaderData() as userDataResponse;

  return (
    <>
      <Helmet>
        <title>Your news, reimagined - Tailor - Loading</title>
      </Helmet>
      <React.Suspense fallback={<Loading />}>
        <Await resolve={data.digest} errorElement={<StoryViewError />}>
          {(loadedDigest) => <SharedDigestView digest={loadedDigest} />}
        </Await>
      </React.Suspense>
    </>
  );
}

function StoryViewError() {
  const error = useAsyncError() as any;
  useEffect(() => {
    Sentry.captureException(error);
    console.error(error);
  }, [error]);
  return (
    <div className="pt-6 px-4 col-span-12 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 xl:col-start-4 xl:col-span-6 flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row gap-4 sm:justify-between">
          <Card className="flex gap-4 flex-col">
            <h1>{`${
              error.response && error.response.status == 404
                ? "Ouch! We could not find the story you are looking for"
                : "Woops, there was an error"
            }`}</h1>
            <p>This should not happen.</p>
            <Button
              variant="primary"
              onClick={() => {
                window.location.href = "/";
              }}
            >
              Go back to Home
            </Button>
          </Card>
        </div>
      </div>
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  try {
    if (params.digestId) {
      if (!MarconipyApi.isAuth()) {
        let digest = MarconipyApi.getDigest(
          params.digestId
        ) as Promise<any> as Promise<Digest>;
        return defer({ digest });
      } else {
        let digest = MarconipyApi.getDigest(
          params.digestId
        ) as Promise<any> as Promise<Digest>;
        return defer({ digest });
      }
    } else {
      return redirect("/");
    }
  } catch (e: any) {
    if (e.json && e.json.detail && e.json.detail.includes("Banned")) {
      return redirect("/banned");
    } else if (
      e["json"] &&
      e["json"]["detail"] &&
      e["json"]["detail"] == "waitlisted"
    ) {
      console.log("Waitlist!");
      let story = await MarconipyApi.getDigest(params.digestId);
      return { story };
    } else {
      return redirect("/", 302);
    }
  }
}
