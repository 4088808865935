import { useState, useEffect } from "react";

import { SourceSet } from "@/utils/types";
import Button from "@/components/styled/Button";
import SourceSetImage from "@/components/SourceSetImage";
import classNames from "classnames";

type SourceSetMosaicViewProps = {
  sourcesets: SourceSet[];
  buttonAction?: () => void;
  advancedModeAction: () => void;
  advancedModeCTA: string;
  showHost: boolean;
};

const SourceSetMosaicView = ({
  sourcesets,
  advancedModeAction,
  advancedModeCTA,
  buttonAction,
}: SourceSetMosaicViewProps) => {
  const [sourcesetImagesByType, setSourcesetImagesByType] = useState<
    Record<string, string[]>
  >({});
  const [sourcesetByTypeWithoutImage, setSourcesetByTypeWithoutImage] =
    useState<Record<string, number>>({});

  const typesToWords: Record<string, string> = {
    text: "Websites & Blogs",
    youtube: "YouTube Channels",
    podcast: "Podcasts & Audio",
    twitter: "Twitter",
    mastodon: "Mastodon",
    default: "Other",
  };

  useEffect(() => {
    const retrieveSourceSets = async () => {
      let tempSourcesetByType: Record<string, string[]> = {};
      let tempSourcesetByTypeWithoutImage: Record<string, number> = {};
      sourcesets.forEach((sourceset) => {
        if (sourceset.image && sourceset.image !== "default") {
          if (!tempSourcesetByType[sourceset.type]) {
            tempSourcesetByType[sourceset.type] = [];
          }
          if (!tempSourcesetByType[sourceset.type].includes(sourceset.image)) {
            tempSourcesetByType[sourceset.type].push(sourceset.image);
          } else {
            if (!tempSourcesetByTypeWithoutImage[sourceset.type]) {
              tempSourcesetByTypeWithoutImage[sourceset.type] = 0;
            }
            tempSourcesetByTypeWithoutImage[sourceset.type] += 1;
          }
        } else {
          if (!tempSourcesetByTypeWithoutImage[sourceset.type]) {
            tempSourcesetByTypeWithoutImage[sourceset.type] = 0;
          }
          tempSourcesetByTypeWithoutImage[sourceset.type] += 1;
        }
        setSourcesetImagesByType(tempSourcesetByType);
        setSourcesetByTypeWithoutImage(tempSourcesetByTypeWithoutImage);
      });
    };
    retrieveSourceSets();
  }, [sourcesets]);

  return (
    <>
      <div>
        {Object.keys(sourcesetImagesByType)
          .sort(
            (a, b) =>
              sourcesetImagesByType[b].length - sourcesetImagesByType[a].length
          )
          .map((type, index) => (
            <div
              className={classNames("text-left", {
                "my-4": index != 0,
                "mb-4": index == 0,
              })}
              key={type}
            >
              <p
                className={classNames("font-sans", {
                  "pt-4 pb-2": index != 0,
                  "py-2": index == 0,
                })}
              >
                {typesToWords[type]}
              </p>
              <div className="grid">
                <div className="flex flex-wrap gap-2">
                  {sourcesetImagesByType[type].map((image, index) =>
                    image !== "default" || !image ? (
                      <SourceSetImage key={index} image={image} type={type} />
                    ) : null
                  )}
                  {sourcesetByTypeWithoutImage[type] && (
                    <div className="h-8 w-8 flex-shrink-0 text-center flex justify-center items-center bg-gray-200 dark:bg-gray-900 rounded-lg">
                      +{sourcesetByTypeWithoutImage[type]}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="flex flex-col gap-2 mt-2">
        {advancedModeAction && (
          <Button variant="secondary" $fullwidth onClick={advancedModeAction}>
            {advancedModeCTA}
          </Button>
        )}
        {buttonAction && (
          <Button
            onClick={buttonAction}
            variant="primary"
            $fullwidth
            disabled={sourcesets.length == 0}
          >
            Continue
          </Button>
        )}
      </div>
    </>
  );
};

export default SourceSetMosaicView;
