import { useState } from "react";
import { usePostHog } from "posthog-js/react";
import Button from "./styled/Button";
import { RiShareBoxLine } from "react-icons/ri";
import Modal from "./styled/Modal";
import { Story } from "@/utils/types";
import { TextFrontend } from "@/utils/Text";
import { ShareButton } from "./ShareButton";

type StoryShareButtonProps = {
  story: Story;
  canShare: boolean;
  onShare: () => void;
  url: string;
  text: string;
};

export const StoryShareButton = (props: StoryShareButtonProps) => {
  const posthog = usePostHog();
  const [openShareOverlay, setOpenShareOverlay] = useState(false);
  const handleCloseShareOverlay = () => {
    setOpenShareOverlay(false);
  };
  const handleShare = async () => {
    posthog?.capture("share", {
      trigger: "story_share",
    });
    props.onShare();
  };

  const formatShareLabel = (share_count: number): string => {
    if (share_count > 0) {
      if (share_count == 1) {
        return TextFrontend.formatNumber(share_count) + " Share";
      } else {
        return TextFrontend.formatNumber(share_count) + " Shares";
      }
    } else {
      return "Share";
    }
  };

  return (
    <>
      {props.canShare && (
        <>
          <Button
            variant="secondary"
            onClick={() => setOpenShareOverlay(!openShareOverlay)}
            icon={<RiShareBoxLine />}
            title="Share"
            aria-label="Share"
            role="button"
            hideTextOnMobile
          >
            {formatShareLabel(props.story.shares_count)}
          </Button>
        </>
      )}
      <Modal open={openShareOverlay} onClose={handleCloseShareOverlay}>
        <Modal.Title>Share</Modal.Title>
        <Modal.Body>
          <div className="flex flex-col gap-2 items-center">
            <div className="py-8 px-4 w-full">
              <ShareButton
                size="lg"
                url={props.url}
                title={props.text}
                onShare={handleShare}
              />
            </div>
            <small className="opacity-50 uppercase text-black dark:text-white">
              Preview
            </small>
            <div className="border rounded-lg p-4 max-h-80 overflow-hidden relative text-black dark:text-white">
              <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-white to-transparent dark:from-black h-20"></div>
              <h2 className="text-left">{props.story.title}</h2>
              <p
                className="py-2"
                dangerouslySetInnerHTML={{
                  __html: TextFrontend.stripMarkdownLines(
                    props.story.bulletpoints_content,
                    true
                  ),
                }}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
