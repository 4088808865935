import "react-h5-audio-player/lib/styles.css";
import { RiSmartphoneLine } from "react-icons/ri";
import Button from "@/components/styled/Button";
import { useOnboardingContext } from "@/screens/OnboardingView";
import { useEffect } from "react";

type OnboardingAppDownloadStepProps = {
  nextStep: () => void;
};

const OnboardingAppDownloadStep = (props: OnboardingAppDownloadStepProps) => {
  const { setHostTitle, setHostSubtitle } = useOnboardingContext();

  useEffect(() => {
    setHostTitle("All set! I'm creating a news feed for you.");
    setHostSubtitle(
      " In the meantime, download our iOS app to read or listen to the news on the go!"
    );
  });

  return (
    <div>
      <div className="flex flex-wrap gap-2 mt-2 justify-center">
        <Button
          href="https://testflight.apple.com/join/jlQPI7I8"
          target="_blank"
          variant="secondary"
        >
          <RiSmartphoneLine />
          Try the iOS app
        </Button>
        <Button
          onClick={() => props.nextStep()}
          variant="primary"
          className="px-10"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default OnboardingAppDownloadStep;
