import React, { useState } from "react";
import { AudioPlayerManager } from "@/components/AudioPlayerManager";
import NavigationBar from "@/components/NavigationBar";
import { useAuth } from "@/contexts/AuthenticationContext";
import { ContentSetContextProvider } from "@/contexts/ContentSetContext";
import { Outlet } from "react-router-dom";
import SVGBackground from "@/components/styled/SVGBackground";
import classNames from "classnames";
import {
  FeatureFlagsProvider,
  FeatureFlags,
  useFeatureFlagsContext,
} from "@/contexts/FeatureFlagsContext";
import { RiInformationLine } from "react-icons/ri";
import { useFeatureFlagPayload } from "posthog-js/react";

export async function loader() {
  return [];
}

const RootWithFeatureFlags = () => {
  const { isAuth } = useAuth();
  const { isFeatureEnabled } = useFeatureFlagsContext();
  const message = useFeatureFlagPayload(
    FeatureFlags.SITEWIDE_MESSAGE
  ) as string;
  const flagEnabled = isFeatureEnabled(FeatureFlags.SITEWIDE_MESSAGE);
  const [discontinued, setDiscontinued] = useState(true);
  return (
    <AudioPlayerManager>
      <ContentSetContextProvider>
        {flagEnabled && (
          <div
            className="flex items-center text-black bg-primary-light text-sm font-bold px-4 py-3"
            role="alert"
          >
            <RiInformationLine className="mr-5" />
            {message}
          </div>
        )}
        {discontinued && (
          <div
            className="flex items-center text-black bg-primary-light text-sm px-4 py-3"
            role="alert"
          >
            <RiInformationLine className="mr-5" />
            Tailor will be discontinued on July 25th as it becomes TailorTask.ai
            {` `}
            {` `}
            <a href="https://www.tailortask.ai" className="ml-2 underline">
              Try it out for free here
            </a>
          </div>
        )}
        <div className="relative flex flex-col md:flex-row h-screen w-screen">
          <NavigationBar />
          <div
            id="main-container"
            className={classNames(
              "relative font-sans tracking-wide text-black dark:text-white overflow-hidden h-full w-full pt-16",
              {
                "md:pt-0 md:pl-40": isAuth,
              }
            )}
          >
            <div className="relative grid grid-cols-12 overflow-hidden h-screen">
              <Outlet />
            </div>
            <SVGBackground />
          </div>
        </div>
      </ContentSetContextProvider>
    </AudioPlayerManager>
  );
};

export default function Root() {
  return (
    <FeatureFlagsProvider>
      <RootWithFeatureFlags />
    </FeatureFlagsProvider>
  );
}
