import { useEffect, useState } from "react";
import MarconipyApi from "@/utils/marconipyApi";
import { SourceSet } from "utils/types";
import { DebounceInput } from "react-debounce-input";
import {
  RiCloseFill,
  RiSearch2Line,
  RiLoader2Fill,
  RiQuestionLine,
  RiFileWarningLine,
} from "react-icons/ri";
import SearchResult from "@/components/SearchResult";
import { IconsUtils } from "@/utils/Icons";
import SourceSetRowItem from "@/components/SourceSetRowItem";
import Button from "@/components/styled/Button";
import Modal from "@/components/styled/Modal";
import { useContentSetContext } from "@/contexts/ContentSetContext";
import { useOnboardingContext } from "../OnboardingView";
import Input from "@/components/styled/Input";

type SelectSourceSetsPartialViewProps = {
  buttonAction?: () => void;
  isOnboarding: boolean;
};

const SelectSourceSetsPartialView = ({
  buttonAction,
  isOnboarding,
}: SelectSourceSetsPartialViewProps) => {
  const { contentset, updateContentSetOptimistically } = useContentSetContext();
  const [query, setQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState<SourceSet[]>([]);
  const [searchInputIsFocused, setSearchInputIsFocused] =
    useState<boolean>(false);
  const [searchNew, setSearchNew] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showAddLinkModal, setShowAddLinkModal] = useState<boolean>(false);
  const [manualLink, setManualLink] = useState<string>("");
  const [manualLinkLoading, setManualLinkLoading] = useState<string>("");
  const { showHost } = useOnboardingContext();

  const addSourceSet = async function (sourceset: SourceSet) {
    setQuery("");
    setSuggestions([]);
    setSearchInputIsFocused(false);
    updateContentSetOptimistically(
      {
        ...contentset!,
        sourcesets: [...contentset!.sourcesets, sourceset],
      },
      async () => {
        await MarconipyApi.addSourceSet(contentset?.uuid ?? "", {
          uuid: sourceset.uuid,
          is_onboarding: isOnboarding,
        });
      }
    );
  };
  const removeSourceset = async function (sourceset: SourceSet) {
    updateContentSetOptimistically(
      {
        ...contentset!,
        sourcesets: contentset!.sourcesets.filter(
          (_sourceset) => _sourceset.uuid !== sourceset.uuid
        ),
      },
      async () => {
        MarconipyApi.removeSourceSet(contentset?.uuid ?? "", {
          uuid: sourceset.uuid,
          is_onboarding: isOnboarding,
        });
      }
    );
  };

  const searchAutocomplete = async function (squery: string) {
    setIsSearching(true);
    setQuery(squery);
    if (squery.length < 3) {
      setSuggestions([]);
      return;
    }
    let results = (await MarconipyApi.searchSourceSetAutocomplete(
      squery
    )) as any as SourceSet[];
    setSuggestions(results);
    setIsSearching(false);
  };
  const searchSourcesets = async function (force = false) {
    if (query.length < 2) {
      setSuggestions([]);
      return;
    }
    setSearchNew(true);
    let results = (await MarconipyApi.searchSourceSet(
      query,
      force
    )) as any as SourceSet[];
    setSuggestions(results);
    setSearchNew(false);
  };

  const searchWasFocused = async function () {
    setSearchInputIsFocused(true);
  };
  const searchWasoutOfFocus = async function () {
    setSuggestions([]);
    setSearchInputIsFocused(false);
    setQuery("");
  };

  const searchAndAddSourceSet = async function (sourceset: SourceSet) {
    setQuery("");
    setSuggestions([]);
    setSearchInputIsFocused(false);
    updateContentSetOptimistically(
      {
        ...contentset!,
        sourcesets: [...contentset!.sourcesets, sourceset],
      },
      async () => {
        let foundSourceset = (await MarconipyApi.searchSourceSet(
          sourceset.name
        )) as unknown as SourceSet;
        if (foundSourceset && foundSourceset.uuid) {
          addSourceSet(foundSourceset);
        }
      }
    );
  };

  const handleSourceSetSubmit = async function (e: any) {
    e.preventDefault();
    await searchAndAddSourceSet({ name: query, uuid: "temp" } as SourceSet);
    setQuery("");
  };

  const addManualLink = async function (url: string) {
    setManualLinkLoading("loading");
    const sourceset = (await MarconipyApi.sourceSetSearchByLink(
      url
    )) as any as SourceSet;
    if (sourceset && sourceset.uuid) {
      addSourceSet(sourceset);
      setManualLink("");
      setQuery("");
      setManualLinkLoading("done");
    } else {
      setManualLinkLoading("noresults");
    }
  };
  const toggleAddLinkModal = () => {
    setShowAddLinkModal(!showAddLinkModal);
  };

  useEffect(() => {
    if (!showAddLinkModal) {
      setManualLinkLoading("");
    }
  }, [showAddLinkModal]);

  useEffect(() => {
    showHost(!searchInputIsFocused);
  }, [searchInputIsFocused, showHost]);

  return (
    <>
      <div className="flex flex-col">
        <form onSubmit={handleSourceSetSubmit}>
          <div className="flex relative mt-4">
            <DebounceInput
              type="text"
              value={query}
              placeholder="🔎 Add a source..."
              onChange={(e) => searchAutocomplete(e.target.value)}
              onFocus={searchWasFocused}
              className="border border-gray rounded-md py-1 px-2 w-full dark:bg-black"
            />
            {searchInputIsFocused && (
              <span className="absolute flex p-2 items-center inset-y-0 right-0 cursor-pointer hover:text-primary">
                <RiCloseFill onClick={searchWasoutOfFocus} className="" />
              </span>
            )}
          </div>
        </form>
        {(suggestions.length > 0 || query?.length > 0) && (
          <div className="flex flex-col my-2">
            {query?.length > 0 &&
              !isSearching &&
              suggestions.length < 5 &&
              !searchNew && (
                <SearchResult
                  key={query}
                  value={{
                    name: "Search more sources for: " + query,
                    uuid: "temp",
                  }}
                  icon={<RiSearch2Line />}
                  callback={function () {
                    searchSourcesets(false);
                  }}
                />
              )}
            {query?.length > 0 && searchNew && (
              <SearchResult
                key={query}
                value={{
                  name: "Searching more sources for: " + query,
                  uuid: "temp",
                }}
                icon={<RiLoader2Fill className="animate-spin" />}
                callback={() => {}}
              />
            )}
            {suggestions?.map((sourceset) => {
              const Icon = IconsUtils.fromSourceSetTypeToIcon(sourceset.type); // get the icon component
              return (
                <SearchResult
                  key={sourceset.uuid}
                  value={sourceset}
                  icon={<Icon />}
                  callback={addSourceSet}
                />
              );
            })}
            {query?.length > 1 &&
              !isSearching &&
              suggestions.length > 4 &&
              !searchNew && (
                <SearchResult
                  key={query + "_more"}
                  value={{
                    name: "Search more sources for: " + query,
                    uuid: "temp",
                  }}
                  icon={<RiSearch2Line />}
                  callback={function () {
                    searchSourcesets(true);
                  }}
                />
              )}
            {query?.length > 2 && !isSearching && !searchNew && (
              <SearchResult
                key="custom link"
                value={{
                  name: "Add a custom link",
                  uuid: "temp",
                }}
                icon={<RiQuestionLine />}
                callback={function () {
                  toggleAddLinkModal();
                }}
              />
            )}
          </div>
        )}
        {searchInputIsFocused &&
          suggestions.length == 0 &&
          !searchNew &&
          query == "" && (
            <div className="flex flex-col align-center text-center mt-8">
              <h1 className="font-serif text-2xl">Search for a source</h1>
              <h2 className="font-sans text-base">
                Newspapers, podcasts, youtube channels...
              </h2>
            </div>
          )}
        {!searchInputIsFocused && contentset?.sourcesets && (
          <div className="align-left py-4">
            {contentset?.sourcesets.map((sourceset) => (
              <div key={sourceset.uuid}>
                <SourceSetRowItem
                  sourceset={sourceset}
                  key={sourceset.uuid}
                  functionToAddButton={addSourceSet}
                  functionToRemoveButton={removeSourceset}
                />
              </div>
            ))}
          </div>
        )}
        {!searchInputIsFocused && buttonAction && (
          <Button
            onClick={() => {
              if (buttonAction) {
                buttonAction();
              }
            }}
            $fullwidth
            variant="primary"
            disabled={contentset?.sourcesets.length == 0}
          >
            Continue
          </Button>
        )}
      </div>
      <Modal open={showAddLinkModal} onClose={toggleAddLinkModal}>
        {manualLinkLoading == "" && (
          <>
            <Modal.Title>Paste a link</Modal.Title>
            <Modal.MainContainer>
              <Modal.Body>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    addManualLink(manualLink);
                  }}
                  className="flex flex-col gap-2"
                >
                  <Input
                    type="url"
                    name="url"
                    placeholder="Paste the link of a publication you follow"
                    value={manualLink}
                    onChange={(e) => setManualLink(e.target.value)}
                  />
                  <Button
                    variant="primary"
                    disabled={manualLink.length == 0}
                    type="submit"
                  >
                    Add publication
                  </Button>
                </form>
              </Modal.Body>
            </Modal.MainContainer>
          </>
        )}
        {manualLinkLoading == "loading" && (
          <Modal.Title>Loading...</Modal.Title>
        )}
        {manualLinkLoading == "done" && (
          <>
            <Modal.Title>Done! A publication was added</Modal.Title>
            <Modal.MainContainer>
              <Modal.Body>
                <Button
                  onClick={function () {
                    setManualLinkLoading("");
                    toggleAddLinkModal();
                  }}
                  variant="primary"
                  $fullwidth
                >
                  Back
                </Button>
              </Modal.Body>
            </Modal.MainContainer>
          </>
        )}
        {manualLinkLoading == "noresults" && (
          <Modal.MainContainer>
            <Modal.Body>
              <div className="flex flex-col gap-2 text-left">
                <div className="flex items-center gap-2">
                  <RiFileWarningLine className="icon" />
                  <p>
                    {` `}No results were found for <strong>{manualLink}</strong>
                  </p>
                </div>
                <p>
                  You can{" "}
                  <a
                    href="#"
                    onClick={function () {
                      setManualLinkLoading("");
                    }}
                  >
                    try with a different link
                  </a>{" "}
                  or{" "}
                  <a
                    href="mailto:info@tailor.news"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    get in touch with our team
                  </a>
                </p>
              </div>
            </Modal.Body>
          </Modal.MainContainer>
        )}
      </Modal>
    </>
  );
};

export default SelectSourceSetsPartialView;
