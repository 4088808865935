import { Helmet } from "react-helmet";
import Container from "@/components/styled/Container";
import { Footer } from "@/components/Footer";
import Divider from "@/components/styled/Divider";

export default function Blog() {
  return (
    <>
      <Helmet>
        <title>Tailor Blog </title>
        <meta name="description" content="Updates from the Tailor team" />
        <meta property="og:title" content="Tailor Blog" />
        <meta
          property="og:description"
          content="Updates from the Tailor team"
        />
        <meta property="og:url" content="https://tailor.news/blog/" />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
        <link rel="canonical" href="https://tailor.news/blog" />
      </Helmet>
      <div className="col-span-10 col-start-2 bg-white dark:bg-black mt-10 mb-10">
        <Container>
          <h1 className="static">Tailor Blog - Coming soon</h1>
          <p>We are working on our blog. Stay tuned for updates.</p>
        </Container>
      </div>
      <Divider className="mt-10" />
      <Footer />
    </>
  );
}
