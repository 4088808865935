import { RiEditBoxLine } from "react-icons/ri";
import JSONRenderer from "./styled/JSONRenderer";
import Modal from "./styled/Modal";
import { NavigationButton } from "./styled/NavigationButton";

type FeedInfoModalProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  debug?: Record<string, any>;
};

export const FeedInfoModal = ({
  isOpen,
  setOpen,
  debug = {},
}: FeedInfoModalProps) => {
  let data: Record<string, string> = {
    "This news was selected based on these interests": debug.rationale
      ? debug.rationale.interests
      : "No interests found",
  };

  if (debug.relevant_user_request) {
    data[
      "This is what Tailor keeps in mind when generating insights for this news"
    ] = debug.relevant_user_request;
  }

  if (debug.user_request_reasoning) {
    data["Insights were generated following this reasoning"] =
      debug.user_request_reasoning;
  }

  return (
    <>
      <Modal open={isOpen} onClose={() => setOpen(false)} size="lg">
        <Modal.Title>Why am I seeing this?</Modal.Title>
        <Modal.MainContainer>
          <Modal.Body>
            <div className="dark:text-white">
              <div className="py-2">
                {debug && <JSONRenderer data={data} />}
                <NavigationButton
                  icon={<RiEditBoxLine />}
                  to="/edit"
                  className="mt-4"
                >
                  Change your interests and settings
                </NavigationButton>
              </div>
            </div>
          </Modal.Body>
        </Modal.MainContainer>
      </Modal>
    </>
  );
};
