import { Suggestion } from "@/utils/types";

type QueryFeaturedBoxProps<T extends Suggestion> = {
  icon?: any;
  text: string;
  click?: () => void;
};

export const QueryFeaturedBox = <T extends Suggestion>(
  props: QueryFeaturedBoxProps<T>
) => {
  return (
    <div
      onClick={props.click || (() => {})}
      className="width-auto justify-between items-center cursor-pointer bg-white dark:bg-black hover:bg-primary-light hover:dark:bg-black-light px-2 py-2 rounded-lg"
    >
      <div className="flex gap-2 items-center overflow-hidden">
        {props.icon && <div className="w-4 h-4">{props.icon}</div>}
        <span className="truncate">{props.text}</span>
      </div>
    </div>
  );
};
