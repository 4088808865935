import HostHeader from "@/components/HostHeader";
import LoadingCircle from "@/components/styled/LoadingCircle";
import classnames from "classnames";

const OnboardingHostContainer = ({
  children,
  isLoading,
  title,
  subtitle,
  show,
}: {
  children: React.ReactNode;
  isLoading?: boolean;
  show?: boolean;
  title: string;
  subtitle: string;
}) => {
  return (
    <div className="flex flex-col">
      <HostHeader
        title={title}
        subtitle={subtitle}
        className={classnames({ hidden: !show })}
      />
      {isLoading && (
        <div className="w-full flex items-center justify-center">
          <div role="status">
            <LoadingCircle />
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {!isLoading && children}
    </div>
  );
};

export default OnboardingHostContainer;
