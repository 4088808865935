import { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";
import SubchapterTitle from "./SubchapterTitle";
import SourceItemRowItem from "./SourceItemRowItem";
import { Story } from "@/utils/types";
import SourcesBadges from "./SourcesBadges";
import TimeAgo from "react-timeago";
import { StoryResidualMenu } from "./StoryResidualMenu";
import Divider from "./styled/Divider";
import PlayButton from "./PlayButton";
import { useAuth } from "@/contexts/AuthenticationContext";

type StoryDetailTopRowProps = {
  story: Story;
  sources: Record<string, any>;
  debug: Record<string, any>;
  onPlay?: (uuid: string) => void;
};

export const StoryDetailTopRow = ({
  story,
  sources,
  debug,
}: StoryDetailTopRowProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const { isAuth } = useAuth();

  return (
    <>
      <div className="flex md:hidden flex-row gap-2 items-center justify-between">
        {story.first_sourceitem_published_at && (
          <div className="ml-1 text-xs">
            <TimeAgo date={story.first_sourceitem_published_at} />
          </div>
        )}
        <div className="max-w-12">
          {isAuth && <StoryResidualMenu story={story} debug={debug} />}
        </div>
      </div>
      <h1 className="text-left my-2">{story.title}</h1>
      <div className="overflow-hidden flex flex-col gap-4">
        <div className="flex flex-row justify-between gap-2 items-center">
          <button
            onClick={toggleOpen}
            className="py-2 rounded-lg flex items-center flex-grow text-left hover:bg-gray-100 dark:hover:bg-gray-800 transition-all ease-in-out"
          >
            <span className="text-gray-500 dark:text-white text-xl pr-2 -ml-1">
              {isOpen ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
            </span>
            <div className="flex flex-row items-center justify-between gap-2 w-full">
              <div className="flex flex-row gap-2 items-center justify-between md:justify-start w-full">
                <SubchapterTitle
                  title={`From ${Object.keys(
                    sources
                  ).length.toString()} sources`}
                  className="pr-4"
                />
                <SourcesBadges story={story} />
              </div>
            </div>
          </button>
          <div className="hidden md:flex flex-row gap-2 items-center">
            {story.first_sourceitem_published_at && (
              <div className="ml-1 text-xs flex-none">
                <TimeAgo date={story.first_sourceitem_published_at} />
              </div>
            )}
            {isAuth && <StoryResidualMenu story={story} debug={debug} />}
          </div>
        </div>
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transition ease-out duration-300"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition ease-in duration-200"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <div className="mt-2 flex gap-2 transition-all snap-x pb-4 snap-mandatory scroll-smooth overflow-x-scroll dark:[color-scheme:dark]">
            {Object.values(sources).map((obj) => (
              <div className="snap-start" key={obj.index}>
                <SourceItemRowItem
                  sourceitem={obj.sourceitem}
                  index={obj.index}
                />
              </div>
            ))}
          </div>
        </Transition>
        {story.audio_url && <PlayButton story={story} />}
      </div>
      <Divider />
    </>
  );
};
