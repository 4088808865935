import { Helmet } from "react-helmet";
import { redirect } from "react-router-dom";
import MarconipyApi from "@/utils/marconipyApi";
import { Loading } from "@/components/Loading";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Tailor - Understand the news with AI</title>
        <meta
          name="description"
          content="Make sense of the noise. Tailor uses A.I. to understand the news and create daily summaries of what matters most to you."
        />
        <meta
          property="og:title"
          content="Tailor - Understand the news with AI"
        />
        <meta
          property="og:description"
          content="Make sense of the noise. Tailor uses A.I. to understand the news and create daily summaries of what matters most to you"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://d3c0pq8hs2rjcc.cloudfront.net/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
      </Helmet>
      <Loading />
    </>
  );
}

export async function loader({}: { params: any }) {
  const loggedIn = MarconipyApi.isAuth();
  if (loggedIn) {
    return redirect("/discover");
  } else {
    return redirect("/login");
  }
}
