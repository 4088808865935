import React from "react";
import { Story } from "@/utils/types";
import { StorySocialBar } from "./StorySocialBar";
import FeedSection from "./styled/FeedSection";
import classnames from "classnames";
import Button from "./styled/Button";
import Card from "./styled/Card";
import { RiBookLine } from "react-icons/ri";
import Divider from "./styled/Divider";
import { StoryResidualMenu } from "./StoryResidualMenu";
import { useAuth } from "@/contexts/AuthenticationContext";
import SourcesBadges from "./SourcesBadges";
import TimeAgo from "react-timeago";
import {
  FeatureFlags,
  useFeatureFlagsContext,
} from "@/contexts/FeatureFlagsContext";
import BulletpointsBlock from "./BulletpointsBlock";
import useSourceMap from "@/hooks/useSourceMap";
import PlayButton from "./PlayButton";

type StoryRendererProps = {
  story: Story;
  onReadMore: (uuid: string) => void;
  className?: string | Record<string, boolean>;
  debug?: Record<string, any>;
  onAuthRedirect?: () => void;
};

export const StoryRenderer = React.forwardRef<
  HTMLDivElement,
  StoryRendererProps
>(function StoryRenderer(
  { story, onReadMore, className, debug, onAuthRedirect }: StoryRendererProps,
  ref
) {
  const { isAuth } = useAuth();
  const { isFeatureEnabled } = useFeatureFlagsContext();
  const isDebugMode = isFeatureEnabled(FeatureFlags.FEED_DEBUG_MODE);

  const sourceIdsMap = useSourceMap(story);

  return (
    <FeedSection key={`story-${story.uuid}`} ref={ref}>
      <Card
        className={classnames(
          className,
          "relative flex flex-col gap-4 overflow-auto my-4 cursor-pointer p-0"
        )}
      >
        {story.top_image && (
          <div className="w-full h-48 rounded-t-lg">
            <img
              src={story.top_image}
              className="rounded-md w-full h-full object-cover object-center"
            />
          </div>
        )}
        {isAuth && (
          <div className="absolute top-0 right-0 pr-4 pt-3 flex flex-col justify-center">
            <StoryResidualMenu story={story} debug={debug} />
          </div>
        )}
        <div
          onClick={() => onReadMore(story.uuid)}
          className="flex flex-col gap-6 px-4"
        >
          <div id={`$feed_${story.uuid}`} className="flex flex-col gap-2">
            <h2 className="text-left w-full">{story.title}</h2>
            <div className="flex-none flex flex-row gap-2 items-center">
              <SourcesBadges story={story} />
              {story.first_sourceitem_published_at && (
                <div className="text-xs">
                  <TimeAgo date={story.first_sourceitem_published_at} />
                </div>
              )}
            </div>
            {/* {story.customization && story.customization.relevance > 0 && (
              <p
                className="line-clamp-12 rounded-lg px-4 py-2 bg-tertiary dark:text-black"
                dangerouslySetInnerHTML={{
                  __html: TextFrontend.stripMarkdownLines(
                    story.customization.content,
                    true
                  ),
                }}
              ></p>
            )} */}
          </div>
          <BulletpointsBlock
            sources={sourceIdsMap}
            content={story.bulletpoints_content}
            content_json={story.bulletpoints_content_json}
          />
        </div>
        {isDebugMode && debug && (
          <div className="text-xs p-2">{JSON.stringify(debug)}</div>
        )}
        <Divider />
        <div className="flex flex-row gap-2 sm:gap-4 justify-center flex-shrink-0 pb-4">
          <Button
            variant="secondary"
            onClick={() => onReadMore(story.uuid)}
            icon={<RiBookLine />}
            hideTextOnMobile
          >
            Read more
          </Button>
          {story.audio_url && <PlayButton story={story} />}
          <StorySocialBar
            story={story}
            showViews={false}
            onAuthRedirect={onAuthRedirect}
          />
        </div>
      </Card>
    </FeedSection>
  );
});
