import {
  useFloating,
  offset,
  flip,
  shift,
  useFocus,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useTransitionStyles,
  useClick,
} from "@floating-ui/react";
import { useState } from "react";
import { RiMoreFill } from "react-icons/ri";

type MenuItem = {
  label: React.ReactNode;
  icon?: React.ReactNode;
  onClick: () => void;
};

const CustomFloatingMenu = ({ items }: { items: MenuItem[] }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom",
    middleware: [offset(8), flip(), shift()],
  });

  const { styles, isMounted } = useTransitionStyles(context);

  const click = useClick(context);
  const focus = useFocus(context);
  const dismiss = useDismiss(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([
    click,
    focus,
    dismiss,
  ]);

  return (
    <>
      <button
        className="inline-flex w-full justify-center rounded-md dark:text-white hover:bg-tertiary hover:dark:bg-black-light px-2 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
        onClick={() => setIsOpen((prev) => !prev)}
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <RiMoreFill />
      </button>

      <FloatingPortal>
        {isMounted && isOpen && (
          <div
            {...getFloatingProps()}
            ref={refs.setFloating}
            style={{ ...floatingStyles, ...styles }}
            className="bg-white dark:bg-black shadow-lg rounded-md p-2 border dark:border-gray-800 z-50"
          >
            <ul>
              {items.map((item: MenuItem, index: number) => (
                <li
                  key={index}
                  className="hover:bg-gray-100 dark:hover:bg-gray-700 p-2 cursor-pointer flex flex-row items-center gap-2"
                  onClick={() => {
                    item.onClick();
                    setIsOpen(false);
                  }}
                >
                  {item.icon && <span className="mr-2">{item.icon}</span>}
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

export default CustomFloatingMenu;
